<template>
  <div @tab-changed="fetchServices">
    <v-row>
      <v-col cols="12" md="12" class="text-right">
        <v-dialog v-model="dialogAddService" max-width="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab icon color="EoleBlue">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Ajouter un service dans mon organisation</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field dense outlined label="Nom du service" v-model="editService.name">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            outlined
                            dense
                            label="Site du service"
                            :items="getActiveAgencies"
                            item-value="id"
                            item-text="city"
                            v-model="editService.idAgencyEtablishment"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            outlined
                            dense
                            label="Référent du service"
                            :items="agency.activeCollaborators"
                            item-value="id"
                            :item-text="item => `${item.lastname} ${item.firstname}`"
                            v-model="editService.idManager"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-btn text @click="dialogAddService = false"> Fermer</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" :disabled="editService.idService === null" @click="postService"
                        >Ajouter un nouveau service</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          @wheel="handleScroll"
          style="z-index: 9999 !important; height: 900px !important"
          v-model="dialog"
          width="1200px"
          transition="dialog-bottom-transition"
          class="hide-scrollbar"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" fab icon :loading="fetchMembers" color="EoleBlue">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <v-card @scroll.passive="zoomOut()" height="800px" class="hide-scrollbar">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Organigramme - {{ agencyName }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <div @wheel="handleScroll" class="h-full hide-scrollbar">
              <vue-tree
                class="h-full hide-scrollbar"
                id="chart"
                ref="tree"
                style="width: 100%; word-break: break-word !important"
                :dataset="chartData"
                :config="treeConfig"
                linkStyle="straight"
                :collapse-enabled="false"
                direction="vertical"
              >
                <template v-slot:node="{ node }" style="word-break: break-word !important">
                  <div v-if="node.description === 'Mon cabinet'">
                    <v-card-text class="text-center">
                      <v-avatar size="96" color="EoleBlue">
                        <i class="fa-solid fa-building fa-3x white--text"></i>
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="text-center">
                      {{ node.name }}
                    </v-card-text>
                  </div>

                  <v-card
                    v-else-if="node.description === 'Service'"
                    max-width="100"
                    class="rounded-xl text-center pr-10"
                    elevation="3"
                    outlined
                  >
                    <v-card-text class="text-center">
                      <v-avatar size="64" color="EoleYellow">
                        <i class="fa-solid fa-layer-group fa-2x white--text"></i>
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="text-center"> Service : {{ node.name }} </v-card-text>
                  </v-card>

                  <v-card
                    v-else-if="node.description === 'Responsable hiérarchique'"
                    max-width="100"
                    class="rounded-xl text-center pr-10"
                    elevation="3"
                    outlined
                  >
                    <v-card-text>
                      <v-avatar size="64" color="EoleGreen">
                        <i v-if="node.avatarUrl === null" class="fa-solid fa-user fa-2x white--text"></i>
                        <v-img v-else :src="node.avatarUrl"></v-img>
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="text-center">
                      {{ node.name }}
                    </v-card-text>
                  </v-card>
                  <v-card v-else max-width="100" class="rounded-xl text-center pr-10" elevation="3" outlined>
                    <v-card-text>
                      <v-avatar size="48" color="gray">
                        <i v-if="node.avatarUrl === null" class="fa-solid fa-user fa-1x white--text"></i>
                        <v-img v-else :src="node.avatarUrl"></v-img>
                      </v-avatar>
                    </v-card-text>
                    <v-card-text class="text-center">
                      {{ node.name }}
                    </v-card-text>
                  </v-card>
                </template>
              </vue-tree>
            </div>
            <div id="output"></div>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card-text class="no-padding">
      <v-row>
        <v-col class="text-left">
          <v-data-table
            :expanded.sync="expanded"
            single-expand
            show-expand
            :headers="headers"
            :items="services"
            item-key="name"
            class="elevation-1 header-table"
            :items-per-page="-1"
            disable-pagination
            hide-default-footer
            :loading="fetchAll"
          >
            <template v-slot:item.complete_name_manager="{ item }">
              {{ item.manager.firstname }} {{ item.manager.lastname }}
            </template>
            <template v-slot:item.complete_name_chief="{ item }">
              {{ item.chief ? `${item.chief.firstname} ${item.chief.lastname}` : "N/A" }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-5" :colspan="headers.length">
                <p>
                  Voici la liste des collaborateurs appartenant au service
                  <strong>{{ item.name }}</strong> :
                </p>
                <v-row class="my-2">
                  <v-col class="d-flex text-center" v-for="partner in item.members" :key="partner.id" cols="12" md="2">
                    <v-card
                      :title="`${partner.lastname} ${partner.firstname}`"
                      outlined
                      elevation="3"
                      class="text-center justify-center flex d-flex flex-column rounded-xl"
                    >
                      <v-card-text>{{ partner.lastname }} {{ partner.firstname }} </v-card-text>
                      <v-card-text class="flex-grow-1">
                        <v-avatar left :color="partner.avatarUrl === null ? 'EoleBlue' : null" size="64">
                          <span v-if="partner.avatarUrl === null" class="white--text text-h6">
                            {{ partner.lastname[0] + partner.firstname[0] }}
                          </span>
                          <v-img v-else :src="partner.avatarUrl"></v-img>
                        </v-avatar>
                      </v-card-text>
                      <v-card-actions class="text-center justify-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              color="EoleError"
                              @click="deleteServiceCollaborator(item.id_service_manager, partner.id)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Retirer {{ partner.lastname }} {{ partner.firstname }} du service {{ item.name }}
                          </span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex text-center">
                    <v-dialog v-model="dialogPutService" max-width="1200">
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          target="_blank"
                          link
                          elevation="3"
                          class="text-center justify-center flex d-flex flex-column rounded-xl"
                        >
                          <v-card-text>
                            <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                          </v-card-text>
                          <v-card-text>Ajouter un collaborateur</v-card-text>
                        </v-card>
                      </template>
                      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                        <v-card-text style="background-color: #f1f4f9">
                          <v-row>
                            <v-col cols="12" md="3" class="no-padding ml-n2">
                              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                <v-card-title>
                                  <v-img
                                    :src="require('@/assets/images/logos/anavel_logo.png')"
                                    max-height="150px"
                                    max-width="120px"
                                    alt="logo"
                                    contain
                                  >
                                  </v-img>
                                </v-card-title>
                                <v-card-title class="text-center justify-center muller-capitalized"
                                  >Mes Services Assurance</v-card-title
                                >
                                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                                <v-card-text class="no-padding">
                                  <v-img
                                    :src="require('@/assets/images/logos/layer.png')"
                                    alt="logo"
                                    width="300px"
                                    class=""
                                  >
                                  </v-img>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="9" class="no-padding d-flex">
                              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                <v-card-title>
                                  <span class="text-h5">
                                    Ajouter un collaborateur dans le service {{ item.name }}
                                  </span>
                                </v-card-title>

                                <v-card-text class="my-2">
                                  <v-card-text>
                                    <v-row>
                                      <v-col cols="12" md="12">
                                        <v-select
                                          multiple
                                          outlined
                                          :label="`Collaborateurs dans le service`"
                                          :items="agency.activeCollaborators"
                                          item-value="id"
                                          :item-text="`${item.lastname} ${item.firstname}`"
                                          v-model="item.members"
                                        >
                                          <template v-slot:selection="{ item, index }">
                                            <v-chip>
                                              <v-avatar left color="EoleBlue" size="32">
                                                <span class="white--text">
                                                  {{ item.lastname[0] + item.firstname[0] }}
                                                </span>
                                              </v-avatar>
                                              <span>{{ item.lastname }} {{ item.firstname }}</span>
                                            </v-chip>
                                          </template>
                                          <template #item="{ item }">
                                            <v-list-item-avatar
                                              color="EoleBlue"
                                              class="white--text text-center"
                                              size="32"
                                            >
                                              <v-avatar class="bordered" color="EoleBlue" size="32">
                                                <span class="white--text">
                                                  {{ item.lastname[0] + item.firstname[0] }}
                                                </span>
                                              </v-avatar>
                                            </v-list-item-avatar>
                                            <v-list-item-content height="150">
                                              <v-list-item-title
                                                >{{ item.lastname }} {{ item.firstname }}</v-list-item-title
                                              >
                                            </v-list-item-content>
                                          </template>
                                        </v-select>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card-text>
                                <v-spacer></v-spacer>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    elevation="0"
                                    @click="postCollaboratorInService(item.id_service_manager, item.members)"
                                  >
                                    Placer dans le service</v-btn
                                  >
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:[`item.number_members`]="{ item }">
              <v-skeleton-loader v-if="fetchAll" type="chip"></v-skeleton-loader>
              <v-dialog v-else max-width="950">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-chip link v-on="on"> {{ item.number_members + 1 }} membres</v-chip>
                      </template>
                      <ul>
                        <li>{{ item.lastname_manager }} {{ item.firstname_manager }} (Responsable)</li>
                        <li v-for="member in item.members" :key="member.id">
                          {{ member.lastname }} {{ member.firstname }}
                        </li>
                      </ul>
                    </v-tooltip>
                  </div>
                </template>
                <v-card class="pa-5 h-full hide-scrollbar" height="900">
                  <v-card-title class="text-center">Liste des membres du service {{ item.name }} </v-card-title>
                  <vue-tree
                    class="h-full hide-scrollbar"
                    id="chart"
                    ref="tree"
                    style="width: 100%; word-break: break-word !important"
                    :dataset="item.chartData"
                    :config="treeConfig"
                    linkStyle="straight"
                    :collapse-enabled="false"
                    direction="vertical"
                  >
                    <template v-slot:node="{ node }" style="word-break: break-word !important">
                      <div v-if="node.description === 'Mon cabinet'">
                        <v-card-text class="text-center">
                          <v-avatar size="96" color="EoleBlue">
                            <i class="fa-solid fa-building fa-3x white--text"></i>
                          </v-avatar>
                        </v-card-text>
                        <v-card-text class="text-center">
                          {{ node.name }}
                        </v-card-text>
                      </div>

                      <v-card
                        v-else-if="node.description === 'Service'"
                        max-width="100"
                        class="rounded-xl text-center pr-10"
                        elevation="3"
                        outlined
                      >
                        <v-card-text class="text-center">
                          <v-avatar size="64" color="EoleYellow">
                            <i class="fa-solid fa-layer-group fa-2x white--text"></i>
                          </v-avatar>
                        </v-card-text>
                        <v-card-text class="text-center"> Service : {{ node.name }} </v-card-text>
                      </v-card>

                      <v-card
                        v-else-if="node.description === 'Responsable hiérarchique'"
                        max-width="100"
                        class="rounded-xl text-center pr-10"
                        elevation="3"
                        outlined
                      >
                        <v-card-text>
                          <v-avatar size="64" color="EoleGreen">
                            <i class="fa-solid fa-user-tie fa-2x white--text"></i>
                          </v-avatar>
                        </v-card-text>
                        <v-card-text class="text-center"> Responsable : {{ node.name }} </v-card-text>
                      </v-card>
                      <v-card v-else max-width="100" class="rounded-xl text-center pr-10" elevation="3" outlined>
                        <v-card-text>
                          <v-avatar size="48" color="gray">
                            <i v-if="node.avatarUrl === null" class="fa-solid fa-user fa-1x white--text"></i>
                            <v-img v-else :src="node.avatarUrl"></v-img>
                          </v-avatar>
                        </v-card-text>
                        <v-card-text class="text-center">
                          {{ node.name }}
                        </v-card-text>
                      </v-card>
                    </template>
                  </vue-tree>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu right transition="slide-x-transition" close-on-content-click>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="editItem(item)">
                    <v-list-item-title>
                      <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item color="EoleError" link @click="deleteService(item.id_service_manager)" target="blank">
                    <v-list-item-title> <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:no-data> Vous n'avez actuellement aucun service dans votre organisation </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-dialog v-model="dialogEditService" max-width="1200">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title>
                    <span class="text-h5">Modifier le service</span>
                  </v-card-title>
                  {{ editServiceManage }}
                  <v-card-text class="my-2">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field dense outlined label="Nom du service" v-model="editServiceManage.name">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            outlined
                            dense
                            label="Site du service"
                            :items="getActiveAgencies"
                            item-value="id"
                            item-text="city"
                            v-model="editServiceManage.id_agency_etablishment"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            outlined
                            dense
                            label="Référent du service"
                            :items="agency.activeCollaborators"
                            item-value="id"
                            :item-text="item => `${item.lastname} ${item.firstname}`"
                            v-model="editServiceManage.id_manager"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card-text>
                  <v-spacer></v-spacer>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" @click="putService(editServiceManage)">Modifier</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<script>
import config from "@/views/config/config"

import moment from "moment"
import { getCookie } from "@/utils/cookies"
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: "OfficeServices",
  props: {
    agency: {
      type: Object,
      required: true,
    },
    tabChangeIndicator: Number,
  },
  data() {
    return {
      fetchAll: false,
      dialogPutServiceManage: false,
      agencyName: localStorage.getItem("agency_name"),
      nodeChart: [],
      chartData: {},
      treeConfig: {
        nodeWidth: 150,
        nodeHeight: 50,
        levelHeight: 250,
      },
      fetchServicesState: false,
      valueOrganization: 0,

      dialogAddService: false,
      dialogEditService: false,
      dialogPutService: false,
      dialog: false,
      dialogDeleteService: false,

      editService: {
        name: "",
        idAgencyEtablishment: null,
        idManager: null,
      },
      editServiceManage: {
        name: "",
        id_agency_etablishment: null,
        id_manager: null,
      },
      expanded: [],
      headers: [
        {
          text: "NOM DU SERVICE",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "CHEF DE SERVICE ",
          value: "complete_name_chief", // Assurez-vous que cette propriété est bien définie dans vos données
        },
        {
          text: "NOMBRE DE MEMBRES",
          value: "number_members",
        },
        {
          text: "SITE",
          value: "location",
        },
        {
          text: "ACTIONS",
          value: "actions",
          sortable: false,
        },
      ],
      services: [],
      fetchMembers: false,
    }
  },
  computed: {
    getActiveAgencies() {
      return this.agency.establishments.filter(agency => agency.in_activity == 1)
    },
  },
  watch: {
    tabChangeIndicator(newVal, oldVal) {
      //   this.services = []
      //   this.fetchServices()
    },
  },
  methods: {
    async postCollaboratorInService(idService, collaborators) {
      const postData = {
        idService: idService,
        collaborators: collaborators,
      }

      // using fetch to post data
      fetch(`${config.apiUri}/services/${idService}/collaborators`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")} `,
        },
        body: JSON.stringify(postData),
      })
        .finally(() => {
          this.fetchServices()
          this.fetchServicesMembers(idService, this.services.length - 1)
          this.dialogPutService = false
          this.$toast.success("Collaborateur ajouté avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    deleteServiceCollaborator(idService, idCollaborator) {
      fetch(`${config.apiUri}/services/${idService}/collaborators/${idCollaborator}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")} `,
        },
      })
        .finally(() => {
          this.fetchServices()
          this.$toast.success("Collab supprimé avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    async deleteService(id) {
      try {
        let headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)
        await fetch(`${config.apiUri}/services/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(() => {
            this.fetchServices()
            this.$toast.success("Service supprimé avec succès", {
              position: "bottom-right",
              timeout: 3000,
            })
          })
          .catch(() => {
            this.$toast.error("Une erreur est survenue")
          })
      } catch (error) {
        console.error(error)
      }
    },

    putService(item) {
      const postData = {
        id: item.id_service,
        name: item.name,
        id_manager: item.id_manager,
        id_agency_etablishment: item.id_agency_etablishment,
        id_agency: this.$store.state.user.agency_id,
      }

      // using fetch to post data
      fetch(`${config.apiUri}/services/${item.id_service_manager}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")} `,
        },
        body: JSON.stringify(postData),
      })
        .finally(() => {
          this.dialogEditService = false
          this.fetchServices()
          this.$toast.success("Service mdifier avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    postService() {
      const postData = {
        name: this.editService.name,
        id_manager: this.editService.idManager,
        id_agency_etablishment: this.editService.idAgencyEtablishment,
        id_agency: this.$store.state.user.agency_id,
      }

      // using fetch to post data
      fetch(`${config.apiUri}/services`, {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")} `,
        },
      })
        .finally(() => {
          this.fetchServices()
          this.dialogAddService = false
          this.stepperAddService = 1
          this.$toast.success("Service ajouté avec succès", {
            position: "bottom-right",
            timeout: 3000,
          })
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
          this.stepperAddService = 1
        })

      this.editService = {
        // idService: null,*
        name: "",
        idManager: null,
        idAgencyEtablishment: null,
      }
    },
    editItem(item) {
      this.editServiceManage = item
      this.dialogEditService = true
    },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    zoomIn() {
      this.$refs.tree.zoomIn()
    },
    zoomOut() {
      this.$refs.tree.zoomOut()
    },
    handleScroll(event) {
      if (event.deltaY < 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomOut
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomIn()
      } else if (event.deltaY > 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomIn
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomOut()
      }
    },
    async fetchServicesMembers(idService, index) {
      this.fetchMembers = true
      await pause(1500)

      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id
      let response

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      response = await fetch(`${config.apiUri}/agencies/${idAgency}/services/${idService}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      })

      const data = await response.json()

      this.services[index].number_members = parseInt(data.count_members_total)

      let constructChildren = []
      data.members.map(async member => {
        constructChildren.push({
          name: `${member.lastname} ${member.firstname}`,
          contract: member.contract[0],
          description: "Collaborateur",
          avatarUrl: await this.getImg2(member.lastname, member.firstname),
        })
      })

      let manager = [
        {
          name: `${this.services[index].complete_name_manager}`,
          description: "Responsable hiérarchique",
          avatarUrl: await this.getImg2(this.services[index].lastname_manager, this.services[index].firstname_manager),

          children: constructChildren,
        },
      ]

      this.nodeChart.push({
        name: `${this.services[index].name}`,
        description: "Service",
        children: manager,
      })

      this.fetchMembers = false
    },

    async fetchServices() {
      this.fetchAll = true
      this.services = []
      this.nodeChart = []
      this.fetchServicesState = true
      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/services`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          data.forEach(element => {
            // Construction des enfants (collaborateurs) pour le manager
            let constructChildren = []
            element.members.forEach(async member => {
              member.avatarUrl = await this.getImg2(member.lastname, member.firstname)
              constructChildren.push({
                name: `${member.lastname} ${member.firstname}`,
                description: "Collaborateur",
                avatarUrl: member.avatarUrl,
              })
            })

            // Définir le manager
            let manager = {
              name: `${element.manager.lastname} ${element.manager.firstname}`,
              description: "Responsable hiérarchique",
              children: constructChildren, // Les collaborateurs sont les enfants du manager
              avatarUrl: element.manager.avatarUrl, // Supposition que l'URL de l'avatar est récupérée et définie ailleurs
            }

            // Définir le chief
            let chief = {
              name: `${element.chief.lastname} ${element.chief.firstname}`,
              description: "Chef de service",
              avatarUrl: element.chief.avatarUrl, // Idem pour l'avatar du chef
              children: [], // Initialisé vide, peut être rempli plus tard
            }

            // Vérifier si le manager et le chief sont la même personne
            if (element.manager.id === element.chief.id) {
              // Si oui, le chief prend les collaborateurs comme enfants
              chief.children = constructChildren
            } else {
              // Si non, ajouter le manager comme enfant du chief
              chief.children.push(manager)
            }

            // Utiliser chief comme racine pour chartData
            element.chartData = {
              name: `${element.name}`,
              description: "Service",
              children: [chief], // Le chief est au niveau supérieur, incluant le manager si nécessaire
            }

            this.services.push({
              ...element,
              complete_name_manager: `${element.manager.lastname} ${element.manager.firstname}`,
              manager: element.manager,
              chief: element.chief ? element.chief : null, // Conditionnellement ajouter le chef si présent
              chartData: element.chartData,
              number_members: element.members.length,
            })

            // La fonction fetchServicesMembers semble être appelée ici mais n'est pas définie dans le snippet fourni
          })
        }
        await pause(3000)
        // Autres traitements
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
      this.fetchServicesState = false
      this.fetchAll = false
    },
  },

  mounted() {
    // this.fetchServices()
  },
  created() {
    // this.fetchAgency().finally(() => {
    // })
    this.fetchServices()
  },
}
</script>

<style></style>
