<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="12" md="3">
        <v-card
          outlined
          elevation="3"
          rounded="100"
          @mouseover="tasks[0].hovered = true"
          @mouseleave="tasks[0].hovered = false"
          class="align-center rounded-xl"
          @click="generateExcel"
        >
          <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{ lastMonth }}</v-card-subtitle>

          <v-card-text class="text-center pa-5 mt-n5">
            <i
              :class="['fa-solid ', tasks[0].icon, 'fa-5x', tasks[0].hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
            ></i>
            <p class="mt-5">{{ tasks[0].name }}</p>

            <v-row class="mt-n8 mb-n8">
              <v-col cols="12" md="12">
                <v-card-subtitle
                  class="text-center text-h5 EoleBlue--text font-weight-bold"
                  style="font-size: 0.9rem !important"
                  >{{ count.totalCollaboratorsConfirmed }}/{{ count.totalCollaborators }} collaborateur(s) validé(s)
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-dialog max-width="1300" v-model="dialogExportCost">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[1].hovered = true"
              @mouseleave="tasks[1].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{ lastMonth }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[1].icon,
                    'fa-5x',
                    tasks[1].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[1].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      {{ countCostsValidatedLastMonth() }} notes validées
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Export des notes de frais du cabinet {{ agency }} </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="yearFiltered"
                            :items="years"
                            label="Année"
                            outlined
                            dense
                            clearable
                            item-text="text"
                            item-value="year"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="monthFiltered"
                            :items="months"
                            label="Mois"
                            outlined
                            dense
                            clearable
                            item-text="month"
                            item-value="id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="downloadCosts">Exporter</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="2">
        <v-dialog max-width="1300" v-model="dialogImportSocialPayslip">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[2].hovered = true"
              @mouseleave="tasks[2].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{
                currentMonth
              }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[2].icon,
                    'fa-5x',
                    tasks[2].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[2].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      a déposer
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="loadingSend">
                    <v-card-title>
                      <span class="text-h5">Importer les bulletins de paies de {{ agency }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-card
                        link
                        outlined
                        @dragover.prevent
                        v-model="filesPayslip"
                        @click="importFilesPayslip"
                        class="drop-zone flex d-flex flex-column justify-center text-center"
                      >
                        <div>
                          <v-card-text class="EoleBlue--text">
                            <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                          </v-card-text>
                          <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                          <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
                        </div>
                      </v-card>

                      <!-- <v-file-input
                        ref="fileInput"
                        multiple
                        :rules="[rules.required]"
                        accept="application/pdf"
                        prepend-icon="fa-solid fa-file-pdf"
                        label="Importer des fichiers PDF"
                        @change="previewFiles"
                        chips
                      >
                        <template v-slot:selection="{ index, text }"> </template>
                      </v-file-input> -->

                      <v-dialog v-model="previewPayslipDialog" max-width="800px">
                        <v-card>
                          <v-card-title class="text-h5">Prévisualisation PDF</v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <iframe :src="selectedFilePayslipPreview" width="100%" height="500px"></iframe>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn color="blue darken-1" text @click="closePreview">Fermer</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="EoleError darken-1" text @click="deleteFile(selectedFilePayslipPreview)"
                              >supprimer</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                          v-for="(f, index) in filesPayslip"
                          class="flex d-flex flex-column"
                          v-bind:key="index"
                        >
                          <v-card
                            class="rounded-xl text-center"
                            elevation="3"
                            @click="previewFilePaySlip(f)"
                            min-height="170px"
                            max-height="170px"
                          >
                            <v-card-text class="text-center">
                              <i class="fa-solid fa-file-pdf fa-2x EoleError--text"></i>
                            </v-card-text>
                            <v-card-subtitle>
                              {{ f.name }}
                            </v-card-subtitle>
                            <v-card-text v-if="verifyRegistration(f)">
                              Matricule reconnu <v-icon color="EoleGreen">mdi-check</v-icon>
                            </v-card-text>
                            <v-card-text v-if="verifyRegistration(f)" class="mt-n5">
                              Collaborateur :
                              {{ f.lastname }} {{ f.firstname }}
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="uploadPayslip" :loading="loadingSend">Envoyer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="2">
        <v-dialog max-width="1300" v-model="dialogImportSocialStatement">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[3].hovered = true"
              @mouseleave="tasks[3].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{
                currentMonth
              }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[3].icon,
                    'fa-5x',
                    tasks[3].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[3].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      a déposer
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="loadingSend">
                    <v-card-title>
                      <span class="text-h5">Importer les Déclarations sociales de {{ agency }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-card
                        link
                        outlined
                        @dragover.prevent
                        v-model="files"
                        @click="importFiles"
                        class="drop-zone flex d-flex flex-column justify-center text-center"
                      >
                        <div>
                          <v-card-text class="EoleBlue--text">
                            <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                          </v-card-text>
                          <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                          <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
                        </div>
                      </v-card>

                      <!-- <v-file-input
                        ref="fileInput"
                        multiple
                        :rules="[rules.required]"
                        accept="application/pdf"
                        prepend-icon="fa-solid fa-file-pdf"
                        label="Importer des fichiers PDF"
                        @change="previewFiles"
                        chips
                      >
                        <template v-slot:selection="{ index, text }"> </template>
                      </v-file-input> -->

                      <v-dialog v-model="previewDialog" max-width="800px">
                        <v-card>
                          <v-card-title class="text-h5">Prévisualisation PDF</v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <iframe :src="selectedFilePreview" width="100%" height="500px"></iframe>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closePreview">Fermer</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                          v-for="(file, index) in files"
                          class="flex d-flex flex-column"
                          v-bind:key="index"
                        >
                          <v-card
                            class="rounded-xl text-center"
                            elevation="3"
                            @click="previewFile(file)"
                            min-height="150px"
                            max-height="150px"
                          >
                            <v-card-text class="text-center">
                              <i class="fa-solid fa-file-pdf fa-2x EoleError--text"></i>
                            </v-card-text>
                            <v-card-subtitle>
                              {{ file.name }}
                            </v-card-subtitle>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="upload" :loading="loadingSend">Envoyer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="2">
        <v-dialog max-width="1300" v-model="dialogImportMonthlyStatus">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[4].hovered = true"
              @mouseleave="tasks[4].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{
                currentMonth
              }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[4].icon,
                    'fa-5x',
                    tasks[4].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[4].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      a déposer
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat :loading="loadingSend">
                    <v-card-title>
                      <span class="text-h5">Importer les états mensuels de {{ agency }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-card
                        link
                        outlined
                        @dragover.prevent
                        v-model="files"
                        @click="importFiles"
                        class="drop-zone flex d-flex flex-column justify-center text-center"
                      >
                        <div>
                          <v-card-text class="EoleBlue--text">
                            <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                          </v-card-text>
                          <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                          <v-card-text class="EoleBlue--text"> Glissez et déposez un fichier ici</v-card-text>
                        </div>
                      </v-card>
                      <v-dialog v-model="previewDialog" max-width="800px">
                        <v-card>
                          <v-card-title class="text-h5">Prévisualisation PDF</v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <iframe :src="selectedFilePreview" width="100%" height="500px"></iframe>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closePreview">Fermer</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                          v-for="(file, index) in files"
                          class="flex d-flex flex-column"
                          v-bind:key="index"
                        >
                          <v-card
                            class="rounded-xl text-center"
                            elevation="3"
                            @click="previewFile(file)"
                            min-height="150px"
                            max-height="150px"
                          >
                            <v-card-text class="text-center">
                              <i class="fa-solid fa-file-pdf fa-2x EoleError--text"></i>
                            </v-card-text>
                            <v-card-subtitle>
                              {{ file.name }}
                            </v-card-subtitle>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="uploadMonthlyStatus" :loading="loadingSend">Envoyer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row> </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
import FileSaver from "file-saver"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import moment from "moment"
import "moment-business-days"

import * as XLSX from "xlsx"
import Holidays from "date-holidays"
import { getCookie } from "@/utils/cookies"

const hd = new Holidays()
hd.init("FR")
moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "OfficeSocial",
  data() {
    return {
      loadingSend: false,
      currentYear: moment().year(),
      idCurrentMonth: moment().month(),
      dialogImportMonthlyStatus: false,
      selectedFilePreview: "",
      selectedFilePayslipPreview: "",

      files: [],
      filesPayslip: [],
      previewPayslipDialog: false,
      dialogImportSocialPayslip: false,
      dialogImportSocialStatement: false,
      previewDialog: false,
      currentMonth: moment().format("MMMM").toUpperCase(),
      lastMonth: moment().subtract(1, "months").format("MMMM").toUpperCase(),
      tasks: [
        {
          id: 1,
          name: "Jours travaillés",
          done: false,
          icon: "fa-calendar",
          hovered: false,
        },
        {
          id: 2,
          name: "Notes de frais",
          done: false,
          icon: "fa-piggy-bank",
          hovered: false,
        },
        {
          id: 3,
          name: "Bulletins de paie",
          done: false,
          icon: "fa-file-invoice-dollar",
          hovered: false,
        },
        {
          id: 4,
          name: "Déclarations sociales",
          done: false,
          icon: "fa-download",
          hovered: false,
        },
        {
          id: 5,
          name: "États mensuels",
          done: false,
          icon: "fa-download",
          hovered: false,
        },
      ],
      itemsHoliday: [
        { id: 1, type: "COP", label: "Congés payés" },
        { id: 2, type: "RTT", label: "Récupération du temps de travail" },
        { id: 3, type: "JFC", label: "Jours forfait compensatoire" },
        { id: 4, type: "COEF", label: "Événement familial" },
        { id: 5, type: "MAL", label: "Maladie" },
        { id: 6, type: "CSS", label: "Congés sans solde" },
        { id: 7, type: "MP", label: "Maladie professionnelle" },
        { id: 8, type: "AT", label: "Accident du travail" },
        { id: 9, type: "MAT", label: "Maternité" },
        { id: 10, type: "PAT", label: "Paternité" },
        { id: 11, type: "ABNJ", label: "Absence non justifiée" },
        { id: 12, type: "REA", label: "Révision examen apprenti" },
        { id: 13, type: "TT", label: "Télétravail" },
        { id: 14, type: "ABJR", label: "Absence justifiée rémunurée" },
      ],
      agency: "",
      config: config,
      costs: [],
      dialogExportCost: false,
      yearFiltered: moment().year(),
      monthFiltered: moment().month() - 1,
      years: [],
      months: [
        {
          id: 0,
          month: "Janvier",
        },
        {
          id: 1,
          month: "Février",
        },
        {
          id: 2,
          month: "Mars",
        },
        {
          id: 3,
          month: "Avril",
        },
        {
          id: 4,
          month: "Mai",
        },
        {
          id: 5,
          month: "Juin",
        },
        {
          id: 6,
          month: "Juillet",
        },
        {
          id: 7,
          month: "Août",
        },
        {
          id: 8,
          month: "Septembre",
        },
        {
          id: 9,
          month: "Octobre",
        },
        {
          id: 10,
          month: "Novembre",
        },
        {
          id: 11,
          month: "Décembre",
        },
      ],
      worked_days: [],
      monthFilter: moment().month() - 1,
      publicHolidays: [],
      count: {
        totalCollaborators: 0,
        totalCollaboratorsConfirmed: 0,
      },
    }
  },
  computed: {
    filteredCollabsInfo() {
      // Vérifie que `test` est défini et que `monthFilter` a une valeur valide.
      if (!this.worked_days || this.monthFilter == null) {
        return []
      }

      // Trouver les données correspondant au mois sélectionné dans `test`
      const monthData = this.worked_days.find(data => data.month == this.monthFilter)

      if (!monthData) {
        return []
      }

      // Filtrer et traiter les données des collaborateurs pour le mois sélectionné
      const collabsInfo = monthData.collabs_info.map(collaborator => {
        console.log("coll", collaborator)
        // Traiter `worked_days` pour chaque collaborateur
        const workedDaysProcessed = collaborator.worked_days_history.flatMap(workedDay => {
          if (workedDay.days == null) return [] // Gérer le cas où `days` est null ou undefined

          // Analyser la chaîne JSON des jours travaillés/absences
          try {
            const days = JSON.parse(workedDay.days)
            // Filtrer les jours qui correspondent au type et à l'état désirés, si nécessaire
            return days.filter(day => {
              // Exemple de filtrage supplémentaire, si nécessaire
              return true // Remplacer par la condition de filtrage appropriée
            })
          } catch (error) {
            console.error("Erreur lors de l'analyse de JSON pour les jours travaillés", error)
            return []
          }
        })

        return {
          ...collaborator,
          worked_days_processed: workedDaysProcessed,
        }
      })

      // Filtrer par nom de famille et prénom si le filtre utilisateur est spécifié
      const filteredCollabs = this.userFilter
        ? collabsInfo.filter(
            collaborator =>
              collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
              collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase()),
          )
        : collabsInfo

      return filteredCollabs.filter(collaborator => collaborator.worked_days_processed.length > 0)
    },
  },
  watch: {},
  methods: {
    verifyRegistration(file) {
      console.log(file)
      return this.accountsRegistration.find(account => {
        let findItem = `${account.lastname}_${account.firstname}`.toLowerCase()
        console.log(findItem)
        return findItem == file.uname
      })
    },
    async getRegistration() {
      const idAgency = this.$store.state.user.id_agency
      const res = await fetch(`${config.apiUri}/agencies/${idAgency}/registrations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      })
      const data = await res.json()

      this.accountsRegistration = data
      console.log(data)
    },
    getIdAccount(file) {
      return this.accountsRegistration.find(account => {
        let findItem = `${account.lastname}_${account.firstname}`.toLowerCase()
        return findItem == file.uname
      })
    },
    verifyFileExtension(file) {
      // pdf and xml files
      const allowedExtensions = /(\.pdf|\.xml)$/i
      return allowedExtensions.exec(file.name)
    },
    closePreview() {
      this.previewDialog = false
    },
    importFilesPayslip() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "application/pdf"
      input.multiple = true

      input.onchange = e => {
        const filesPayslip = Array.from(e.target.files).filter(file => this.verifyFileExtension(file))
        console.log(filesPayslip)
        if (filesPayslip.length > 0) {
          this.previewFilesPaySlip(filesPayslip)
        }
      }

      input.click()
    },
    importFiles() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "application/pdf, application/xml"
      input.multiple = true

      input.onchange = e => {
        const files = Array.from(e.target.files).filter(file => this.verifyFileExtension(file))

        if (files.length > 0) {
          this.previewFiles(files)
        }
      }

      input.click()
    },

    async uploadPayslip() {
      this.loadingSend = true
      try {
        for (const file of this.filesPayslip) {
          const formData = new FormData()

          formData.append("id", file.idAccount)
          formData.append("lastname", file.lastname)
          formData.append("firstname", file.firstname)
          formData.append("agency", this.$store.state.user.agency_name)
          formData.append(
            "documentName",
            `Fiche de paie du mois de ${this.getMonthName(this.idCurrentMonth)} ${this.currentYear}`,
          )
          formData.append("endpoint", "payslip")
          formData.append("file", file)

          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")} `,
            },
            body: formData,
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.$toast.success(
          `Fichiers envoyé avec succès pour ${this.filesPayslip.map(file => `${file.lastname} ${file.firstname}`)}`,
          {
            duration: 5000,
            position: "bottom-right",
          },
        )
        this.filesPayslip = []
        this.loadingSend = false
        this.dialogImportSocialPayslip = false
      }
    },

    async upload() {
      this.loadingSend = true // Démarre l'indicateur de chargement
      try {
        for (const [index, file] of this.files.entries()) {
          const formData = new FormData()
          formData.append("idAgency", this.$store.state.user.id_agency)
          formData.append("agency", this.$store.state.user.agency_name)
          formData.append("documentName", file.name.split(".")?.[0])
          //if file extension is xml endpoint is testXML
          if (file.name.split(".")[1] === "xml") {
            formData.append("endpoint", "testXML")
          } else {
            formData.append("endpoint", "social")
          }
          formData.append("file", file)

          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: formData,
          })

          if (!res.ok) throw new Error("Erreur lors de l'upload du PDF")
        }
        this.dialogImportSocialStatement = false
        this.$toast.success("Fichiers envoyés avec succès", {
          duration: 5000,
          position: "bottom-right",
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      } finally {
        this.files = [] // Réinitialiser les fichiers après l'envoi
        this.loadingSend = false // Arrête l'indicateur de chargement
      }
    },
    async uploadMonthlyStatus() {
      this.loadingSend = true // Démarre l'indicateur de chargement
      try {
        for (const [index, file] of this.files.entries()) {
          const formData = new FormData()
          formData.append("idAgency", this.$store.state.user.id_agency)
          formData.append("agency", this.$store.state.user.agency_name)
          formData.append("documentName", file.name.split(".")?.[0])
          formData.append("endpoint", "monthlyStatus")
          formData.append("file", file)

          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: formData,
          })

          if (!res.ok) throw new Error("Erreur lors de l'upload du PDF")
        }
        this.dialogImportMonthlyStatus = false
        this.$toast.success("Fichiers envoyés avec succès", {
          duration: 5000,
          position: "bottom-right",
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      } finally {
        this.files = [] // Réinitialiser les fichiers après l'envoi
        this.loadingSend = false // Arrête l'indicateur de chargement
      }
    },

    async verifyLastname(lastname) {
      console.log("lastname", lastname)
      const account = this.accountsRegistration.find(
        account => account.maiden_name?.toLowerCase() === lastname.toLowerCase(),
      )
      console.log(account?.maiden_name.toLowerCase(), lastname)
      if (account) {
        console.log("find", account.lastname)
        return account.lastname.toLowerCase()
      }
      return lastname // Retourne le lastname original si pas de correspondance
    },
    async previewFilesPaySlip(files) {
      // Traite les fichiers ici, en filtrant par noms_prenoms de manière asynchrone
      const processedFilesPromises = files.map(async file => {
        // Extraire les informations nécessaires du nom du fichier
        const splitName = file.name.split("_")
        if (splitName.length < 5) {
          // S'assurer que le format du nom du fichier est correct
          this.$toast.error("Le format du nom de fichier est incorrect.", {
            duration: 5000,
            position: "bottom-right",
          })
          return null
        }

        // Attendre la vérification du lastname
        const lastname = await this.verifyLastname(splitName[2])
        const concat = lastname + "_" + splitName[3].toLowerCase()

        // Suite du traitement...
        file.uname = concat
        file.nameWithoutExtension = file.name.split(".")[0]
        file.lastname = lastname
        file.firstname = splitName[3]
        file.idAccount = this.getIdAccount(file)?.id

        // Vérifier si le fichier existe déjà
        if (this.filesPayslip.find(f => f.name == file.name)) {
          this.$toast.error("Le fichier existe déjà", {
            duration: 5000,
            position: "bottom-right",
          })
          return null
        }

        if (this.verifyRegistration(file)) {
          file.preview = URL.createObjectURL(file)
          return file
        } else {
          this.$toast.error("Le nom de fichier est incorrect ou le matricule n'est pas reconnu par le système", {
            duration: 5000,
            position: "bottom-right",
          })
          return null
        }
      })

      // Attendre le traitement de tous les fichiers
      const processedFiles = (await Promise.all(processedFilesPromises)).filter(file => file !== null)

      // Ajouter les fichiers traités à la liste existante
      this.filesPayslip = this.filesPayslip.concat(processedFiles)
    },
    deleteFile(file) {
      this.filesPayslip = this.filesPayslip.filter(f => f.preview !== file)
      this.previewPayslipDialog = false
    },
    previewFilePaySlip(file) {
      console.log(file)
      this.selectedFilePayslipPreview = file.preview || URL.createObjectURL(file)
      this.previewPayslipDialog = true
    },
    previewFiles(files) {
      // Traite les fichiers ici, par exemple :
      const processedFiles = files.map(file => {
        if (!file.preview) {
          file.preview = URL.createObjectURL(file)
        }
        return file
      })

      // Ajoute les fichiers traités à votre liste de fichiers existants
      this.files = this.files.concat(processedFiles)

      console.log(this.files)
    },
    previewFile(file) {
      this.selectedFilePreview = file.preview || URL.createObjectURL(file)
      this.previewDialog = true
    },
    async downloadCosts() {
      const agencyId = this.$store.state.user.id_agency

      const date = `${this.yearFiltered}-${this.monthFiltered + 1}`

      try {
        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/costs/${date}/export`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
            // Ajoutez ici d'autres en-têtes si nécessaire, par exemple pour l'authentification
          },
        })

        if (!response.ok) {
          throw new Error("Problème lors du téléchargement du fichier")
        }

        const blob = await response.blob()

        // Crée un URL pour le blob
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = downloadUrl

        // Définissez le nom de fichier pour le téléchargement
        const filename = `costs_${date}.zip`
        a.setAttribute("download", filename)
        document.body.appendChild(a)
        a.click()

        // Nettoie l'objet URL après le téléchargement
        window.URL.revokeObjectURL(downloadUrl)
        a.remove()
      } catch (error) {
        console.error("Erreur lors du téléchargement :", error)
        alert("Erreur lors du téléchargement des documents.")
      }
    },

    countCostsValidatedLastMonth() {
      let count = 0
      const lastMonthId = moment().month()
      const currentYear = moment().year()

      this.costs.forEach(cost => {
        console.log(cost)
        count++
      })

      return count
    },
    countConfirmWorkingDays() {
      let count = 0
      //get current month id from moment -1
      let currentMonth = moment().month() - 1
      console.log("currentMonth", currentMonth)
      this.count.totalCollaboratorsConfirmed = 0

      this.worked_days.forEach(wk => {
        if (wk.month == currentMonth) {
          this.count.totalCollaborators = wk.collabs_info.length
          console.log(wk)
          wk.collabs_info.forEach(collab => {
            if (collab.worked_days_history.length > 0) {
              this.count.totalCollaboratorsConfirmed++
            }
          })
        }
      })
      return count
    },
    getMonthName(month) {
      return moment().month(month).format("MMMM")
    },
    // Fonction pour générer des styles de bordure
    getBorderStyle() {
      return {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
      }
    },

    // Fonction pour appliquer le style à une cellule spécifique
    setCellStyle(ws, cellRef, style) {
      ws[cellRef] = ws[cellRef] || {}
      ws[cellRef].s = { ...ws[cellRef].s, ...style }
    },
    generateExcel() {
      const { data, merges } = this.createExcelData()
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(data)

      // Appliquer les fusions de cellules
      ws["!merges"] = merges
      // Appliquer les styles de bordure à toutes les cellules
      data.forEach((row, rowIndex) => {
        row.forEach((_, colIndex) => {
          const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
          this.setCellStyle(ws, cellRef, { border: this.getBorderStyle() })
        })
      })

      // Appliquer les styles (si nécessaire)
      // Assurez-vous d'appliquer des styles seulement après avoir ajouté les fusions
      data.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          if (cell !== "JT" && rowIndex > 1) {
            // Ignorer les en-têtes et la seconde ligne pour 'm' et 'am'
            const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
            ws[cellRef] = ws[cellRef] || {}
            ws[cellRef].s = {
              fill: { fgColor: { rgb: "FFFF00" } },
            }
          }
        })
      })

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
      XLSX.writeFile(wb, `${this.getMonthName(this.monthFilter)}.xlsx`)
    },
    createExcelData() {
      const days = this.getDayByMonth(this.monthFilter) // Suppose que cela retourne un tableau de moments/dates
      const headers = ["Nom", "Affilié mutuelle ?", "Télétravail ?"]
      const merges = [] // Configuration des fusions pour les en-têtes de jour

      // Ajouter les en-têtes de jour avec "AM" et "PM" en dessous
      days.forEach((day, index) => {
        const formattedDay = day.format("DD MMM")
        headers.push(formattedDay) // Ajouter l'en-tête de jour
        // Ajouter la configuration de fusion pour l'en-tête de jour
        const baseIndex = 3 + index * 2 // 3 pour les colonnes 'Nom', 'Affilié mutuelle ?', 'Télétravail ?'
        merges.push({ s: { r: 0, c: baseIndex }, e: { r: 0, c: baseIndex + 1 } })
        // On ajoute également une cellule vide après chaque en-tête de jour pour la fusion
        headers.push("") // Cellule vide pour "PM"
      })

      // Créez une seconde ligne avec 'm' et 'am' pour chaque jour
      // La seconde ligne commence avec trois cellules vides pour les trois premières colonnes
      const secondRow = new Array(3).fill(null).concat(days.flatMap(() => ["m", "am"]))

      const data = [headers, secondRow]

      this.filteredCollabsInfo.forEach(collaborator => {
        const rowData = [
          `${collaborator.lastname} ${collaborator.firstname}`,
          collaborator.is_affiliated_mutual == 1 ? "oui" : "non",
          collaborator.is_telework == 1 ? "oui" : "non",
        ]

        // Insérez ici le code pour traiter les jours travaillés et les absences
        days.forEach(day => {
          let amData = "JT" // Par défaut, journée travaillée
          let pmData = "JT" // Par défaut, journée travaillée

          collaborator.worked_days_processed.forEach(absence => {
            const absenceStartDate = new Date(absence.start_date)
            const absenceEndDate = new Date(absence.end_date)
            const currentDay = new Date(day.format("YYYY-MM-DD"))

            if (currentDay >= absenceStartDate && currentDay <= absenceEndDate) {
              if (absence.is_half_day === 1) {
                if (absence.half_day_type === 1) {
                  amData = absence.type
                } else if (absence.half_day_type === 2) {
                  pmData = absence.type
                }
              } else {
                amData = absence.type
                pmData = absence.type
              }
            }
          })

          rowData.push(amData, pmData)
        })

        data.push(rowData)
      })

      // Ajout d'une ligne vide pour séparer les tableaux
      data.push([""])

      // Ajout des en-têtes pour le tableau des types de congés
      data.push(["Abbréviation", "Type de congé"])

      // Parcourir itemsHoliday pour remplir les données des types de congés
      this.itemsHoliday.forEach(holiday => {
        data.push([holiday.type, holiday.label])
      })

      // Ajoutez d'autres lignes ou sections si nécessaire

      return { data, merges }
    },
    async fetchAgencyHoliday() {
      this.worked_days = []

      let idAgency = this.$store.state.user.id_agency
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Origin: "*",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        data.worked_days.forEach(wd => {
          this.worked_days.push(wd)
        })
      } catch (e) {
        console.log(e)
      }
    },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    getDayByMonth(month) {
      const year = moment().year() // Utilisez l'année actuelle ou une autre logique pour l'année
      const daysInMonth = moment([year, month]).daysInMonth()
      let days = []

      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = moment([year, month]).date(day)
        // Vérifiez si c'est un jour ouvrable et pas un jour férié
        if (currentDate.isBusinessDay() && !this.publicHolidays.includes(currentDate.format("YYYY-MM-DD"))) {
          days.push(currentDate)
        }
      }

      return days
    },

    async fetchCosts() {
      this.costs = []

      try {
        let headers = new Headers()
        let idAgency = this.$store.state.user.id_agency

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/costs/validated`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          // Filtrage initial pour les managers
          let filteredUsers = data.costs

          filteredUsers.forEach(user => {
            user.costs.forEach(yearObj => {
              yearObj.months.forEach(monthObj => {
                let totalMonth = 0
                monthObj.costs.forEach(item => {
                  // Traitement de chaque item de coût
                  item.designation = item.designation_category
                  item.price = parseFloat(item.price)
                  item.documents = JSON.parse(item.documents)
                  item.data = item.data != null ? JSON.parse(item.data) : []
                  item.date_display = moment(item.date).format("DD/MM/YYYY")
                  item.date_cost_display = moment(item.date_cost).format("DD/MM/YYYY")
                  item.month_display = moment(item.date_cost).format("MMMM")
                  item.year = parseInt(moment(item.date_cost).format("YYYY"))

                  totalMonth += item.price
                })

                // Conditions pour ajouter dans costsOrdered ou costsOrderedHistory
                let conditionToAdd = parseInt(monthObj.state) === 1
                // let targetArray = conditionToAdd ? this.costsOrdered : this.costsOrderedHistory

                if (conditionToAdd || parseInt(monthObj.state) === 2 || parseInt(monthObj.state) === 3) {
                  this.costs.push({
                    // Ajout des coûts filtrés avec les informations utilisateur
                    year: yearObj.year,
                    id_cost_manager: parseInt(monthObj.id_cost_manager),
                    idMonth: monthObj.costs[0] ? parseInt(moment(monthObj.costs[0].date_cost).format("MM")) : 0,
                    month: monthObj.costs,
                    user: {
                      id: user.id,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      complete_name: `${user.firstname} ${user.lastname}`,
                    },
                    monthPrice: totalMonth,
                    state: parseInt(monthObj.state),
                    months: monthObj.costs[0] ? moment(monthObj.costs[0].date_cost).format("MMMM") : "",
                  })
                }
              })
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },

  async created() {
    this.agency = this.$store.state.user.agency_name
    this.getRegistration()

    this.fetchAgencyHoliday().finally(() => {
      console.log(this.countConfirmWorkingDays())
    })

    this.fetchCosts()

    //generer année de à année en cours
    let currentYear = new Date().getFullYear()
    for (let i = 2021; i <= currentYear; i++) {
      this.years.push({ year: i, text: i.toString() })
    }
  },
}
</script>

<style>
.import .v-btn--icon.v-size--default {
  height: 64px !important;
  width: 64px !important;
}
</style>
