<template>
  <div v-if="!dataFetch" class="test">
    <v-card outlined class="my-5 mt-10">
      <v-card-text class="profilAgencyAvatar">
        <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
          <v-card-title style="color: white !important; display: inline-block">
            <i class="fa-solid fa-building mr-2 ml-2" style="font-size: 1.75rem"></i>
            {{ agency.name }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text class="mt-16">
        <v-row v-if="!!agency">
          <v-col cols="12" md="6" class="text-left">
            <strong>Code NAF/APE :</strong> {{ agency.naf_code }} <br />
            <strong>Forme juridique :</strong> {{ agency.juridique_forme }} <br />

            <strong>Date de création :</strong> {{ agency.creation_date_formated }} <br />
            <strong>Nom commercial :</strong> {{ agency.brand_name }} <br />
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <strong> Numéro de siren : </strong> {{ agency.siret }} <br />
            <v-spacer> </v-spacer>
            <h3>
              <a @click="showMap(`${agency.street} ${agency.zip} ${agency.city}`)"
                >{{ agency.street }}
                <br />
                {{ agency.zip }}, {{ agency.city }}</a
              >
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogUpload.dialog"
      max-width="1200px"
      @click:outside="
        document = {
          name: '',
          data: '',
          id_agency_etablishment: 999,
        }
        fileUrl = null
      "
    >
      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
        <v-card-text style="background-color: #f1f4f9">
          <v-row>
            <v-col cols="12" md="3" class="no-padding ml-n2">
              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized"
                  >Mes Services Assurance</v-card-title
                >
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" class="no-padding d-flex">
              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                <v-card-title>
                  <span class="text-h5">Ajouter une nouveau document</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label="Nom du document"
                        v-model="document.title"
                        :suffix="document.extension"
                        dense
                      >
                      </v-text-field>
                      <v-select
                        label="Catégorie du document"
                        :items="etablishmentsOptions"
                        item-value="id"
                        item-text="name"
                        outlined
                        v-model="document.id_agency_etablishment"
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <div>
                        <span v-if="document === null && fileUrl === null"
                          >Aucun document n'est pour le moment disponible.</span
                        >
                        <iframe
                          v-else-if="
                            document != null && document.data != null && document.data.type === 'application/pdf'
                          "
                          id="lddcVisualisator"
                          style="width: 100%; height: 500px"
                          :src="fileUrl"
                        ></iframe>

                        <v-row
                          align="center"
                          v-else-if="
                            document != null &&
                            document.data != null &&
                            document.data.type ===
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                          "
                          style="height: 225px"
                        >
                          <v-col cols="12" md="12">
                            <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          v-else-if="document != null && document.data != null && document.data.type === 'image/png'"
                          style="height: 225px"
                        >
                          <v-col cols="12" md="12">
                            <v-img :src="fileUrl" contain></v-img>
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          v-else-if="document != null && document.data != null && document.data.type === 'image/jpeg'"
                          style="height: 225px"
                        >
                          <v-col cols="12" md="12">
                            <v-img :src="fileUrl" contain></v-img>
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          v-else-if="document != null && document.data != null && document.data.type === 'image/jpg'"
                          style="height: 225px"
                        >
                          <v-col cols="12" md="12">
                            <v-img :src="fileUrl" contain></v-img>
                          </v-col>
                        </v-row>
                      </div>

                      <v-card-text v-if="document.url != null">
                        <v-row align="center"> </v-row>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="EoleYellow"
                    text
                    @click="
                      () => {
                        dialogUpload.dialog = false
                        document = {
                          name: '',
                          data: '',
                        }
                      }
                    "
                  >
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="
                      uploadPutFile($event, dialogUpload.type, dialogUpload.area, dialogUpload.subType, editFile.id)
                    "
                  >
                    Ajouter un nouveau document</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadPostFile"
      max-width="1200px"
      @click:outside="
        document = {
          name: '',
          data: '',
        }
        documentArea = {
          type: 0,
          area: '',
          subType: '',
        }
        fileUrl = null
      "
    >
      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
        <v-card-text style="background-color: #f1f4f9">
          <v-row>
            <v-col cols="12" md="3" class="no-padding ml-n2">
              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                <v-card-title>
                  <v-img
                    :src="require('@/assets/images/logos/anavel_logo.png')"
                    max-height="150px"
                    max-width="120px"
                    alt="logo"
                    contain
                  ></v-img>
                </v-card-title>
                <v-card-title class="text-center justify-center muller-capitalized"
                  >Mes Services Assurance</v-card-title
                >
                <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                <v-card-text class="no-padding">
                  <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="9" class="no-padding d-flex">
              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                <v-card-title>
                  <span class="text-h5">Ajouter une nouveau document</span>
                </v-card-title>
                <v-card-text class="pa-5">
                  <v-row>
                    <v-col cols="12" sm="6" md="12">
                      <v-card flat class="text-center align-center justify-center">
                        <v-row class="pa-5">
                          <v-col cols="12" md="12">
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field outlined label="Nom du document" v-model="document.name"> </v-text-field>
                                <v-select
                                  label="Catégorie du document"
                                  :items="etablishmentsOptions"
                                  item-value="id"
                                  item-text="name"
                                  outlined
                                  v-model="document.id_agency_etablishment"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" md="6">
                                <div>
                                  <span v-if="document === null && fileUrl === null"
                                    >Aucun document n'est pour le moment disponible.</span
                                  >
                                  <iframe
                                    v-else-if="
                                      document != null &&
                                      document.data != null &&
                                      document.data.type === 'application/pdf'
                                    "
                                    id="lddcVisualisator"
                                    style="width: 100%; height: 500px"
                                    :src="fileUrl"
                                  ></iframe>

                                  <v-row
                                    align="center"
                                    v-else-if="
                                      document != null &&
                                      document.data != null &&
                                      document.data.type ===
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                    "
                                    style="height: 225px"
                                  >
                                    <v-col cols="12" md="12">
                                      <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                    </v-col>
                                  </v-row>
                                </div>

                                <v-card-text v-if="document.url != null">
                                  <v-row align="center"> </v-row>
                                </v-card-text>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="EoleYellow"
                    text
                    @click="
                      () => {
                        dialogUploadPostFile = false
                        document = {
                          name: '',
                          data: '',
                        }
                        documentArea = {
                          type: 0,
                          area: '',
                          subType: '',
                        }
                      }
                    "
                  >
                    Annuler
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text @click="uploadFile($event, documentArea.type, documentArea.area, documentArea.subType)">
                    Ajouter un nouveau document</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-tabs
      color="EoleYellow"
      class="text-left"
      v-model="tab"
      style="box-shadow: none !important"
      background-color="transparent"
      @change="tabChanged"
    >
      <v-tab v-if="verifyRights('office')" id="office" href="#tab-1" @click="recordMenu('tab-1')"> Mon cabinet </v-tab>
      <v-tab
        v-else-if="user.grade === 'cabinet social' || user.grade === 'expert comptable'"
        id="office"
        href="#tab-1"
        @click="recordMenu('tab-1')"
      >
        Mes tâches
      </v-tab>
      <!-- <v-tab v-else-if="user.grade === 'manager due'" id="office" href="#tab-1" @click="recordMenu('tab-1')">
        Articles
      </v-tab> -->

      <v-tab
        v-if="verifyRights('office-collaborators')"
        id="office-collaborators"
        href="#tab-7"
        @click="recordMenu('tab-7')"
      >
        Collaborateurs
      </v-tab>
      <v-tab v-if="verifyRights('office-services')" id="office-services" href="#tab-2" @click="recordMenu('tab-2')">
        Mon organisation
      </v-tab>
      <v-tab v-if="verifyRights('office-rh')" id="office-rh" href="#tab-3" @click="recordMenu('tab-3')">
        Ressources humaines
      </v-tab>
      <v-tab v-if="verifyRights('office-hollidays')" id="office-hollidays" href="#tab-8" @click="recordMenu('tab-8')">
        <v-badge v-if="newDemandCount > 0" color="EoleError" :content="newDemandCount"> Congés </v-badge>
        <span v-else>Congés</span>
      </v-tab>
      <v-tab
        v-if="verifyRights('office-working-days')"
        id="office-working-days"
        href="#tab-10"
        @click="recordMenu('tab-10')"
      >
        Jours travaillés
      </v-tab>
      <v-tab v-if="verifyRights('office-cost')" id="office-cost" href="#tab-9" @click="recordMenu('tab-9')">
        <v-badge v-if="newCostCount > 0" color="EoleError" :content="newCostCount"> Frais </v-badge>
        <span v-else>Frais</span>
      </v-tab>
      <v-tab v-if="verifyRights('office-training')" id="office-training" href="#tab-5" @click="recordMenu('tab-5')">
        Formations
      </v-tab>
      <v-tab
        v-if="verifyRights('office-maintenances')"
        id="office-maintenances"
        href="#tab-6"
        @click="recordMenu('tab-6')"
      >
        Entretiens
      </v-tab>
      <v-tab v-if="verifyRights('office-process')" id="office-process" href="#tab-4" @click="recordMenu('tab-4')">
        Procédures
      </v-tab>
    </v-tabs>
    <v-divider class="mb-10"></v-divider>
    <v-tabs-items v-model="tab" style="background-color: transparent !important">
      <v-tab-item :value="'tab-1'">
        <div v-if="user.grade === 'administrateur' || user.grade === 'superadministrateur'">
          <v-row>
            <v-col cols="12" md="4" class="d-flex">
              <v-card outlined elevation="3" class="rounded-xl">
                <v-card-title class="justify-center">Complétudes d'informations</v-card-title>
                <v-row align-content="center">
                  <v-col cols="12" md="12" align-self="center">
                    <v-card-subtitle class="text-center"
                      >Vous pouvez retrouver ci-dessous des informations en lien avec la complétude de vos données.
                      <br />Si des données sont manquantes elles vous seront indiqués.
                    </v-card-subtitle>
                    <v-card-text class="pa-5 mt-2">
                      <v-row class="justify-center text-center text-caption">
                        <v-col cols="12" md="4">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-progress-circular
                                v-bind="attrs"
                                v-on="on"
                                size="64"
                                :value="obligatoryInformations.percentage"
                                rotate="270"
                                :color="getProgressColor(obligatoryInformations.percentage)"
                              >
                                {{ obligatoryInformations.percentage }}%
                              </v-progress-circular>
                            </template>
                            <span>
                              <v-row>
                                <v-col cols="12" md="12" class="text-left">
                                  <ul v-if="missingFields.length > 0" class="EoleYellow--text no-bullets">
                                    <li
                                      v-for="missingFields in obligatoryInformations.fields"
                                      v-bind:key="missingFields"
                                    >
                                      <v-icon color="EoleYellow" size="16">mdi-close-thick</v-icon>
                                      {{ missingFields }}
                                    </li>
                                  </ul>
                                  <span v-else class="EoleGreen--text">Toutes les informations sont complètes</span>
                                </v-col>
                              </v-row>
                            </span>
                          </v-tooltip>

                          <br />
                          Informations <br />
                          cabinet
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-progress-circular
                            size="64"
                            :value="valueOrganization"
                            rotate="270"
                            :color="valueOrganization === 100 ? 'EoleGreen' : 'EoleError'"
                            :indeterminate="fetchServicesState"
                          >
                            <span v-if="valueOrganization != 100">{{ valueOrganization }}%</span>
                            <v-icon v-else>mdi-check</v-icon>
                          </v-progress-circular>
                          <br />
                          Mon <br />
                          organisation
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-progress-circular
                            size="64"
                            :value="obligatoryFilesCount.percentage"
                            rotate="270"
                            :color="getProgressColor(obligatoryFilesCount.percentage)"
                          >
                            {{ obligatoryFilesCount.value }} / {{ obligatoryFilesCount.max }}
                          </v-progress-circular>
                          <br />
                          Ressources <br />
                          humaines
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="d-flex">
              <v-card outlined elevation="3" class="flex-fill flex d-flex flex-column rounded-xl">
                <v-card-title class="justify-center">Mon abonnement</v-card-title>

                <v-card-text class="flex-grow-1 no-padding text-center">
                  <v-card-text>Votre formule actuelle : <strong> Essential</strong>. </v-card-text>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">Actif depuis le 01/12</v-col>
                      <v-col class="text-right">Résiliable jusqu'au 20/12</v-col>
                    </v-row>
                    <v-progress-linear height="10" color="EoleYellow" rounded value="60"> </v-progress-linear>
                  </v-card-text>
                  <v-card-title class="mt-n4 justify-center">Formule et options : 90€/mois </v-card-title>
                  <v-card-text> Moyen de paiement : <strong> prélevement automatique</strong> </v-card-text>

                  <v-card-text>
                    Vous souhaitez changer de formule pour accéder à encore plus de fonctionnalités ?
                  </v-card-text>
                </v-card-text>

                <v-card-actions class="mt-5">
                  <v-row>
                    <v-col cols="12" md="6" class="text-left">
                      <v-btn width="200px" color="EoleYellow" class="white--text">Paiement </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" class="text-right">
                      <v-btn width="200px" color="EoleBlue" class="white--text">Changer d'offre </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" class="d-flex">
              <v-card outlined elevation="3" class="rounded-xl" width="100%">
                <v-card-title class="justify-center">Mon logo</v-card-title>
                <v-row class="text-center" align="center" justify="center" style="flex-grow: 1">
                  <v-col cols="12">
                    <div class="d-flex flex-column fill-height">
                      <v-card-text class="text-center mt-3">
                        <v-skeleton-loader v-if="loadImage" style="" type="image"></v-skeleton-loader>
                        <v-avatar
                          id="profil-picture"
                          v-else-if="agencyPictureProfil != null"
                          class="avatar bordered"
                          size="256"
                        >
                          <v-img contain :src="agencyPictureProfil"></v-img>
                          <div class="hover-overlay" v-on:click="openFileExplorer">
                            <span class="overlay-text">Changer votre logo d'entreprise</span>
                          </div>
                        </v-avatar>
                        <v-avatar v-else class="avatar bordered" size="256" color="EoleBlue">
                          <div class="hover-overlay" v-on:click="openFileExplorer">
                            <span class="overlay-text">Changer votre logo d'entreprise</span>
                          </div>
                          <span class="white--text"
                            >En cliquant ici vous pouvez ajouter votre premier logo d'entreprise. Celui-ci sera utilisé
                            pour l'intégralité des documents édités</span
                          >
                        </v-avatar>
                      </v-card-text>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <div v-if="agency.agent == 1">
            <v-card-title
              class="no-padding mt-10"
              style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
            >
              MES MENTIONS LÉGALES AGENT
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right>mdi-information</v-icon>
                </template>
                <span>Ces mentions légales apparaîtront dans chacun des documents édités</span>
              </v-tooltip>
            </v-card-title>
            <v-divider class="mb-6 mt-2"></v-divider>

            <v-row>
              <v-col>
                <v-card outlined elevation="3" class="rounded-xl">
                  <v-card-text>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Mentions légales"
                      v-model="agency.legal_mention_agent"
                      auto-grow
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"> </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn color="EoleYellow" class="white--text" outlined @click="putAgencyLegalNotice('agent')"
                          >Sauvegarder</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-if="agency.middleman == 1">
            <v-card-title
              class="no-padding mt-10"
              style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
            >
              MES MENTIONS LÉGALES COURTIER
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right>mdi-information</v-icon>
                </template>
                <span>Ces mentions légales apparaîtront dans chacun des documents édités</span>
              </v-tooltip>
            </v-card-title>
            <v-divider class="mb-6 mt-2"></v-divider>

            <v-row>
              <v-col>
                <v-card outlined elevation="3" class="rounded-xl">
                  <v-card-text>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Mentions légales"
                      v-model="agency.legal_mention_middleman"
                      auto-grow
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"> </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          color="EoleYellow"
                          class="white--text"
                          outlined
                          @click="putAgencyLegalNotice('middleman')"
                          >Sauvegarder</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-card-title
              class="no-padding mt-10"
              style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
              >MES SOCIÉTÉS ET ÉTABLISSEMENTS ACTIFS</v-card-title
            >
            <v-divider class="mb-6 mt-2"></v-divider>
            <v-card class="rounded-l-xl" outlined elevation="3" height="350px">
              <v-row>
                <v-col cols="12" md="8">
                  <l-map style="height: 348px" :zoom="8" :center="initialCoordinates" class="rounded-l-xl">
                    <l-tile-layer :url="tileLayerUrl"></l-tile-layer>
                    <l-feature-group>
                      <l-marker
                        v-for="(etablishment, index) in getActiveAgencies"
                        v-bind:key="etablishment.id"
                        :lat-lng="[etablishment.lattitude, etablishment.longitude]"
                      >
                        <l-tooltip :options="{ permanent: true, interactive: true }">
                          <div>
                            <strong>{{ etablishment.brand_name }} ({{ etablishment.siret }})</strong>
                            <br />
                            {{ etablishment.street }} <br />
                            {{ etablishment.zip }}, {{ etablishment.city }}
                          </div>
                        </l-tooltip>
                      </l-marker>
                    </l-feature-group>
                  </l-map>
                </v-col>
                <v-col cols="12" md="4">
                  <v-list class="rounded-xl">
                    <div
                      class="pb-3 pt-2"
                      v-for="(etablishment, index) in getActiveAgencies"
                      v-bind:key="etablishment.id"
                    >
                      <v-avatar class="bordered" color="EoleBlueLighten">
                        <i class="fa-solid fa-building EoleBlue--text" style="font-size: 1.3rem"></i>
                      </v-avatar>

                      <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
                        <span class="text--primary font-weight-semibold mb-n1"
                          >{{ etablishment.brand_name }} ({{ etablishment.siret }})
                        </span>

                        <small>
                          {{ etablishment.street }} <br />
                          {{ etablishment.zip }}, {{ etablishment.city }}</small
                        >
                      </div>
                      <v-divider v-if="index != agency.establishments.length - 1" class="mt-5"> </v-divider>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <div>
            <v-card-title
              class="no-padding mt-10"
              style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
              >LES DIRIGEANTS</v-card-title
            >
            <v-divider class="mb-6 mt-2"></v-divider>

            <v-row>
              <v-col cols="12" md="12" class="d-flex">
                <v-row class="mb-5">
                  <v-col
                    class="flex d-flex flex-column"
                    cols="12"
                    md="3"
                    v-for="director in agency.directors"
                    v-bind:key="director.id"
                  >
                    <v-card outlined class="flex d-flex flex-column rounded-xl" elevation="3">
                      <v-card-text class="text-center">
                        <v-icon size="64" v-if="director.sexe === 'M'">mdi-human-male</v-icon>
                        <v-icon size="64" v-else>mdi-human-male</v-icon>
                      </v-card-text>

                      <v-card-text class="EoleYellow--text text-h6 text-center">
                        {{ director.lastname }} {{ director.firstname }}
                      </v-card-text>

                      <v-card-text>
                        <v-row class="my-2">
                          <v-card-text><strong>Qualité : </strong>{{ director.quality }} </v-card-text>
                          <v-card-text
                            ><strong>Domicilié au : </strong> {{ director.street }} <br />{{ director.city }},
                            {{ director.zip }}</v-card-text
                          >
                          <v-card-text
                            ><strong>Né(e) le : </strong>{{ director.born_date }} à
                            {{ director.born_city }}
                          </v-card-text>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="mx-auto mt-n7">
                        <v-switch label="Signataire" inset></v-switch>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <v-card-title
            class="no-padding mt-10"
            style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
          >
            <v-row>
              <v-col cols="12" md="6" class="text-left" align-self="center">Documents officiels du cabinet</v-col>
              <v-col cols="12" md="6" class="text-right">
                <v-btn
                  fab
                  icon
                  class="white--text"
                  color="EoleYellow"
                  outlined
                  href="mailto:?subject=Objet du mail&body=Corps du mail"
                  target="_blank"
                  small
                >
                  <v-icon>mdi-mail</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mb-6 mt-2"></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                full-width
                :headers="headersFiles"
                :items="agency.agency"
                item-key="id"
                class="elevation-1"
                :items-per-page="-1"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="item.url === null"
                        fab
                        icon
                        color="EoleError"
                        :href="`https://${item.url}`"
                        target="_blank"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <i class="fa-solid fa-file-pdf icon-medium"></i>
                      </v-btn>
                    </template>
                    <span v-if="item.url !== null"> Télécharger {{ item.title }} </span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon v-on="on" v-bind="attrs" @click="importPutFile(item, 1, 'agence', 'agence')">
                        <i class="fa-solid fa-file-import icon-medium"></i
                      ></v-btn>
                    </template>
                    <span> Importer/modifier {{ item.title }} </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-card-title
            class="no-padding mt-10"
            style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
          >
            PARAMÉTRAGE</v-card-title
          >
          <v-divider class="mb-6 mt-2"></v-divider>
          <v-card-text>
            <office-settings></office-settings>
          </v-card-text>
        </div>
        <div v-else-if="user.grade === 'cabinet social'">
          <office-social></office-social>
        </div>
        <div v-else-if="user.grade === 'expert comptable'">
          <office-accountant
            :documents="agency.social_statement"
            :monthlyStatus="agency.monthly_status"
          ></office-accountant>
        </div>
      </v-tab-item>
      <v-tab-item :value="'tab-7'">
        <office-collaborators
          class="mt-n10"
          :agency="agency"
          :count-candidates="countCandidates"
          @updateCountCandidates="handleUpdateCountCandidates"
        ></office-collaborators>
      </v-tab-item>
      <v-tab-item :value="'tab-2'" :key="'tab-2'">
        <office-services :agency="agency" :tab-change-indicator="tabChangeIndicator"></office-services>
      </v-tab-item>
      <v-tab-item :value="'tab-3'">
        <v-dialog
          v-model="dialogUploadPostFile"
          max-width="1200px"
          @click:outside="
            document = {
              name: '',
              data: '',
              id_agency_etablishment: 999,
            }
            documentArea = {
              type: 0,
              area: '',
              subType: '',
            }
            fileUrl = null
          "
        >
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Importer un fichier</span>
                    </v-card-title>
                    <v-card-text class="pa-5">
                      <v-row>
                        <v-col cols="12" sm="6" md="12">
                          <v-card flat class="text-center align-center justify-center">
                            <v-row class="pa-5">
                              <v-col cols="12" md="12">
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field outlined label="Nom du document" v-model="document.name">
                                    </v-text-field>
                                    <v-select
                                      label="Catégorie du document"
                                      :items="etablishmentsOptions"
                                      item-value="id"
                                      item-text="name"
                                      outlined
                                      v-model="document.id_agency_etablishment"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <div>
                                      <span v-if="document === null && fileUrl === null"
                                        >Aucun document n'est pour le moment disponible.</span
                                      >
                                      <iframe
                                        v-else-if="
                                          document != null &&
                                          document.data != null &&
                                          document.data.type === 'application/pdf'
                                        "
                                        id="lddcVisualisator"
                                        style="width: 100%; height: 500px"
                                        :src="fileUrl"
                                      ></iframe>

                                      <v-row
                                        align="center"
                                        v-else-if="
                                          document != null &&
                                          document.data != null &&
                                          document.data.type ===
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        "
                                        style="height: 225px"
                                      >
                                        <v-col cols="12" md="12">
                                          <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <v-card-text v-if="document.url != null">
                                      <v-row align="center"> </v-row>
                                    </v-card-text>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text
                        @click="
                          () => {
                            dialogUploadPostFile = false
                            document = {
                              name: '',
                              data: '',
                              id_agency_etablishment: 999,
                            }
                            documentArea = {
                              type: 0,
                              area: '',
                              subType: '',
                            }
                          }
                        "
                      >
                        Annuler
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="uploadFile($event, documentArea.type, documentArea.area, documentArea.subType)"
                      >
                        Ajouter un nouveau document</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-row class="no-padding">
          <v-col cols="12" md="2" class="d-flex">
            <v-card outlined elevation="3" class="text-left">
              <v-navigation-drawer permanent>
                <v-list nav dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="selectTabHR('tab-4')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-4' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Affichage obligatoire</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-3')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-3' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Contrats de travail</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-2')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-2' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Protection sociale</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabHR('tab-6')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-6' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Accord d'intéressement</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-7')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-7' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Accord de participation</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabHR('tab-1')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-1' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Bilan social individuel</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-9')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-9' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Entretiens annuels</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-5')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-5' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Mes recrutements</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabHR('tab-8')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-8' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Registre du personnel</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-10')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-10' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Documents types</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabHR('tab-11')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-11' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Déclaration sociale</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabHR('tab-12')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabHR === 'tab-12' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>États mensuels</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-card>
          </v-col>
          <v-col cols="12" md="10">
            <v-tabs-items v-model="tabHR" style="background-color: transparent !important">
              <v-tab-item :value="'tab-1'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      BILAN SOCIAL INDIVIDUEL</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          CONVENTIONS COLLECTIVES</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'protection_sociale', 'conventions_collectives')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.conventions"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'protection_sociale', 'conventions_collectives')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          DÉCISION UNILATÉRALE DE L'EMPLOYEUR</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'protection_sociale', 'decision_unilaterale_employeur')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.decision"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'protection_sociale', 'decision_unilaterale_employeur')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          CONTRATS DE TRAVAIL</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'contrats_de_travail', 'contrat_de_travail')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.contracts"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 1, 'contrats_de_travail', 'contrat_de_travail')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" md="12" class="mt-2">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          CHARTES</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(1, 'contrats_de_travail', 'chartes')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.charts"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(file, 1, 'contrats_de_travail', 'chartes')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          FICHES DE POSTE</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'contrats_de_travail', 'fiche_de_poste')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.poste"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 1, 'contrats_de_travail', 'fiche_de_poste')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-4'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          AFFICHAGE OBLIGATOIRE</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'affichage_obligatoire', 'affichage_obligatoire')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <div v-for="etablishment in agency.display" v-bind:key="etablishment.id">
                      <v-card-title
                        class="no-padding my-5 EoleBlue--text"
                        style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                      >
                        ÉTABLISSEMENT DE {{ etablishment.city }}</v-card-title
                      >
                      <v-data-table
                        full-width
                        :headers="headersFiles"
                        :items="etablishment.files"
                        item-key="id"
                        elevation="3"
                        class="elevation-3"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.title`]="{ item }">
                          <span v-if="item.title != null"> {{ item.title.toUpperCase() }} ({{ item.city }}) </span>
                          <span v-else> {{ item.name }} </span>
                        </template>

                        <template v-slot:[`item.format_date_upload`]="{ item }">
                          <span v-if="item.upload_datetime != null"> {{ item.format_date_upload }} </span>
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                          <v-btn
                            icon
                            color="EoleError"
                            target="_blank"
                            @click="downloadFile(item.path, item.name, item.extension)"
                            :disabled="item.url == null"
                          >
                            <i class="fa-solid fa-file-pdf icon-medium"></i>
                          </v-btn>

                          <v-btn icon target="_blank" @click="openPreviewFile(item)" :disabled="item.url == null">
                            <i class="fa-solid fa-eye icon-medium"></i>
                          </v-btn>
                          <v-menu right transition="slide-x-transition" close-on-content-click>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <!-- <v-list-item :disabled="item.url === null">
                                <v-list-item-title>
                                  <div class="ml-1">
                                    <v-switch
                                      dense
                                      @click="putFileSetShareable(item.id, item.share)"
                                      v-model="item.share"
                                      label="Partage espace collaboratif"
                                      inset
                                    >
                                    </v-switch>
                                  </div>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item :disabled="item.url === null">
                                <v-list-item-title>
                                  <div class="ml-1">
                                    <v-switch
                                      dense
                                      @click="putFileSetShareable(item.id, item.share)"
                                      v-model="item.share_acpr"
                                      label="Partage classeur ACPR"
                                      inset
                                    >
                                    </v-switch>
                                  </div>
                                </v-list-item-title>
                              </v-list-item> -->
                              <!-- <v-list-item :href="`https://${item.url}`" target="_blank">
                                <v-list-item-title>
                                  <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                                </v-list-item-title>
                              </v-list-item> -->
                              <v-list-item
                                @click="importPutFile(item, 1, 'affichage_obligatoire', 'affichage_obligatoire')"
                              >
                                <v-list-item-title>
                                  <div><v-icon left>mdi-download</v-icon>Modifier</div>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-data-table>
                    </div>

                    <v-dialog max-width="1200" v-model="dialogPreviewFile" @click:outside="selectedPreviewFile = {}">
                      <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                        <v-card-text style="background-color: #f1f4f9">
                          <v-row>
                            <v-col cols="12" md="3" class="no-padding ml-n2">
                              <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                <v-card-title>
                                  <v-img
                                    :src="require('@/assets/images/logos/anavel_logo.png')"
                                    max-height="150px"
                                    max-width="120px"
                                    alt="logo"
                                    contain
                                  ></v-img>
                                </v-card-title>
                                <v-card-title class="text-center justify-center muller-capitalized">
                                  Mes Services Assurance</v-card-title
                                >
                                <v-card-subtitle class="text-center justify-center"
                                  >Un logiciel ANAVEL
                                </v-card-subtitle>
                                <v-card-text class="no-padding">
                                  <v-img
                                    :src="require('@/assets/images/logos/layer.png')"
                                    alt="logo"
                                    width="300px"
                                    class=""
                                  >
                                  </v-img>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" md="9" class="no-padding d-flex">
                              <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                <v-card-title>
                                  <span class="text-h5">Prévisualisation du document </span>
                                </v-card-title>
                                <v-card-text v-if="selectedPreviewFile.url != null">
                                  <iframe
                                    :src="`https://${selectedPreviewFile.url}`"
                                    frameborder="0"
                                    width="100%"
                                    height="500px"
                                  ></iframe>
                                </v-card-text>
                                <v-card-text>
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(selectedPreviewFile.id, selectedPreviewFile.share)"
                                    v-model="selectedPreviewFile.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(selectedPreviewFile.id, selectedPreviewFile.share)"
                                    v-model="selectedPreviewFile.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                    class="mt-n5"
                                  >
                                  </v-switch>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <v-card-actions>
                                  <v-btn text @click="putTraining(item)" color="EoleError"> terminer </v-btn>

                                  <v-spacer></v-spacer>
                                  <v-btn text @click="putTraining(item)"> terminer </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <!-- <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.display"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span v-if="item.title != null"> {{ item.title.toUpperCase() }} ({{ item.city }}) </span>
                        <span v-else> </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'affichage_obligatoire', 'affichage_obligatoire')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table> -->
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-5'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      ANNONCES DE RECRUTEMENT</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      DEMANDES DE RECRUTEMENT</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-6'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          ACCORD D'INTÉRESSEMENT INITIAL</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'accord_interessement', 'accord_interessement')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.money_accord"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'accord_interessement', 'accord_interessement')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          AVENANTS A L'ACCORD D'INTÉRESSEMENT
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'accord_interessement', 'avenant_accord_interessement')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.endorsement_money_accord"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'accord_interessement', 'avenant_accord_interessement')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-7'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          ACCORD DE PARTICIPATION
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          @click="importFile(1, 'accord_participation', 'accord_participation')"
                          icon
                          fab
                          color="EoleBlue"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersHRFiles"
                      :items="agency.participation_accord"
                      item-key="id"
                      class="elevation-1"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click="importPutFile(item, 1, 'accord_participation', 'accord_participation')"
                            >
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-8'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      REGISTRE DU PERSONNEL</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row class="pa-5">
                      <v-data-table
                        :headers="headersCollaborators"
                        :items="agency.activeCollaborators"
                        item-key="name"
                        class="elevation-1 header-table"
                        :items-per-page="-1"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.nationality`]="{ item }">
                          <v-img
                            v-if="item.nationality_abbreviation != null"
                            :src="`https://flagcdn.com/40x30/${item.nationality_abbreviation}.png`"
                            class="mx-auto"
                            width="20"
                            height="15"
                          ></v-img>
                        </template>

                        <template v-slot:[`item.name`]="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>
                        <template v-slot:[`item.addresse`]="{ item }">
                          {{ item.street }}, <br />
                          {{ item.zip }} {{ item.city }}
                        </template>
                      </v-data-table>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-9'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      ENTRETIENS ANNUELS</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>

                    <v-row> Entretiens </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-10'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      DOCUMENTS TYPES</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>

                    -
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-11'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      DÉCLARATIONS SOCIALES</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <office-social-statement :documents="agency.social_statement"> </office-social-statement>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-12'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      ÉTATS MENSUELS</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <office-monthly-status :documents="agency.monthly_status"> </office-monthly-status>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>

        <v-card-text class="pa-5"></v-card-text>
      </v-tab-item>
      <v-tab-item :value="'tab-8'">
        <office-calendar
          :agencyInformations="agencyInformations"
          :collaborators="collaboratorsHoliday"
          :collaboratorsValidation="collaboratorsValidation"
          :collaboratorsCompensatoryLeave="collaboratorsCompensatoryLeave"
          :collaboratorsRecoveryLeave="collaboratorsRecoveryLeave"
          :demand="demand"
          :demandHistory="demandHistory"
          @update="update"
        ></office-calendar>
      </v-tab-item>
      <v-tab-item :value="'tab-10'">
        <office-calendar-working-days
          :collaboratorsHistory="collaboratorsHistory"
          :agencyInformations="agencyInformations"
          :collaborators="collaboratorsHoliday"
          :collaboratorsValidation="collaboratorsValidation"
          :collaboratorsCompensatoryLeave="collaboratorsCompensatoryLeave"
          :collaboratorsRecoveryLeave="collaboratorsRecoveryLeave"
          :demand="demand"
          :test="test"
          :demandHistory="demandHistory"
          @update="update"
        ></office-calendar-working-days>
      </v-tab-item>
      <v-tab-item :value="'tab-9'">
        <office-costs class="mt-n10"></office-costs>
      </v-tab-item>
      <v-tab-item :value="'tab-5'">
        <v-row>
          <v-col cols="12" md="10" class="justify-center text-center">
            <v-btn fab icon @click="previousYear">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span>
              <strong class="EoleYellow--text"> {{ year }} </strong></span
            >

            <v-btn :disabled="year >= currentYear" fab icon @click="nextYear">
              <v-icon>mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" md="2" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="EoleBlue" @click="showInactive = !showInactive">
                  <i v-if="!showInactive" class="fa fa-users-slash"></i>
                  <i v-else class="fa fa-users"></i>
                </v-btn>
              </template>
              <span v-if="!showInactive"> Afficher les collaborateurs inactifs </span>
              <span v-else> Afficher les collaborateurs actifs </span>
            </v-tooltip>

            <!-- <v-btn icon color="EoleBlue"> <i class="fa fa-users" @click="verifyDocuments"></i> </v-btn> -->

            <v-dialog
              @click:outside="
                dialogAddTraining = false
                currentIdAccount = null
                newTraining = {
                  collaborators: [],
                  date: '',
                  isDDA: false,
                  designation: '',
                  duration: '',
                  organism: '',
                }
              "
              v-model="dialogAddTraining"
              width="1200"
              transition="dialog-bottom-transition"
              class="hide-scrollbar"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="EoleBlue">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>

              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="3" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                        <v-card-title>
                          <v-img
                            :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px"
                            max-width="120px"
                            alt="logo"
                            contain
                          ></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized"
                          >Mes Services Assurance</v-card-title
                        >
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="9" class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title>
                          <span class="text-h5">Ajouter une nouvelle séance de formation</span>
                        </v-card-title>
                        <v-card-text class="pa-5">
                          <v-form ref="form" lazy-validation>
                            <v-row v-if="currentIdAccount === null">
                              <v-col cols="12" sm="6" md="12">
                                <v-select
                                  dense
                                  multiple
                                  outlined
                                  :label="`Qui est concerné`"
                                  :items="agency.activeCollaborators"
                                  item-value="id"
                                  v-model="newTraining.collaborators"
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-chip>
                                      <v-avatar left color="EoleBlue" size="32">
                                        <span class="white--text">
                                          {{ item.lastname[0] + item.firstname[0] }}
                                        </span>
                                      </v-avatar>
                                      <span>{{ item.lastname }} {{ item.firstname }}</span>
                                    </v-chip>
                                  </template>
                                  <template #item="{ item }">
                                    <v-list-item-avatar color="EoleBlue" class="white--text text-center" size="32">
                                      <v-avatar class="bordered" color="EoleBlue" size="32">
                                        <span class="white--text">
                                          {{ item.lastname[0] + item.firstname[0] }}
                                        </span>
                                      </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content height="150">
                                      <v-list-item-title>{{ item.lastname }} {{ item.firstname }} </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row v-if="currentIdAccount === null">
                              <v-col cols="12" sm="6" md="12">
                                <v-select
                                  dense
                                  multiple
                                  outlined
                                  :label="`Service concerné`"
                                  :items="services"
                                  item-value="id"
                                  key="id"
                                  @input="handleServiceSelection(selectedServices)"
                                  v-model="selectedServices"
                                  clearable
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <v-chip>
                                      <v-avatar left color="EoleBlue" size="32">
                                        <span class="white--text">
                                          {{ item.name.substring(0, 2) }}
                                        </span>
                                      </v-avatar>
                                      <span>{{ item.name }}</span>
                                    </v-chip>
                                  </template>
                                  <template #item="{ item }">
                                    <v-list-item-avatar color="EoleBlue" class="white--text text-center" size="32">
                                      <v-avatar class="bordered" color="EoleBlue" size="32">
                                        <span class="white--text">
                                          {{ item.name.substring(0, 2) }}
                                        </span>
                                      </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content height="150">
                                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  type="date"
                                  outlined
                                  dense
                                  v-model="newTraining.date"
                                  label="Date de la formation"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  type="time"
                                  outlined
                                  dense
                                  v-model="newTraining.duration"
                                  label="Durée de formation"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" md="12" class="mt-n10">
                                <v-switch inset label="Demi-journée" v-model="newTraining.isHalfDay"></v-switch>
                              </v-col>
                              <v-col cols="12" md="12" v-if="newTraining.isHalfDay" class="mt-n10">
                                <v-radio-group v-model="newTraining.halfDayType">
                                  <v-radio
                                    v-for="n in [
                                      { id: 1, label: 'Matin' },
                                      { id: 2, label: 'Après-midi' },
                                    ]"
                                    :key="n.id"
                                    :label="`${n.label}`"
                                    :value="n.id"
                                  ></v-radio>
                                </v-radio-group>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="newTraining.designation"
                                  label="Libellé de la formation"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="newTraining.organism"
                                  label="Organisme de formation"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row class="mt-n5">
                              <v-col cols="12" sm="6" md="6">
                                <v-switch inset label="Éligible DDA ?" v-model="newTraining.isDDA"> </v-switch>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="postNewTraining">Terminer</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-btn icon color="EoleBlue" @click="testMergingTrainingDocuments">
              <i class="fa-solid fa-file-pdf EoleBlue--text"></i>
            </v-btn>
          </v-col>
        </v-row>
        <v-dialog v-model="dialogTrainingDocuments" max-width="600">
          <v-card>
            <v-card-title class="text-h5">Visualisation du document</v-card-title>
            <v-card-text>
              <iframe
                v-if="selectedTraining.id_document === 1"
                :src="previewedUrl"
                frameborder="0"
                width="100%"
                height="600px"
              ></iframe>
              <iframe
                v-if="selectedTraining.id_document === 2"
                :src="previewedUrl"
                frameborder="0"
                width="100%"
                height="600px"
              ></iframe>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="selectedTraining.id_document === 1"
                elevation="0"
                @click="uploadNewTraining(selectedTraining, 1)"
                >Modifier mon document</v-btn
              >
              <v-btn
                v-if="selectedTraining.id_document === 2"
                elevation="0"
                @click="uploadNewTraining(selectedTraining, 2)"
                >Modifier mon document</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- {{ getTrainingsByYear(agency.activeCollaborators) }} -->
        <v-data-table
          :headers="headersTrainings"
          :items="!showInactive ? getTrainingsByYear(agency.activeCollaborators) : agency.inactiveCollaborators"
          item-key="id"
          class="elevation-1 header-table"
          :items-per-page="-1"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.name`]="{ item }"> {{ item.lastname }} {{ item.firstname }} </template>

          <template v-slot:[`item.sum_DDA_hours`]="{ item }">
            <span v-if="item.sum_DDA_hours != '00h00'"> {{ item.sum_DDA_hours }} </span>
            <span v-else> 00h00</span>
          </template>
          <template v-slot:[`item.sum_DDA_hours_done`]="{ item }">
            <span v-if="item.sum_DDA_hours_done != '00h00'"> {{ item.sum_DDA_hours_done }} </span>
            <span v-else> 00h00 </span>
          </template>
          <template v-slot:[`item.sum_hours`]="{ item }">
            <span v-if="item.sum_hours != '00h00'"> {{ item.sum_hours }} </span>
            <span v-else> 00h00 </span>
          </template>
          <template v-slot:[`item.data_complete`]="{ item }">
            <v-progress-circular
              rotate="270"
              :value="item.percentage"
              size="40"
              :color="getProgressColor(item.percentage)"
              rounded
            >
              {{ item.percentage }}%
            </v-progress-circular>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <v-dialog max-width="1200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  icon
                  @click="
                    () => {
                      editedUploadTraining = {
                        lastname: item.lastname,
                        firstname: item.firstname,
                        idAccount: item.id,
                      }
                    }
                  "
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                <v-card-text style="background-color: #f1f4f9">
                  <v-row>
                    <v-col cols="12" md="3" class="no-padding ml-n2">
                      <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                        <v-card-title>
                          <v-img
                            :src="require('@/assets/images/logos/anavel_logo.png')"
                            max-height="150px"
                            max-width="120px"
                            alt="logo"
                            contain
                          ></v-img>
                        </v-card-title>
                        <v-card-title class="text-center justify-center muller-capitalized"
                          >Mes Services Assurance</v-card-title
                        >
                        <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL </v-card-subtitle>
                        <v-card-text class="no-padding">
                          <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                          </v-img>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="9" class="no-padding d-flex">
                      <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                        <v-card-title>
                          <span class="text-h5">Formations pour {{ item.lastname }} {{ item.firstname }}</span>
                        </v-card-title>
                        <v-card-text>
                          <v-row class="text-center justify-center">
                            <v-col cols="12" md="11" class="text-center">
                              <v-btn fab icon @click="previousYear">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                              <span>
                                <strong class="EoleYellow--text"> {{ year }} </strong></span
                              >

                              <v-btn :disabled="year >= currentYear" fab icon @click="nextYear">
                                <v-icon>mdi-chevron-right </v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="1" class="text-center">
                              <v-btn
                                fab
                                icon
                                color="EoleBlue"
                                @click="
                                  () => {
                                    dialogAddTraining = !dialogAddTraining
                                    currentIdAccount = item.id
                                    newTraining.collaborators = [item.id]
                                  }
                                "
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text>
                          <v-dialog v-model="dialogUploadDocument" max-width="800px">
                            <v-card flat>
                              <v-card-title>
                                <span class="text-h5">Import d'un fichier</span>
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text class="pa-5">
                                <v-row>
                                  <v-col cols="12" sm="6" md="12">
                                    <v-card flat class="text-center align-center justify-center">
                                      <v-row class="pa-5">
                                        <v-col cols="12" md="12">
                                          <div>
                                            <span v-if="documentTraining === null && fileUrlTraining === null"
                                              >Aucun document n'est pour le moment fileUrlTraining.</span
                                            >
                                            <v-img
                                              v-else-if="
                                                (documentTraining != null &&
                                                  documentTraining.data != null &&
                                                  documentTraining.data.type === 'image/jpeg') ||
                                                documentTraining.data.type === 'image/png'
                                              "
                                              contain
                                              class="mx-auto"
                                              :src="fileUrlTraining"
                                            >
                                            </v-img>
                                            <iframe
                                              v-else-if="
                                                documentTraining != null &&
                                                documentTraining.data != null &&
                                                documentTraining.data.type === 'application/pdf'
                                              "
                                              id="lddcVisualisator"
                                              style="width: 100%; height: 500px"
                                              :src="fileUrlTraining"
                                            ></iframe>

                                            <v-row
                                              align="center"
                                              v-else-if="
                                                documentTraining != null &&
                                                documentTraining.data != null &&
                                                documentTraining.data.type ===
                                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                              "
                                              style="height: 225px"
                                            >
                                              <v-col cols="12" md="12">
                                                <i class="fa-regular fa-file-word EoleBlue--text fa-8x"></i>
                                              </v-col>
                                            </v-row>
                                          </div>

                                          <v-card-text v-if="documentTraining.url != null">
                                            <v-row align="center"> </v-row>
                                          </v-card-text>
                                        </v-col>
                                      </v-row>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-card-text>

                              <v-card-actions>
                                <v-btn
                                  color="EoleYellow"
                                  text
                                  @click="
                                    () => {
                                      dialogUploadDocument = !dialogUploadDocument
                                      documentTraining = {
                                        name: '',
                                        data: {
                                          name: null,
                                          type: null,
                                        },
                                      }
                                    }
                                  "
                                >
                                  Annuler
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="EoleGreen"
                                  class="white--text"
                                  @click="
                                    postTrainingDocument(
                                      selectedTraining.id,
                                      editedUploadTraining.lastname,
                                      editedUploadTraining.firstname,
                                    )
                                  "
                                  >Ajouter un nouveau document
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <v-data-table
                            :loading-text="`Chargement des pièces en cours...\n Merci de patienter`"
                            :page.sync="page"
                            :items-per-page="10"
                            :headers="headersTrainingsItems"
                            :items="getTrainingByYear(item.allTrainings)"
                            hide-default-footer
                          >
                            <template v-slot:no-data> Aucune formation suivis en {{ year }}</template>

                            <template v-slot:[`item.sum_year`]="{ item }">
                              <span v-if="item.sum_year != '00h00'"> {{ item.sum_year }} </span>
                              <span v-else> Aucune heure DDA réalisée </span>
                            </template>
                            <template v-slot:[`item.time`]="{ item }"> {{ item.hour }}:{{ item.minute }} </template>
                            <template v-slot:[`item.dda`]="{ item }">
                              <v-icon v-if="parseInt(item.isDDA)" color="EoleGreen">mdi-check </v-icon>
                              <v-icon v-else color="EoleError">mdi-close-thick</v-icon>
                            </template>

                            <template v-slot:[`item.documents`]="{ item }">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item.training_module_url != null && item.training_module_url != ''"
                                    v-on="on"
                                    v-bind="attrs"
                                    @click="openTrainingDocuments(item, 1)"
                                    icon
                                    color="EoleError"
                                    target="_blank"
                                  >
                                    <i class="fa-solid fa-file-pdf icon-medium"></i>
                                  </v-btn>
                                  <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item, 1)">
                                    <i class="fa-solid fa-file-import icon-medium"></i>
                                  </v-btn>
                                </template>
                                <span v-if="item.training_module_url != null && item.training_module_url != ''"
                                  >Télécharger le module de formation</span
                                >
                                <span v-else>Importer le module de formation</span>
                              </v-tooltip>

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-if="item.training_attestation_url != null && item.training_attestation_url != ''"
                                    v-on="on"
                                    v-bind="attrs"
                                    @click="openTrainingDocuments(item, 2)"
                                    icon
                                    color="EoleError"
                                    target="_blank"
                                  >
                                    <i class="fa-solid fa-file-pdf icon-medium"></i>
                                  </v-btn>
                                  <v-btn v-else v-on="on" v-bind="attrs" icon @click="uploadNewTraining(item, 2)">
                                    <i class="fa-solid fa-file-import icon-medium"></i>
                                  </v-btn>
                                </template>
                                <span
                                  v-if="item.training_attestation_url != null && item.training_attestation_url != ''"
                                  >Télécharger l'attestation de formation</span
                                >
                                <span v-else>Importer l'attestation de formation</span>
                              </v-tooltip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-menu right transition="slide-x-transition" close-on-content-click>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                  </v-btn>
                                </template>

                                <v-list>
                                  <v-dialog max-width="1200" v-model="dialogEditTraining">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-title>
                                          <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                                        </v-list-item-title>
                                      </v-list-item>
                                    </template>

                                    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                      <v-card-text style="background-color: #f1f4f9">
                                        <v-row>
                                          <v-col cols="12" md="3" class="no-padding ml-n2">
                                            <v-card
                                              class="rounded-l-xl"
                                              color="#f1f4f9"
                                              style="background-color: #f1f4f9"
                                              flat
                                            >
                                              <v-card-title>
                                                <v-img
                                                  :src="require('@/assets/images/logos/anavel_logo.png')"
                                                  max-height="150px"
                                                  max-width="120px"
                                                  alt="logo"
                                                  contain
                                                ></v-img>
                                              </v-card-title>
                                              <v-card-title class="text-center justify-center muller-capitalized">
                                                Mes Services Assurance</v-card-title
                                              >
                                              <v-card-subtitle class="text-center justify-center"
                                                >Un logiciel ANAVEL
                                              </v-card-subtitle>
                                              <v-card-text class="no-padding">
                                                <v-img
                                                  :src="require('@/assets/images/logos/layer.png')"
                                                  alt="logo"
                                                  width="300px"
                                                  class=""
                                                >
                                                </v-img>
                                              </v-card-text>
                                            </v-card>
                                          </v-col>
                                          <v-col cols="12" md="9" class="no-padding d-flex">
                                            <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                              <v-card-title>
                                                <span class="text-h5">Modification de la formation</span>
                                              </v-card-title>
                                              <v-card-text class="pa-5">
                                                <v-form ref="form" lazy-validation>
                                                  <v-row>
                                                    <v-col cols="12" sm="6" md="6">
                                                      <v-text-field
                                                        type="date"
                                                        outlined
                                                        dense
                                                        v-model="item.date"
                                                        label="Date de la formation"
                                                      ></v-text-field>
                                                    </v-col>

                                                    <v-col cols="12" sm="6" md="6">
                                                      <v-text-field
                                                        type="time"
                                                        outlined
                                                        dense
                                                        v-model="item.duration"
                                                        label="Durée de formation"
                                                      ></v-text-field>
                                                    </v-col>
                                                  </v-row>

                                                  <v-row>
                                                    <v-col cols="12" sm="6" md="6">
                                                      <v-text-field
                                                        outlined
                                                        dense
                                                        v-model="item.designation"
                                                        label="Libellé de la formation"
                                                      >
                                                      </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6">
                                                      <v-text-field
                                                        outlined
                                                        dense
                                                        v-model="item.organism"
                                                        label="Organisme de formation"
                                                      >
                                                      </v-text-field>
                                                    </v-col>
                                                  </v-row>
                                                  <v-row class="mt-n5">
                                                    <v-col cols="12" sm="6" md="6">
                                                      <v-switch inset label="Éligible DDA ?" v-model="item.isDDA">
                                                      </v-switch>
                                                    </v-col>
                                                  </v-row>
                                                </v-form>
                                              </v-card-text>
                                              <v-spacer></v-spacer>
                                              <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn text @click="putTraining(item, editedUploadTraining.idAccount)">
                                                  terminer
                                                </v-btn>
                                              </v-card-actions>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                      </v-card-text>
                                    </v-card>
                                  </v-dialog>

                                  <v-list-item
                                    color="EoleError"
                                    link
                                    @click="deleteTraining(item.id, editedUploadTraining.idAccount)"
                                    target="blank"
                                  >
                                    <v-list-item-title>
                                      <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </template>

                            <template v-slot:[`item.hour_programmated`]="{ item }">
                              <span> 30 heures </span>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                              <v-row>
                                <v-col cols="12" md="10">
                                  <v-textarea dense v-model="item.description" outlined rows="1" class="mt-5">
                                  </v-textarea>
                                </v-col>
                                <v-col cols="12" md="2" class="d-flex align-center mb-2">
                                  <v-btn
                                    icon
                                    fab
                                    @click="postTrainingComment(item.id, item.idAccount, item.description)"
                                  >
                                    <i class="fa-solid fa-floppy-disk EoleGreen-text" color="EoleGreen"></i
                                  ></v-btn>
                                </v-col>
                              </v-row>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:no-data>
            <v-alert color="error" text class="ma-2">
              <div class="d-flex align-start">
                <v-icon color="error"> mdi-alert-circle-outline </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Impossible d'accéder aux données. Merci de réessayer ultérieurement.
                  </p>
                </div>
              </div>
            </v-alert>
            <v-btn color="primary" class="mb-2" @click="fetchInsurers()">Réessayer</v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :value="'tab-6'">
        <office-settings-collaborators class="mt-n10"></office-settings-collaborators>
      </v-tab-item>
      <v-tab-item :value="'tab-4'">
        <v-row class="no-padding">
          <v-col cols="12" md="2" class="">
            <v-card outlined elevation="3" class="text-left">
              <v-navigation-drawer permanent>
                <v-list nav dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="selectTabProcess('tab-3')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-3' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>RÉCLAMATIONS</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabProcess('tab-2')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-2' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>LCBFT </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="selectTabProcess('tab-4')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-4' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>PPE</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabProcess('tab-5')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-5' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> DDA</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabProcess('tab-1')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-1' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> RGPD</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabProcess('tab-6')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-6' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> GDA</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectTabProcess('tab-7')">
                      <v-list-item-icon class="align-center">
                        <i :class="['fa-solid', tabProcess === 'tab-7' ? 'fa-folder-open' : 'fa-folder', 'fa-1x']"></i>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title> NOTES DE SERVICE</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-card>
          </v-col>
          <v-col cols="12" md="10">
            <v-tabs-items v-model="tabProcess" style="background-color: transparent !important">
              <v-tab-item :value="'tab-1'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          RÉGLEMENT GÉNÉRALE SUR LA PROTECTION DES DONNÉES
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'rgpd', 'rgpd')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.rgpd"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'rgpd', 'rgpd')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          LUTTE CONTRE LE BLANCHIMENT DES CAPITAUX ET LE FINANCEMENT DU TERRORISME
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'lcbft', 'lcbft')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.lcbft"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'lcbft', 'lcbft')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-3'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          RÉCLAMATIONS</v-card-title
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'reclamations', 'reclamations')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.reclamations"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'reclamations', 'reclamations')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-4'">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          PERSONNE POLITIQUEMENT EXPOSÉE
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'ppe', 'ppe')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.ppe"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'ppe', 'ppe')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item :value="'tab-5'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          DIRECTIVES DISTRIBUTION EN ASSURANCES
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'dda', 'dda')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.dda"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'dda', 'dda')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-6'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left">
                        <v-card-title
                          class="no-padding mb-2 EoleBlue--text"
                          style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                        >
                          GELS DES AVOIRS
                        </v-card-title>
                      </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn @click="importFile(2, 'gda', 'gda')" icon fab color="EoleBlue">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-9 mt-2"></v-divider>
                    <v-data-table
                      full-width
                      :headers="headersFiles"
                      :items="agency.gda"
                      item-key="id"
                      elevation="3"
                      class="elevation-3"
                      :items-per-page="-1"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.title`]="{ item }">
                        <span> {{ item.name }} </span>
                      </template>

                      <template v-slot:[`item.actions`]="{ item }">
                        <v-menu right transition="slide-x-transition" close-on-content-click>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share"
                                    label="Partage espace collaboratif"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :disabled="item.url === null">
                              <v-list-item-title>
                                <div class="ml-1">
                                  <v-switch
                                    dense
                                    @click="putFileSetShareable(item.id, item.share)"
                                    v-model="item.share_acpr"
                                    label="Partage classeur ACPR"
                                    inset
                                  >
                                  </v-switch>
                                </div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item :href="`https://${item.url}`" target="_blank">
                              <v-list-item-title>
                                <div><v-icon left>mdi-download</v-icon>Télécharger</div>
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="importPutFile(item, 2, 'gda', 'gda')">
                              <v-list-item-title>
                                <div><v-icon left>mdi-pencil</v-icon>Modifier</div>
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item color="EoleError" link @click="deleteFile(item.id, item.path)" target="blank">
                              <v-list-item-title>
                                <v-icon color="EoleError" left>mdi-delete</v-icon>Supprimer
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item :value="'tab-7'">
                <v-row class="mb-10">
                  <v-col cols="12" md="12">
                    <v-card-title
                      class="no-padding mb-2"
                      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
                    >
                      NOTES DE SERVICE</v-card-title
                    >
                    <v-divider class="mb-9 mt-2"></v-divider>
                  </v-col>
                </v-row>
                <v-row> </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
  <div v-else>
    <custome-loader></custome-loader>
    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    <v-row class="mt-5">
      <v-col cols="12" md="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="8">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="12">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="3">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OfficeCollaborators from "./components/OfficeCollaborators.vue"
import OfficeServices from "./components/OfficeServices.vue"
import OfficeCalendar from "./components/OfficeCalendar.vue"
import OfficeCosts from "./components/OfficeCosts.vue"
import OfficeCalendarWorkingDays from "./components/OfficeCalendarWorkingDays.vue"
import OfficeSettings from "./components/OfficeSettings.vue"
import OfficeSocial from "./components/OfficeSocial.vue"
import OfficeAccountant from "./components/OfficeAccountant.vue"
import OfficeSocialStatement from "./components/OfficeSocialStatement.vue"
import OfficeMonthlyStatus from "./components/OfficeMonthlyStatus.vue"

import VueTree from "@ssthouse/vue-tree-chart"
Vue.component("vue-tree", VueTree)

import PdfMerger from "pdf-merger-js"
var merger = new PdfMerger()

import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import io from "socket.io-client"
import { getCookie } from "@/utils/cookies"

import moment from "moment"
moment.locale("fr")
Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

import { LMap, LTileLayer, LMarker, LTooltip, LFeatureGroup } from "vue2-leaflet"
import "leaflet/dist/leaflet.css"
const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

import { Icon } from "leaflet"

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

export default {
  name: "Office",
  data: () => ({
    dialogPreviewFile: false,
    selectedPreviewFile: {},
    previewedUrl: null,
    countCandidates: 0,
    user: {},
    socialStatements: [],
    collaboratorsHistory: [],
    test: [],
    testMonthLeave: [],
    supervisedMembersIds: [],
    newCostCount: 0,
    newDemandCount: 0,
    dataFetch: false,
    dialogTrainingDocuments: false,
    selectedTraining: {},
    currentIdAccount: null,
    currentYear: moment().format("YYYY"),
    editedUploadTraining: {
      lastname: "",
      firstname: "",
    },
    year: moment().format("YYYY"),
    dialogEditTraining: false,
    demandHistory: [],
    agencyInformations: {},
    headersHRFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Date de téléversement",
        value: "format_date_upload",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    headersFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Date de téléversement",
        value: "format_date_upload",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    collaboratorsCompensatoryLeave: [],
    collaboratorsRecoveryLeave: [],
    collaboratorsValidation: [],
    collaboratorsHoliday: [],
    demand: [],
    selectedServices: null,
    newDescription: "",
    showInactive: false,
    fileUrlTraining: null,
    documentTraining: {
      name: null,
      data: {
        name: null,
        type: null,
      },
      categorie: null,
    },
    dialogUploadDocument: false,
    testData: [
      {
        name: "FRICHOT ROMAN",
        date: "12/12/2021",
        duration: "1 jour",
        type: "Congés payés",
        days: "1 jour",
        period: "Du 01/01/2023 au 31/01/2023",
        state: "En attente",
        end_date: "12/12/2021",
        comment: "Je souhaite prendre un jour de congés",
        sold: 5,
      },
    ],
    headersHolidays: [
      {
        text: "Collaborateur",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Date de la demande",
        value: "date",
      },
      {
        text: "Période souhaitée",
        value: "period",
      },
      {
        text: "Type de congés",
        value: "type",
      },
      {
        text: "Solde à date",
        value: "sold",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],
    newTraining: {
      collaborators: [],
      date: "",
      isDDA: false,
      designation: "",
      duration: "",
      organism: "",
    },
    dialogAddTraining: false,
    headersTrainingsItemsFiles: [
      {
        text: "Organisme",
        value: "organism",
        sortable: false,
      },
      {
        text: "Titre de la formation",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Date",
        value: "display_date",
        sortable: false,
      },
      {
        text: "Durée",
        value: "display_duration",
        sortable: false,
      },
      {
        text: "Éligible DDA",
        value: "dda",
        sortable: false,
      },
      {
        text: "Documents",
        value: "documents",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    expandedTrainingItems: [],
    headersTrainingsItems: [
      // {
      //   text: "Année de formation",
      //   value: "year",
      //   sortable: true,
      // },
      {
        text: "Organisme",
        value: "organism",
        sortable: false,
      },
      {
        text: "Titre de la formation",
        align: "start",
        sortable: true,
        value: "designation",
      },
      {
        text: "Date",
        value: "display_date",
        sortable: false,
      },
      {
        text: "Durée",
        value: "display_duration",
        sortable: false,
      },
      // {
      //   text: "Éligible DDA",
      //   value: "dda",
      //   sortable: false,
      // },
      {
        text: "Documents",
        value: "documents",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    expandedTraining: [],
    headersTrainings: [
      {
        text: "NOM COLLABORATEUR",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "HEURES DDA PROGRAMMÉES",
        value: "sum_DDA_hours",
      },
      {
        text: "HEURES DDA RÉALISÉES",
        value: "sum_DDA_hours_done",
      },
      {
        text: "FORMATION TOTALE",
        value: "sum_hours",
      },
      {
        text: "COMPLÉTUDE D'INFORMATIONS",
        value: "data_complete",
        align: "center",
        sortable: false,
      },
      {
        text: "VOIR",
        align: "center",
        value: "state",
        sortable: false,
      },
    ],
    initialCoordinates: [48.413008, -1.742288], // Coordonnées initiales du centre de la carte
    tileLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", // URL du serveur de tuiles OSM

    tabProcess: "tab-1",
    etablishmentsOptions: [
      {
        id: 999,
        name: "Tous les établissements",
      },
    ],
    missingFields: [],
    documentArea: {
      type: 0,
      area: "",
      subType: "",
    },
    obligatoryFilesCount: {
      value: 0,
      max: 0,
      percentage: 0,
    },
    obligatoryInformations: {
      value: 0,
      max: 0,
      percentage: 0,
      fields: [],
    },
    editFile: {},
    dialogUpload: {
      dialog: false,
      type: 0,
      area: "",
      subType: "",
    },
    dialogUploadPostFile: false,
    editServiceManage: {},
    dialogEditService: false,
    expanded: [],
    chartDataService: {},
    nodeChartService: [],
    config: config,
    dialogPutService: false,
    collaboratorsInService: [],
    stepperAddService: 1,
    editService: {
      idAgencyEtablishment: null,
      idManager: null,
      // idService: null,
      name: "",
    },
    dialogAddService: false,
    document: {
      title: "",
      name: "",
      data: {
        name: "",
        type: "",
      },
      id_agency_etablishment: 999,
    },
    loadImage: false,
    selectedItem: 0,
    items: [
      {
        href: "tab-1",
        icon: "mdi-file",
        text: "Mes docs",
      },
      {
        href: "tab-2",
        icon: "mdi-file",
        text: "Mes recrut",
      },
    ],
    agency: null,
    pdf: null,
    fetchServicesState: false,
    valueOrganization: 0,
    tabHR: "tab-1",
    dialog: false,
    treeConfig: {
      nodeWidth: 150,
      nodeHeight: 50,
      levelHeight: 250,
    },

    chartData: {},
    fetchMembers: false,
    tab: null,

    collectiveAgreements: [],
    hover: false,
    services: [],
    servicesProposed: [],
    etablishments: [],
    fileUrl: null,
    pappersInformations: [],
    directorIdentities: [],
    directorName: [],
    agencyName: null,
    load: false,
    ipError: false,
    ip: "",
    ipLocation: "",
    /* test purpose*/
    active: [],
    avatar: null,
    open: [],
    users: [],
    nodeChart: [],
    dialogMembersService: false,
    waitTimeout: true,
    page: 1,
    pageCount: 0,
    loading: false,
    fetch: false,
    headersCollaborators: [
      {
        text: "Collaborateur",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Adresse personnelle",
        value: "addresse",
        sortable: false,
      },
      {
        text: "Type de contrat",
        value: "contract_designation",
        sortable: false,
      },
      {
        text: "Poste",
        value: "job_designation",
        sortable: false,
      },
      {
        text: "Nationalité",
        value: "nationality",
        align: "center",
        sortable: false,
      },
      {
        text: "Date de naissance",
        value: "birthdate_display",
        sortable: false,
      },

      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],
    headers: [
      {
        text: "NOM DU SERVICE",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "RESPONSABLE HIÉRARCHIQUE",
        value: "complete_name_manager",
      },
      {
        text: "NOMBRE DE MEMBRES",
        value: "number_members",
      },
      {
        text: "SITE",
        value: "location",
      },

      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
      },
    ],
    agencyPictureProfil: null,
    tabChangeIndicator: 0,
  }),
  mounted() {},
  async created() {
    this.user = this.$store.state.user
    if (this.$route.query.menu) {
      this.tab = this.$route.query.menu
    }
    this.fetchAgency().finally(() => {
      this.getPictureProfil()

      this.selectTabHR("tab-4")

      switch (this.user.grade) {
        case "manager":
          this.fetchAllServices()

          this.fetchServices()
          this.fetchManage()
          this.fetchAgencyHoliday()
          break
        case "administrateur":
        case "superadministrateur":
          this.fetchAllServices()
          this.fetchServices()
          this.fetchManage()
          this.fetchAgencyHoliday()
          break
      }
    })

    this.agencyName = localStorage.getItem("agency")
  },
  components: {
    appbar,
    Loading,
    CustomeLoader,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LFeatureGroup,
    OfficeCollaborators,
    OfficeCalendar,
    OfficeCosts,
    OfficeCalendarWorkingDays,
    OfficeServices,
    OfficeSettings,
    OfficeSocial,
    OfficeAccountant,
    OfficeSocialStatement,
    OfficeMonthlyStatus,
  },
  computed: {
    getActiveAgencies() {
      return this.agency.establishments.filter(agency => agency.in_activity == 1)
    },
    selectedMembers() {
      const selectedMemberIds = []

      for (const collaboratorId of this.newTraining.collaborators) {
        for (const service of this.services) {
          for (const member of service.members) {
            if (member.id === collaboratorId) {
              selectedMemberIds.push(member.id)
            }
          }
        }
      }
      return selectedMemberIds
    },
  },
  methods: {
    openPreviewFile(item) {
      this.selectedPreviewFile = item
      this.dialogPreviewFile = true
    },
    async openPreview(item) {
      this.selectedPreviewItem = item // Stocke l'item sélectionné
      this.previewedUrl = await this.previewFile(item.url) // Récupère l'URL du fichier à prévisualiser

      this.dialogPreview = true // Ouvre la boîte de dialogue
    },
    async previewFile(path) {
      const idAccount = this.$store.state.user.id
      let url
      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/documents/preview`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            url: path,
          }),
        })
        const data = await response.blob()
        url = URL.createObjectURL(data)
      } catch (error) {
        console.error(error)
      }

      return url
    },
    handleUpdateCountCandidates() {},
    async sendInvitationMail() {
      async function postGuestAccount(email) {
        try {
          const formData = new FormData()
          formData.append("place", "addGuestAccount")
          formData.append("idMasterAccount", localStorage.getItem("master_account_linked"))
          formData.append("idAgency", this.$store.state.user.agency_id)
          formData.append("email", email)

          const response = await fetch(`${config.apiUri}/php/pages/accounts.php`, {
            mode: "cors",
            method: "POST",
            body: formData,
          })

          let data = await response.json()

          if (data.code === 3) {
            return { idAccount: data.id_account, token: data.token }
          } else {
            throw new Error("Une erreur est survenue")
          }
        } catch (e) {
          console.log(e)
        }
      }

      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(email) {
        try {
          let { idAccount, token } = await postGuestAccount(email)

          await emailjs.send("service_5p49t7p", "template_hkv4wtz", {
            to_email: email,
            link: `${config.appUri}/auth/login?firstconnection=1&id=${idAccount}&token=${token}`,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.emailList) {
        // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
        this.emailLoading = true
        await new Promise(resolve => setTimeout(resolve, 1000))

        await sendEmailWithDelay(collaborator).then(() => {
          this.emailLoading = false
        })
      }
    },
    verifyRights(tab) {
      // Admin a accès à tout
      if (this.user.grade === "administrateur") return true
      if (this.user.grade === "superadministrateur") return true

      // if (g === "cabinet social" && tab === "office") return true

      // Manager a uniquement accès à Entretiens et Congés
      if (
        this.user.grade === "manager" &&
        (tab === "office-hollidays" || tab === "office-maintenances" || tab === "office-cost")
      )
        return true

      // Pour les autres cas, pas d'accès
      return false
    },
    recordMenu(tab) {
      //add url params to the url
      this.$router.push({
        query: {
          menu: tab,
        },
      })
    },
    async fetchManage() {
      this.newDemandCount = 0
      this.newCostCount = 0
      let isManager = localStorage.getItem("grade") === "manager"
      let idAccount = this.$store.state.user.id
      this.supervisedMembersIds = [] // Assurez-vous d'avoir cette ligne pour réinitialiser à chaque appel

      try {
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/manage`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        const data = await response.json()

        this.newDemandCount = data.account.count_total_holidays
        this.newCostCount = data.account.count_total_costs

        if (isManager) {
          this.supervisedAccount = data.account.service.map(service => {
            service.collaborators.forEach(collaborator => {
              this.supervisedMembersIds.push(collaborator.id) // Collecter les IDs
            })
            return {
              id: service.id,
              name: service.name,
              members: service.collaborators,
            }
          })
        }
      } catch (error) {
        console.error("Error:", error)
      }
    },
    getFormattedName(lastname, firstname) {
      lastname = lastname.toLowerCase().replace(/ /g, "")
      firstname = firstname.toLowerCase().replace(/ /g, "-")
      return `${lastname}-${firstname}`
    },
    tabChanged() {
      // Logique existante pour le changement d'onglet
      this.tabChangeIndicator++ // Incrément à chaque changement d'onglet
    },
    async testMergingTrainingDocuments() {
      //load document in hiding and get it

      this.agency.collaborators.forEach(async collaborator => {
        collaborator.allTrainings.forEach(async training => {
          if (training.training_attestation_url !== null) {
            let path = URL.createObjectURL(encodeURI(training.training_attestation_url))
            console.log(path)
          }
          if (training.training_module_url !== null) {
            let path2 = URL.createObjectURL(encodeURI(training.training_module_url))
            console.log(path2)
          }
        })
      })

      await merger.save("merged.pdf")
    },
    async generatePDFs() {
      const collaborators = this.agency.collaborators
      for (const collaborateur of collaborators) {
        const docDefinition = {
          pageOrientation: "landscape",
          content: [],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
          },
        }

        if (collaborateur.allTrainings.length > 0) {
          docDefinition.content.push({ text: `${collaborateur.firstname} ${collaborateur.lastname}`, style: "header" })

          for (const formation of collaborateur.allTrainings) {
            const formationData = [
              { text: "Organisme", style: "header" },
              { text: "Titre de la formation", style: "header" },
              { text: "Date", style: "header" },
              { text: "Durée", style: "header" },
            ]

            docDefinition.content.push(formationData)

            const formationRow = [
              formation.organism,
              formation.designation,
              formation.display_date,
              formation.display_duration,
            ]

            docDefinition.content.push(formationRow)

            // Download and embed the module_url PDF if available
            if (formation.training_module_url) {
              const modulePdf = await this.downloadPDF(encodeURI(formation.training_module_url))
              docDefinition.content.push(modulePdf)
            }

            // Download and embed the attestation_url PDF if available
            if (formation.training_attestation_url) {
              const attestationPdf = await this.downloadPDF(encodeURI(formation.training_attestation_url))
              docDefinition.content.push(attestationPdf)
            }

            docDefinition.content.push({ text: "", pageBreak: "after" })
          }
        }

        pdfMake.createPdf(docDefinition).download(`${collaborateur.lastname}_formations.pdf`)
      }
    },

    async downloadPDF(url) {
      return new Promise((resolve, reject) => {
        // Use an appropriate method to download the PDF from the URL
        // For example, you can use axios or fetch to download the PDF
        // Here's a simple example using fetch:
        fetch(url, { method: "GET", mode: "cors" })
          .then(response => response.blob())
          .then(blob => {
            // Create a data URL for embedding the PDF in the document
            const dataUrl = URL.createObjectURL(blob)
            const embeddedPdf = { image: dataUrl, width: 500 } // Adjust width as needed
            resolve(embeddedPdf)
          })
          .catch(error => {
            console.error(`Error downloading PDF from ${url}: ${error}`)
            reject(error)
          })
      })
    },

    async openTrainingDocuments(item, id) {
      this.selectedTraining = item
      this.selectedTraining.id_document = id
      id === 1
        ? (this.previewedUrl = await this.previewFile(item.training_module_url))
        : (this.previewedUrl = await this.previewFile(item.training_attestation_url))
      this.dialogTrainingDocuments = true
    },
    getTrainingsByYear(collabs) {
      let trainingsByYear = []

      collabs.forEach(collab => {
        let trainings = []
        let max_documents = 0
        let max_documents_completed = 0
        let resultDDA = "00:00:00"
        let resultNDDA = "00:00:00"
        let resultDDADone = "00:00:00"
        let resultByYear = "00:00:00"
        collab.organization.forEach(org => {
          org.training.forEach(training => {
            let training_documents = 0
            training.isDDA = training.isDDA
            training.year = org.year
            training.duration = moment(training.duration, "HH:mm:ss").format("HH:mm:ss")
            training.display_date = moment(training.date).format("Do MMM YYYY")
            training.display_duration = moment(training.duration, "HH:mm:ss").format("HH[h]mm")

            if (training.year == this.year) {
              max_documents += 2

              if (training.training_module_url != null) {
                max_documents_completed++
                training_documents++
              }

              if (training.training_attestation_url != null) {
                max_documents_completed++
                training_documents++
              }

              if (parseInt(training.isDDA) === 1) {
                resultDDA = this.additionnerHeures(resultDDA, training.duration)
                resultByYear = this.additionnerHeures(resultByYear, training.duration)

                if (training_documents > 0) {
                  resultDDADone = this.additionnerHeures(resultDDADone, training.duration)
                }
              } else {
                resultNDDA = this.additionnerHeures(resultNDDA, training.duration)
              }
              trainings.push(training)
            }
          })
        })
        trainingsByYear.push({
          id: collab.id,
          firstname: collab.firstname,
          lastname: collab.lastname,
          name: collab.lastname,
          allTrainings: trainings,
          sum_hours: this.formatDuration(this.additionnerHeures(resultDDA, resultNDDA)),
          sum_DDA_hours: this.formatDuration(resultDDA),
          sum_DDA_hours_done: this.formatDuration(resultDDADone),
          percentage: max_documents > 0 ? Math.round((max_documents_completed / max_documents) * 100, 2) : 0,
        })
      })

      return trainingsByYear
    },
    formatDuration(duration) {
      const dur = moment.duration(duration, "HH:mm:ss")
      const totalHours = Math.floor(dur.asHours()) // Obtient le total des heures
      const minutes = dur.minutes()

      return totalHours.toString().padStart(2, "0") + "h" + minutes.toString().padStart(2, "0")
    },
    getTrainingByYear(items) {
      let training = []
      items.forEach(item => {
        if (item.year == this.year) {
          training.push(item)
        }
      })
      return training
    },
    previousYear() {
      this.year = moment(this.year).subtract(1, "years").format("YYYY")
    },
    nextYear() {
      this.year = moment(this.year).add(1, "years").format("YYYY")
    },
    async update() {
      this.fetchAgency()
      this.fetchAgencyHoliday()
    },
    async fetchAgencyHoliday() {
      this.collaboratorsHoliday = []
      this.collaboratorsValidation = []
      this.demand = []
      this.test = []

      let headers = new Headers()
      headers.append("Content-Type", "application/json")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      let idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        const isManager = localStorage.getItem("grade") === "manager"

        if (isManager) {
          // Filtrer pour les managers
          // data.collaborators = data.collaborators.filter(collaborator =>
          //   this.supervisedMembersIds.includes(collaborator.id),
          // )

          data.demand = data.demand.filter(demand => this.supervisedMembersIds.includes(demand.id_account))

          data.history = data.history.filter(demand => this.supervisedMembersIds.includes(demand.id_account))
        }

        data.collaborators.forEach(collaborator => {
          collaborator.holidays.forEach(holiday => {
            // Mettre à jour les propriétés de l'objet holiday
            holiday.id = parseInt(holiday.id)
            holiday.id_account = parseInt(holiday.id_account)
            holiday.start_date = holiday.start_date
            holiday.end_date = holiday.end_date
            holiday.type = holiday.type
            holiday.number_of_days = parseFloat(holiday.number_of_days)
            holiday.state = parseInt(holiday.state)
            holiday.is_half_day = parseInt(holiday.is_half_day)
            holiday.half_day_type = parseInt(holiday.half_day_type)
            holiday.remain_paid_leave = parseInt(holiday.remain_paid_leave)
          })
          this.collaboratorsHoliday.push(collaborator)

          // collaborator.worked_days.forEach(day => {
          //   day.days = JSON.parse(day.days)
          //   day.days.forEach(workingDay => {
          //     workingDay.idWorkingDay = parseInt(day.id)
          //   })
          // })
        })

        data.collaborators.forEach(collaborator => {
          collaborator.worked_days.forEach(day => {
            if (parseInt(day.is_confirm) === 0) {
              day.days != null ? (day.days = JSON.parse(day.days)) : (day.days = [])
              day.days.forEach(workingDay => {
                workingDay.idWorkingDay = parseInt(day.id)
              })
            }
          })

          this.collaboratorsValidation.push(collaborator)
        })

        data.worked_days.forEach(m => {
          this.test.push(m)
        })

        data.demand.forEach(demand => {
          this.demand.push({
            id: demand.id,
            idAccount: demand.id_account,
            lastname: demand.lastname,
            firstname: demand.firstname,
            email: demand.email,
            number_of_days: parseFloat(demand.number_of_days),
            start_date: demand.start_date,
            end_date: demand.end_date,
            type: demand.type,
            state: parseInt(demand.state),
            date: demand.date,
            remain_paid_leave: demand.remain_paid_leave,
            recovery_working_time_leave: demand.recovery_working_time_leave,
            compensatory_package_leave: demand.compensatory_package_leave,
            remain_paid_leave_last_year: demand.remain_paid_leave_last_year,
            compensatory_package_leave_last_year: demand.compensatory_package_leave_last_year,
            recovery_working_time_leave_last_year: demand.recovery_working_time_leave_last_year,
            date_format: moment(demand.date).format("DD/MM/YYYY"),
            start_date_format: moment(demand.start_date).format("DD/MM/YYYY"),
            end_date_format: moment(demand.end_date).format("DD/MM/YYYY"),
            collaborator_comment: demand.collaborator_comment,
            is_half_day: parseInt(demand.is_half_day),
            half_day_type: parseInt(demand.half_day_type),
            resume: `${demand.lastname} ${demand.firstname} souhaite prendre  <strong>${
              demand.number_of_days
            }</strong> jour(s) de ${demand.type} du ${moment(demand.start_date).format("DD/MM/YYYY")} au ${moment(
              demand.end_date,
            ).format("DD/MM/YYYY")}`,
            days_remaining: parseFloat(demand.days_remaining),
            id_employment: parseInt(demand.employment_id),
          })
        })

        data.history.forEach(demand => {
          this.demandHistory.push({
            id: demand.id,
            lastname: demand.lastname,
            firstname: demand.firstname,
            complete_name: `${demand.lastname} ${demand.firstname}`,
            number_of_days: parseFloat(demand.number_of_days),
            start_date: demand.start_date,
            end_date: demand.end_date,
            type: demand.type,
            state: parseInt(demand.state),
            date: demand.date,
            remain_paid_leave: demand.remain_paid_leave,
            recovery_working_time_leave: demand.recovery_working_time_leave,
            compensatory_package_leave: demand.compensatory_package_leave,
            remain_paid_leave_last_year: demand.remain_paid_leave_last_year,
            compensatory_package_leave_last_year: demand.compensatory_package_leave_last_year,
            recovery_working_time_leave_last_year: demand.recovery_working_time_leave_last_year,
            date_format: moment(demand.date).format("DD/MM/YYYY"),
            start_date_format: moment(demand.start_date).format("DD/MM/YYYY"),
            end_date_format: moment(demand.end_date).format("DD/MM/YYYY"),
            collaborator_comment: demand.collaborator_comment,
            is_half_day: parseInt(demand.is_half_day),
            half_day_type: parseInt(demand.half_day_type),
            resume: `${demand.lastname} ${demand.firstname} souhaite prendre  <strong>${
              demand.number_of_days
            }</strong> jour(s) de ${demand.type} du ${moment(demand.start_date).format("DD/MM/YYYY")} au ${moment(
              demand.end_date,
            ).format("DD/MM/YYYY")}`,
            days_remaining: parseFloat(demand.days_remaining),
            id_employment: parseInt(demand.employment_id),
          })
        })

        // pause(4000)
      } catch (e) {
        console.log(e)
      }
    },
    handleServiceSelection(selectedServices) {
      // Réinitialisez newTraining.collaborators à un tableau vide
      this.newTraining.collaborators = []

      // Parcourez les services sélectionnés
      for (const service of selectedServices) {
        // Parcourez les membres du service et ajoutez leurs IDs à newTraining.collaborators
        for (const member of service.members) {
          this.newTraining.collaborators.push(member.id)
        }
      }
    },
    async verifyDocuments() {
      // Utilisez une fonction itérative asynchrone pour introduire un délai entre chaque envoi d'e-mail
      async function sendEmailWithDelay(collaborator, organization, training) {
        let obligatoryItems = []

        if (training.training_module_url === null || training.training_module_url === "") {
          obligatoryItems.push("Module de formation")
        }
        if (training.training_attestation_url === null || training.training_attestation_url === "") {
          obligatoryItems.push("Attestation de formation")
        }

        try {
          await emailjs.send("service_5p49t7p", "template_e4h8ozb", {
            firstname: collaborator.firstname,
            training_name: training.designation,
            date: training.date,
            documents: obligatoryItems,
            //from_name: email,
            to_email: collaborator.email,
          })
        } catch (error) {
          console.log(error)
        }
      }

      for (const collaborator of this.agency.collaborators) {
        for (const organization of collaborator.organization) {
          for (const training of organization.training) {
            // Introduisez un délai de 4 secondes (4000 millisecondes) entre chaque envoi d'e-mail
            await new Promise(resolve => setTimeout(resolve, 4000))
            await sendEmailWithDelay(collaborator, organization, training)
          }
        }
      }
    },

    // async verifyDocuments() {
    //   this.agency.collaborators.forEach(async collaborator => {
    //     collaborator.organization.forEach(async organization => {
    //       organization.training.forEach(async training => {
    //         await pause(4000)

    //         let obligatoryItems = []

    //         if (training.training_module_url === null || training.training_module_url === "") {
    //           obligatoryItems.push("Module de formation")
    //         }
    //         if (training.training_attestation_url === null || training.training_attestation_url === "") {
    //           obligatoryItems.push("Attestation de formation")
    //         }

    //         try {
    //           emailjs.send("service_5p49t7p", "template_e4h8ozb", {
    //             firstname: collaborator.firstname,
    //             training_name: training.designation,
    //             date: training.date,
    //             documents: obligatoryItems,
    //             //from_name: email,
    //             to_email: collaborator.email,
    //           })
    //         } catch (error) {
    //           console.log(error)
    //         }
    //       })
    //     })
    //   })
    // },
    async postTrainingComment(id, idAccount, description) {
      try {
        const formData = new FormData()

        formData.append("id", id)
        formData.append("description", description)
        formData.append("idAccount", idAccount)
        formData.append("place", "postComments")

        fetch(`${config.apiUri}/php/pages/account_training.php`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Formation modifiée avec succès", {
              position: "bottom-right",
            })
            this.fetchAgency()
          } else {
            this.$toast.error("Erreur lors de la modification de la formation", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    async putTraining(item, idAccount) {
      try {
        // Construire le corps de la requête
        const requestBody = {
          date: moment(item.date).format("YYYY-MM-DD"),
          duration: item.duration,
          designation: item.designation,
          organism: item.organism,
          isDDA: item.isDDA ? 1 : 0,
        }

        // Préparation des headers, notamment pour l'autorisation et le type de contenu
        const headers = new Headers()
        headers.append("Content-Type", "application/json")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`) // Assurez-vous d'avoir un token valide

        // Appel de l'API avec la méthode PUT
        const response = await fetch(`${config.apiUri}/accounts/${idAccount}/trainings/${item.id}`, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(requestBody),
        })

        if (response.ok) {
          this.$toast.success("Formation modifiée avec succès", {
            position: "bottom-right",
          })
          // Rafraîchir les données comme nécessaire
          this.fetchAgency()
          this.dialogEditTraining = false
        } else {
          this.$toast.error("Erreur lors de la modification de la formation", {
            position: "bottom-right",
          })
        }
      } catch (err) {
        console.log(err)
        this.$toast.error("Une erreur est survenue lors de la mise à jour de la formation", {
          position: "bottom-right",
        })
      }
    },
    uploadNewTraining(training, id) {
      this.selectedTraining = training
      try {
        const input = document.createElement("input")
        input.type = "file"
        input.accept =
          "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        input.addEventListener("change", event => {
          this.documentTraining.idTraining = training.id
          this.documentTraining.name = event.target.files[0].name
          this.documentTraining.type = id
          this.documentTraining.data = event.target.files[0]
          this.fileUrlTraining = URL.createObjectURL(this.documentTraining.data)
          this.dialogUploadDocument = true
          // this.uploadFile(event, type, area, subType)
        })
        input.click()
      } catch (err) {
        console.log(err)
      }
    },

    async postTrainingDocument(idTraining, lastname, firstname) {
      try {
        console.log(this.$store.state)
        const formData = new FormData()
        formData.append("agency", this.$store.state.user.agency_name)
        formData.append("lastname", lastname)
        formData.append("firstname", firstname)
        formData.append("idTraining", idTraining)
        this.documentTraining.type === 1 ? formData.append("endpoint", "trainingModule") : null
        this.documentTraining.type === 2 ? formData.append("endpoint", "trainingAttestation") : null
        formData.append("file", this.documentTraining.data)
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })
        if (res.ok) {
          this.dialogUploadDocument = false

          this.documentTraining = {
            name: "",
            data: {
              name: null,
              type: null,
            },
            categorie: null,
          }

          this.editedUploadTraining = {
            lastname: "",
            firstname: "",
          }

          this.fetchAgency()

          this.dialogTrainingDocuments = false
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    // async postTrainingDocument(user) {
    //   const formData = new FormData()
    //   formData.append("document", this.documentTraining.data)
    //   formData.append("documentName", this.documentTraining.name)
    //   formData.append("username", user.username)
    //   formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
    //   formData.append("idTraining", this.documentTraining.idTraining)
    //   this.documentTraining.type === 1 ? formData.append("place", "addTrainingModule") : null
    //   this.documentTraining.type === 2 ? formData.append("place", "addTrainingAttestation") : null

    //   try {
    //     const res = await fetch(`${config.apiUri}/upload`, {
    //       method: "POST",
    //       body: formData,
    //     })
    //     if (res.ok) {
    //       this.dialogUploadDocument = false

    //       this.documentTraining = {
    //         name: "",
    //         data: {
    //           name: null,
    //           type: null,
    //         },
    //         categorie: null,
    //       }

    //       this.editedUploadTraining = {
    //         lastname: "",
    //         firstname: "",
    //       }

    //       this.fetchAgency()

    //       this.dialogTrainingDocuments = false
    //     } else {
    //       alert("Erreur lors de l'upload du PDF")
    //     }
    //   } catch (err) {
    //     console.error(err)
    //     alert("Erreur lors de l'upload du PDF")
    //   }
    // },
    async deleteTraining(id, idAccount) {
      fetch(`${config.apiUri}/accounts/${idAccount}/trainings/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      })
        .then(response => {
          if (response.ok) {
            this.fetchAgency()
            this.$toast.success("Formation supprimée avec succès")
          } else {
            this.$toast.error("Erreur lors de la suppression de la formation")
          }
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    getYearId(year) {
      let result
      this.agency.years.forEach(item => {
        if (item.year == year) {
          result = item.id
        }
      })
      return result
    },
    async postNewTraining() {
      const YEAR = this.getYearId(moment(this.newTraining.date).format("YYYY"))

      try {
        const postData = {
          idAccountTrainingYear: YEAR,
          idAccounts: JSON.stringify(this.newTraining.collaborators),
          date: this.newTraining.date,
          duration: this.newTraining.duration,
          designation: this.newTraining.designation,
          organism: this.newTraining.organism,
          isDDA: this.newTraining.isDDA ? 1 : 0,
          isHalfDay: this.newTraining.isHalfDay ? 1 : 0,
          halfDayType: this.newTraining.isHalfDay ? this.newTraining.halfDayType : 0,
        }

        fetch(`${config.apiUri}/accounts/trainings`, {
          method: "POST",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            // this.uploadTraining(this.selected.completeName)
            // this.$toast.success("Formation ajoutée avec succès")
            // this.initialize()
            // this.fetchUsers()
            this.dialogAddTraining = false
            this.currentIdAccount = null

            this.newTraining = {
              collaborators: [],
              date: "",
              isDDA: false,
              designation: "",
              duration: "",
              organism: "",
            }
            this.$toast.success("Formation ajoutée avec succès")
            this.update()
          } else {
            this.$toast.error("Erreur lors de l'ajout de la formation")
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getProgressColor(percentage) {
      if (percentage === 100) {
        return "EoleBlue"
      } else {
        return "EoleYellow"
      }
    },
    async fetchAgencyFile() {
      let headers = new Headers()
      let agencyId = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (parseInt(data.code) === 1) {
          this.agency.files = data.agency[0].files
        }
      } catch (e) {
        console.log(e)
      }
    },
    editItem(item) {
      this.editServiceManage = item
      this.dialogEditService = true
    },
    handleScroll(event) {
      if (event.deltaY < 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomOut
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomIn()
      } else if (event.deltaY > 0) {
        // Faites quelque chose ici pour exécuter la fonction ZoomIn
        // par exemple, appelez directement la fonction ou émettez un événement personnalisé
        this.zoomOut()
      }
    },
    async postCollaboratorInService(idService, collaborators) {
      const formData = new FormData()
      formData.append("idService", idService)
      formData.append("collaborators", JSON.stringify(collaborators))
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=addCollaborators`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.fetchServicesMembers(idService, this.services.length - 1)
          this.$toast.success("Collaborateur ajouté avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    deleteServiceCollaborator(idService, idCollaborator) {
      let idAgency = this.$store.state.user.agency_id
      fetch(
        `${config.apiUri}/php/pages/services.php?idAgency=${idAgency}&idService=${idService}&place=deleteCollaborator&idCollaborator=${idCollaborator}`,
        {
          method: "DELETE",
        },
      )
        .finally(() => {
          this.fetchServices()
          this.$toast.success("Collab supprimé avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    deleteService(id) {
      let idAgency = this.$store.state.user.agency_id
      fetch(`${config.apiUri}/php/pages/services.php?idAgency=${idAgency}&idService=${id}&place=deleteService`, {
        method: "DELETE",
      })
        .finally(() => {
          this.fetchServices()
          this.$toast.success("Service supprimé avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },

    putService(item) {
      const formData = new FormData()
      // formData.append("idService", this.editService.idService)
      formData.append("name", item.name)
      formData.append("idService", item.id_service)
      formData.append("idManager", item.id_manager)
      formData.append("idAgencyEtablishment", item.id_agency_etablishment)
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=editService`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.dialogPutServiceManage = false
          this.$toast.success("Service mdifier avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
        })
    },
    postService() {
      const formData = new FormData()
      // formData.append("idService", this.editService.idService)
      formData.append("name", this.editService.name)
      formData.append("idManager", this.editService.idManager)
      formData.append("idAgencyEtablishment", this.editService.idAgencyEtablishment)
      formData.append("idAgency", this.$store.state.user.agency_id)

      // using fetch to post data
      fetch(`${config.apiUri}/php/pages/services.php?place=addService`, {
        method: "POST",
        body: formData,
      })
        .finally(() => {
          this.fetchServices()
          this.dialogAddService = false
          this.stepperAddService = 1
          this.$toast.success("Service ajouté avec succès")
        })
        .catch(() => {
          this.$toast.error("Une erreur est survenue")
          this.stepperAddService = 1
        })

      this.editService = {
        // idService: null,*
        name: "",
        idManager: null,
        idAgencyEtablishment: null,
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    importPutFile(file, type, area, subType) {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.addEventListener("change", event => {
        this.editFile = file
        this.document.data = event.target.files[0]
        file.id_agency_etablishment === null
          ? (this.document.id_agency_etablishment = 999)
          : (this.document.id_agency_etablishment = file.id_agency_etablishment)
        this.fileUrl = URL.createObjectURL(this.document.data)
        let splitName = this.document.data.name.split(".")
        this.document.title = splitName[0]
        this.document.extension = splitName[1]
        this.dialogUpload.dialog = true
        this.dialogUpload.type = type
        this.dialogUpload.area = area
        this.dialogUpload.subType = subType

        // this.uploadFile(event, type, area, subType)
      })
      input.click()
    },
    getObligatoryInformations() {
      this.obligatoryInformations.max = 3

      this.agencyPictureProfil != null
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Logo de l'agence")
      this.agency.legal_mention_middleman != null && this.agency.legal_mention_middleman != ""
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Mention légale courtier")
      this.agency.legal_mention_agent != null && this.agency.legal_mention_agent != ""
        ? this.obligatoryInformations.value++
        : this.obligatoryInformations.fields.push("Mention légale agent")

      this.obligatoryInformations.percentage = Math.round(
        (this.obligatoryInformations.value / this.obligatoryInformations.max) * 100,
        2,
      )
    },
    importFile(type, area, subType) {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.addEventListener("change", event => {
        this.document.data = event.target.files[0]
        this.documentArea.type = type
        this.documentArea.area = area
        this.documentArea.subType = subType
        this.fileUrl = URL.createObjectURL(this.document.data)
        this.dialogUploadPostFile = true

        // this.uploadFile(event, type, area, subType)
      })
      input.click()
    },
    openFileExplorer() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "image/png, image/jpeg"
      input.addEventListener("change", this.uploadPicture)
      input.click()
    },
    getPictureProfil() {
      try {
        let agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        let pictureProfilPng = `${config.ged}/${agency}/agency/profil.png?${Date.now()}`
        let pictureProfilJpg = `${config.ged}/${agency}/agency/profil.jpg?${Date.now()}`

        let img = new Image()
        img.onload = () => {
          this.agencyPictureProfil = img.src
        }
        img.onerror = () => {
          this.agencyPictureProfil = null
        }

        // Test pour l'image .png
        img.src = pictureProfilPng

        // Si l'image .png n'est pas disponible, tester pour l'image .jpg
        setTimeout(() => {
          if (this.agencyPictureProfil == null) {
            img.src = pictureProfilJpg
          }

          this.obligatoryInformations = {
            value: 0,
            max: 0,
            percentage: 0,
            fields: [],
          }
          this.getObligatoryInformations()
        }, 500)
      } catch (error) {
        this.agencyPictureProfil = null
      }

      // return url with picture profil
    },

    async putFileSetShareable(idFile, value) {
      value === true ? (value = 1) : (value = 0)
      try {
        const res = await fetch(`${config.apiUri}/php/pages/agencies_file.php?idFile=${idFile}&share=${value}`, {
          method: "PUT",
        })
        if (res.ok) {
          if (value === 1) {
            this.socket = io(config.socketUri)
            this.socket.emit("notificationAll", {
              type: 3,
              info: {
                title: `Nouveau document disponible`,
                datetime: new Date(),
                action: `Un nouveau document a été partagé avec vous. Vous pouvez le consulter dans votre espace d'entreprise`,
              },
            })
            this.$toast.success("Fichier partagé avec succès", {
              position: "bottom-right",
              timeout: 5000,
            })
          } else {
            this.$toast.success("Fichier non partagé avec succès", {
              position: "bottom-right",
              timeout: 5000,
            })
          }
        } else {
          this.$toast.success("Erreur dans le partage du fichier", {
            position: "bottom-right",
            timeout: 5000,
          })
        }
      } catch (err) {
        console.error(err)
      }
    },

    async deleteFile(idFile, path) {
      try {
        const res = await fetch(`${config.apiUri}/php/pages/agencies_file.php?idFile=${idFile}&path=${path}`, {
          method: "DELETE",
        })
        if (res.ok) {
          this.$toast.success("Fichier supprimé avec succès")
          this.fetchAgency()
        } else {
          alert("Erreur lors de la suppression du fichier")
        }
      } catch (err) {
        console.error(err)
      }
    },
    async uploadPutFile(event, type, area, subType = null, idFile) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.title)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "agency")
      formData.append("type", type)
      formData.append("area", area)
      formData.append("idAgency", this.$store.state.user.agency_id)
      formData.append("idFile", idFile)
      formData.append("methods", "PUT")
      subType != null ? formData.append("subType", subType) : null

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        }).then(res => {
          if (res.ok) {
            this.document = {
              name: "",
              data: null,
              categorie: null,
              id_agency_etablishment: 999,
            }

            this.fetchAgency()
            this.dialogUpload = {
              dialog: false,
              type: 0,
              area: "",
              subType: "",
            }
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    async uploadFile(event, type, area, subType = null) {
      const formData = new FormData()
      formData.append("document", this.document.data)
      formData.append("documentName", this.document.name)
      formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
      formData.append("place", "agency")
      formData.append("type", type)
      formData.append("area", area)
      formData.append("idAgency", this.$store.state.user.agency_id)
      area === "affichage_obligatoire"
        ? formData.append("idAgencyEtablishment", this.document.id_agency_etablishment)
        : null
      subType != null ? formData.append("subType", subType) : null

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        }).then(res => {
          if (res.ok) {
            this.document = {
              name: "",
              data: null,
              categorie: null,
              id_agency_etablishment: 999,
            }

            this.fetchAgency()
            this.dialogUploadPostFile = false
          } else {
            alert("Erreur lors de l'upload du PDF")
          }
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    async uploadPicture() {
      const file = event.target.files[0]
      this.loadImage = true
      this.document.data = file
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
          id_agency_etablishment: 999,
        }
      } else {
        switch (this.document.data.type) {
          case "image/png":
          case "image/jpeg":
            const id = localStorage.getItem("user_id")
            const username = localStorage.getItem("complete_name").replace(" ", "-")
            const formData = new FormData()
            formData.append("document", this.document.data)
            formData.append("documentName", "profil")
            formData.append("agencyName", localStorage.getItem("agency").toLowerCase())
            formData.append("place", "agency")

            try {
              fetch(`${config.apiUri}/upload`, {
                method: "POST",
                body: formData,
              })
                .then(res => {
                  if (res.ok) {
                    this.dialogUpload = false
                    this.document = {
                      name: "",
                      data: null,
                      categorie: null,
                      id_agency_etablishment: 999,
                    }
                  } else {
                    alert("Erreur lors de l'upload de l'image")
                  }
                })
                .finally(() => {
                  setTimeout(() => {
                    // location.reload()
                    this.loadImage = false
                    this.getPictureProfil()
                  }, 2000)
                })
            } catch (err) {
              console.error(err)
              alert("Erreur lors de l'upload de l'image")
            }

            break
          default:
            this.fileSelected = false
            this.$toast.error(
              `Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf, .png ou .jpeg.`,
              {
                position: "bottom-right",
                timeout: 3000,
              },
            )
        }
      }
    },
    async fetchAllServices() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)
      try {
        const res = await fetch(`${config.apiUri}/services`, {
          method: "GET",
          headers: headers,
        })
        const data = await res.json()

        this.servicesProposed = data
      } catch (err) {
        console.error(err)
      }
    },
    async putAgencyLegalNotice(type) {
      const formData = new FormData()
      formData.append("place", "updateLegalNotice")
      formData.append(
        "legalNotice",
        type === "agent" ? this.agency.legal_mention_agent : this.agency.legal_mention_middleman,
      )
      formData.append("type", type)

      try {
        await fetch(`${config.apiUri}/agencies/${this.agency.id}`, {
          method: "POST",
          body: formData,
        }).finally(() => {
          this.fetchAgency()

          this.$toast.success("Mention légale mise à jour avec succès", {
            position: "bottom-right",
            timeout: 5000,
          })
        })
      } catch (err) {
        console.error(err)

        this.$toast.error("Impossible de mettre les mentions légales à jour.", {
          position: "bottom-right",
          timeout: 5000,
        })
      }
    },
    redirect() {
      // redirect in new tab

      window.open("https://www.tresor.economie.gouv.fr/services-aux-entreprises/sanctions-economiques", "_blank")
    },
    selectTabProcess(tab) {
      this.tabProcess = tab
    },
    selectTabHR(tab) {
      this.tabHR = tab
    },
    setFile(e) {
      this.pdf = e.target.files[0]
    },
    async uploadPDF() {
      const formData = new FormData()
      formData.append("pdf", this.pdf)

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
          alert("PDF uploadé avec succès")
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },

    zoomIn() {
      this.$refs.tree.zoomIn()
    },
    zoomOut() {
      this.$refs.tree.zoomOut()
    },
    showMap(adress) {
      let routeData = this.$router.resolve({
        name: `https://www.google.fr/maps/place/${adress}`,
      })
      window.open(routeData.location.name, "_blank")
    },

    // async fetchCollectiveAgreements(siret) {
    //   let headers = new Headers()

    //   headers.append("Content-Type", "application/json charset=UTF-8")
    //   headers.append("Accept", "application/json")
    //   headers.append("Origin", "*")

    //   try {
    //     let response = await fetch(`https://siret2idcc.fabrique.social.gouv.fr/api/v2/${siret}`, {
    //       mode: "cors",
    //       method: "GET",
    //       headers: headers,
    //     })

    //     const data = await response.json()

    //     this.collectiveAgreements = data[0].conventions
    //     if (this.collectiveAgreements.length === 0) {
    //       this.collectiveAgreements = [
    //         {
    //           id: 0,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 1,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 2,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //         {
    //           id: 3,
    //           name: "Aucune convention collective trouvée",
    //           pdfLocation: null,
    //         },
    //       ]
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    async getImg2(lastname, firstname) {
      try {
        const name = `${lastname.toLowerCase().replace(" ", "")}-${firstname.toLowerCase()}`
        const agency = localStorage.getItem("agency").toLowerCase().replace(/ /g, "_")
        const pictureProfilPng = `${config.ged}/${agency}/users/${name}/profil.png`
        const pictureProfilJpg = `${config.ged}/${agency}/users/${name}/profil.jpg`

        // Create a Promise to handle image loading
        const loadImage = src => {
          return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => resolve(img.src)
            img.onerror = () => reject(null) // Failed to load image
            img.src = src
          })
        }

        // Attempt to load the PNG image
        let result = await loadImage(pictureProfilPng)

        // If the PNG image fails to load, try the JPG image
        if (!result) {
          result = await loadImage(pictureProfilJpg)
        }

        return result // Return the URL or null if no image is found
      } catch (error) {
        console.error("Error fetching avatar:", error)
        return null // Return null in case of any errors
      }
    },
    sortFiles() {
      this.obligatoryFilesCount = {
        value: 0,
        max: 0,
        percentage: 0,
      }
      this.agency.files.forEach(file => {
        switch (parseInt(file.id)) {
          case 1:
            file.files.forEach(item => {
              this.agency.contracts.push(item)
              this.agency.contracts.forEach(contract => {
                contract.format_date_upload = moment(contract.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                contract.share_acpr = parseInt(contract.share_acpr)
                contract.share = parseInt(contract.share)
              })
            })
            break
          case 2:
            file.files.forEach(item => {
              this.agency.charts.push(item)
              this.agency.charts.forEach(chart => {
                chart.format_date_upload = moment(chart.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                chart.share_acpr = parseInt(chart.share_acpr)
                chart.share = parseInt(chart.share)
              })
            })
            break
          case 3:
            file.files.forEach(item => {
              this.agency.conventions.push(item)
              this.agency.conventions.forEach(convention => {
                convention.format_date_upload = moment(convention.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                convention.share_acpr = parseInt(convention.share_acpr)
                convention.share = parseInt(convention.share)
              })
            })
            break
          case 4:
            file.files.forEach(item => {
              this.agency.decision.push(item)
              this.agency.decision.forEach(decision => {
                decision.format_date_upload = moment(decision.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                decision.share_acpr = parseInt(decision.share_acpr)
                decision.share = parseInt(decision.share)
              })
            })
            break
          case 5:
            file.files.forEach(item => {
              item.path != null ? this.obligatoryFilesCount.value++ : null

              this.agency.display.push(item)
              this.agency.display.forEach(display => {
                display.format_date_upload = moment(display.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                display.share_acpr = parseInt(display.share_acpr)
                display.share = parseInt(display.share)
              })

              this.obligatoryFilesCount.max++
              this.obligatoryFilesCount.percentage = Math.round(
                (this.obligatoryFilesCount.value / this.obligatoryFilesCount.max) * 100,
                2,
              )
            })

            break
          case 6:
            file.files.forEach(item => {
              this.agency.announce.push(item)
              this.agency.announce.forEach(announce => {
                announce.format_date_upload = moment(announce.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                announce.share_acpr = parseInt(announce.share_acpr)
                announce.share = parseInt(announce.share)
              })
            })
            break
          case 7:
            file.files.forEach(item => {
              this.agency.recruit.push(item)
              this.agency.recruit.forEach(recruit => {
                recruit.format_date_upload = moment(recruit.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                recruit.share_acpr = parseInt(recruit.share_acpr)
                recruit.share = parseInt(recruit.share)
              })
            })
            break
          case 8:
            file.files.forEach(item => {
              this.agency.poste.push(item)
              this.agency.poste.forEach(poste => {
                poste.format_date_upload = moment(poste.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                poste.share_acpr = parseInt(poste.share_acpr)
                poste.share = parseInt(poste.share)
              })
            })
            break
          case 9:
            file.files.forEach(item => {
              this.agency.money_accord.push(item)
              this.agency.money_accord.forEach(money_accord => {
                money_accord.format_date_upload = moment(money_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
                money_accord.share_acpr = parseInt(money_accord.share_acpr)
                money_accord.share = parseInt(money_accord.share)
              })
            })
            break
          case 10:
            file.files.forEach(item => {
              this.agency.participation_accord.push(item)
              this.agency.participation_accord.forEach(participation_accord => {
                participation_accord.format_date_upload = moment(participation_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
                participation_accord.share_acpr = parseInt(participation_accord.share_acpr)
                participation_accord.share = parseInt(participation_accord.share)
              })
            })
            break
          case 11:
            file.files.forEach(item => {
              this.agency.reclamations.push(item)
              this.agency.reclamations.forEach(reclamation => {
                reclamation.format_date_upload = moment(reclamation.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                reclamation.share_acpr = parseInt(reclamation.share_acpr)
                reclamation.share = parseInt(reclamation.share)
              })
            })
            break
          case 12:
            file.files.forEach(item => {
              this.agency.lcbft.push(item)
              this.agency.lcbft.forEach(lcbft => {
                lcbft.format_date_upload = moment(lcbft.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 13:
            file.files.forEach(item => {
              this.agency.ppe.push(item)
              this.agency.ppe.forEach(ppe => {
                ppe.format_date_upload = moment(ppe.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 14:
            file.files.forEach(item => {
              this.agency.dda.push(item)
              this.agency.dda.forEach(dda => {
                dda.format_date_upload = moment(dda.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 15:
            file.files.forEach(item => {
              this.agency.rgpd.push(item)
              this.agency.rgpd.forEach(rgpd => {
                rgpd.format_date_upload = moment(rgpd.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 16:
            file.files.forEach(item => {
              this.agency.gda.push(item)
              this.agency.gda.forEach(gda => {
                gda.format_date_upload = moment(gda.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
          case 17:
            file.files.forEach(item => {
              item.path != null ? this.obligatoryFilesCount.value++ : null

              this.agency.agency.push(item)
              this.agency.agency.forEach(display => {
                display.format_date_upload = moment(display.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
                display.share_acpr = parseInt(display.share_acpr)
                display.share = parseInt(display.share)
              })

              this.obligatoryFilesCount.max++
              this.obligatoryFilesCount.percentage = Math.round(
                (this.obligatoryFilesCount.value / this.obligatoryFilesCount.max) * 100,
                2,
              )
            })

            break
          case 18:
            file.files.forEach(item => {
              this.agency.endorsement_money_accord.push(item)
              this.agency.endorsement_money_accord.forEach(endorsement_money_accord => {
                endorsement_money_accord.format_date_upload = moment(endorsement_money_accord.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 19:
            file.files.forEach(item => {
              this.agency.social_statement.push(item)
              this.agency.social_statement.forEach(social_statement => {
                social_statement.format_date_upload = moment(social_statement.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 20:
            file.files.forEach(item => {
              this.agency.monthly_status.push(item)
              this.agency.monthly_status.forEach(social_statement => {
                social_statement.format_date_upload = moment(social_statement.upload_datetime).format(
                  "dddd Do MMMM YYYY à HH:mm",
                )
              })
            })
            break
          case 21:
            file.files.forEach(item => {
              this.agency.products.push(item)
              this.agency.products.forEach(product => {
                product.format_date_upload = moment(product.upload_datetime).format("dddd Do MMMM YYYY à HH:mm")
              })
            })
            break
        }
      })
    },
    additionnerHeures(heure1, heure2) {
      const format = "HH:mm:ss"
      const moment1 = moment.duration(heure1, format)
      const moment2 = moment.duration(heure2, format)

      // Convertir chaque durée en minutes et les additionner
      const minutesTotales = moment1.asMinutes() + moment2.asMinutes()

      // Convertir les minutes totales en heures et minutes
      const heures = Math.floor(minutesTotales / 60)
      const minutes = Math.floor(minutesTotales % 60)

      // Construire la chaîne de caractères finale
      let resultat = heures.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0")

      return resultat
    },
    additionnerDureeEtHeure(duree, heure) {
      const format = "HH:mm:ss"
      const regex = /(\d+)\s+jour\(s\)\s+(\d+)h(\d+)/ // Pour matcher "X jour(s) YhZ"

      // Extraire les jours, les heures et les minutes de la durée
      const [, jours, heuresDuree, minutesDuree] = duree.match(regex) || []

      // Convertir en objet moment
      let momentDuree = moment.duration({
        days: parseInt(jours),
        hours: parseInt(heuresDuree),
        minutes: parseInt(minutesDuree),
      })

      // Convertir l'heure en objet moment
      let momentHeure = moment.duration(moment(heure, format).diff(moment("00:00:00", format)))

      // Additionner les deux durées
      momentDuree.add(momentHeure)

      // Convertir le résultat en jours, heures, minutes
      const totalJours = Math.floor(momentDuree.asDays())
      const totalHeures = momentDuree.hours()
      const totalMinutes = momentDuree.minutes()
      const totalSecondes = momentDuree.seconds()

      // Formatage du résultat
      let resultat = [totalHeures, totalMinutes, totalSecondes].map(unit => String(unit).padStart(2, "0")).join(":")

      if (totalJours > 0) {
        resultat = totalJours + " jour(s) " + moment(resultat, "HH:mm").format("HH[h]mm")
      }

      return resultat
    },

    // sum function for the number of members
    async fetchAgency() {
      this.dataFetch = true
      this.collaboratorsCompensatoryLeave = []
      this.collaboratorsRecoveryLeave = []

      this.agency = []

      let headers = new Headers()
      let agencyId = this.$store.state.user.agency_id

      agencyId == undefined ? (agencyId = this.$store.state.user.id_agency) : null

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*") * headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      try {
        let response = await fetch(`${config.apiUri}/agencies/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (response.ok) {
          this.agency = data.agency[0]

          this.agency.contracts = []

          this.agency.charts = []

          this.agency.conventions = []

          this.agency.decision = []

          this.agency.display = []

          this.agency.announce = []

          this.agency.recruit = []

          this.agency.poste = []

          this.agency.money_accord = []

          this.agency.participation_accord = []

          this.agency.reclamations = []

          this.agency.lcbft = []

          this.agency.ppe = []

          this.agency.dda = []

          this.agency.rgpd = []

          this.agency.gda = []

          this.agency.agency = []

          this.agency.endorsement_money_accord = []

          this.agency.social_statement = []

          this.agency.monthly_status = []

          this.agency.products = []

          this.agencyInformations = {
            recovery_current_year: parseFloat(this.agency.recovery_current_year),
            compensatory_current_year: parseFloat(this.agency.compensatory_current_year),
          }

          this.sortFiles()
          this.agency.collaborators.forEach(collaborator => {
            if (parseInt(collaborator.employment_id) === 2 && parseInt(collaborator.disabled) === 0) {
              this.collaboratorsCompensatoryLeave.push({
                id: collaborator.id,
                lastname: collaborator.lastname,
                firstname: collaborator.firstname,
              })
            } else if (parseInt(collaborator.employment_id) != 2 && parseInt(collaborator.disabled) === 0) {
              this.collaboratorsRecoveryLeave.push({
                id: collaborator.id,
                lastname: collaborator.lastname,
                firstname: collaborator.firstname,
              })
            }

            collaborator.birthdate_display = moment(collaborator.birth_date).format("Do MMM YYYY")
            collaborator.nationality != null
              ? (collaborator.nationality_abbreviation = collaborator.nationality.substring(0, 2).toLowerCase())
              : null

            collaborator.disabled = parseInt(collaborator.disabled)
            //random number between 1 and 100
            let max_documents = 0
            let max_documents_completed = 0
            let resultDDA = "00:00:00"
            let resultNDDA = "00:00:00"
            let resultDDADone = "00:00:00"
            let allTrainings = []
            collaborator.organization.forEach(element => {
              let resultByYear = "00:00:00"
              element.training.forEach(training => {
                let training_documents = 0
                training.isDDA = parseInt(training.isDDA)
                training.year = element.year
                training.duration = moment(training.duration, "HH:mm:ss").format("HH:mm")
                training.display_date = moment(training.date).format("Do MMM YYYY")
                training.display_duration = moment(training.duration, "HH:mm:ss").format("HH[h]mm")

                max_documents += 2

                if (training.training_module_url != null) {
                  max_documents_completed++
                  training_documents++
                }

                if (training.training_attestation_url != null) {
                  max_documents_completed++
                  training_documents++
                }

                if (parseInt(training.isDDA) === 1) {
                  // resultDDA = this.additionnerHeures(resultDDA, training.duration)

                  resultDDA.includes("jour(s)")
                    ? (resultDDA = this.additionnerDureeEtHeure(resultDDA, training.duration))
                    : (resultDDA = this.additionnerHeures(resultDDA, training.duration))

                  resultByYear = this.additionnerHeures(resultByYear, training.duration)

                  if (training_documents > 0) {
                    resultDDADone.includes("jour(s)")
                      ? (resultDDADone = this.additionnerDureeEtHeure(resultDDADone, training.duration))
                      : (resultDDADone = this.additionnerHeures(resultDDADone, training.duration))
                  }
                } else {
                  resultNDDA = this.additionnerHeures(resultNDDA, training.duration)
                }

                allTrainings.push(training)
              })
              resultByYear.includes("jour(s)")
                ? (element.sum_year = resultByYear)
                : (element.sum_year = moment(resultByYear, "HH:mm:ss").format("HH[h]mm"))
            })

            collaborator.allTrainings = allTrainings

            // collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm")

            resultDDADone.includes("jour(s)")
              ? (collaborator.sum_DDA_hours_done = resultDDADone)
              : (collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm"))

            // if (max_documents_completed > 0) {
            //   collaborator.sum_DDA_hours_done = moment(resultDDADone, "HH:mm:ss").format("HH[h]mm")
            // } else {
            //   collaborator.sum_DDA_hours_done = "00h00"
            // }

            max_documents > 0
              ? (collaborator.percentage = Math.round((max_documents_completed / max_documents) * 100, 2))
              : (collaborator.percentage = 0)

            resultDDA.includes("jour(s)")
              ? (collaborator.sum_DDA_hours = resultDDA)
              : (collaborator.sum_DDA_hours = moment(resultDDA, "HH:mm:ss").format("HH[h]mm"))

            resultDDA.includes("jour(s)")
              ? (collaborator.sum_hours = this.additionnerDureeEtHeure(resultDDA, resultNDDA))
              : (collaborator.sum_hours = moment(this.additionnerHeures(resultDDA, resultNDDA), "HH:mm:ss").format(
                  "HH[h]mm",
                ))
          })
          this.agency.activeCollaborators = this.agency.collaborators.filter(collaborator => {
            return parseInt(collaborator.disabled) === 0
          })

          this.agency.inactiveCollaborators = this.agency.collaborators.filter(collaborator => {
            return parseInt(collaborator.disabled) === 1
          })

          this.obligatoryInformations = {
            value: 0,
            max: 0,
            percentage: 0,
            fields: [],
          }
          this.getObligatoryInformations()
          this.agency.establishments.forEach(etablishment => {
            this.etablishmentsOptions.push({
              id: etablishment.id,
              name: etablishment.city,
            })
          })
        }

        this.agency.creation_date_formated = moment(this.agency.creation_date).format("Do MMM YYYY")
        this.dataFetch = false

        this.countCandidates = this.agency.candidates.length
      } catch (e) {
        console.log(e)
      }
    },

    async fetchServices() {
      this.services = []
      this.nodeChart = []
      this.fetchServicesState = true
      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        let response

        response = await fetch(`${config.apiUri}/agencies/${idAgency}/services`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.services.forEach(element => {
            let constructChildren = []
            // element.members.map(async collaborator => {
            //   collaborator.avatarUrl = await this.getImg2(collaborator.lastname, collaborator.firstname)
            // })
            element.members.map(async member => {
              member.avatarUrl = await this.getImg2(member.lastname, member.firstname)

              constructChildren.push({
                name: `${member.lastname} ${member.firstname}`,
                avatarUrl: member.avatarUrl,
                description: "Collaborateur",
                avatarUrl: member.avatarUrl,
              })
            })

            let manager = [
              {
                name: `${element.lastname_manager} ${element.firstname_manager}`,
                description: "Responsable hiérarchique",
                children: constructChildren,
                avatarUrl: element.avatarUrl,
              },
            ]

            element.chartData = {
              name: `${element.name}`,
              description: "Service",
              children: manager,
            }

            this.services.push({
              id_service: element.id_service,
              id_service_manager: element.id_service_manager,
              name: element.name,
              id_manager: element.id_manager,
              lastname_manager: element.lastname_manager,
              firstname_manager: element.firstname_manager,
              complete_name_manager: `${element.lastname_manager} ${element.firstname_manager}`,
              number_members: 0,
              location: element.location,
              id_agency_etablishment: element.id_agency_etablishment,
              members: element.members,
              chartData: element.chartData,
            })

            this.fetchServicesMembers(element.id_service_manager, this.services.length - 1)
          })
        }
        await pause(3000)
        this.chartData = {
          name: this.agencyName,
          description: "Mon cabinet",
          children: this.nodeChart,
        }
        if (this.services.length > 0) {
          this.valueOrganization = 100
        } else {
          this.valueOrganization = 0
        }
        this.fetchServicesState = false
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchServicesMembers(idService, index) {
      this.fetchMembers = true
      await pause(1500)

      let headers = new Headers()
      let idAgency = this.$store.state.user.agency_id
      let response

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")

      response = await fetch(`${config.apiUri}/agencies/${idAgency}/services/${idService}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      })

      const data = await response.json()

      this.services[index].number_members = parseInt(data.count_members_total)

      let constructChildren = []
      data.members.map(async member => {
        constructChildren.push({
          name: `${member.lastname} ${member.firstname}`,
          contract: member.contract[0],
          description: "Collaborateur",
          avatarUrl: await this.getImg2(member.lastname, member.firstname),
        })
      })

      let manager = [
        {
          name: `${this.services[index].complete_name_manager}`,
          description: "Responsable hiérarchique",
          avatarUrl: await this.getImg2(this.services[index].lastname_manager, this.services[index].firstname_manager),

          children: constructChildren,
        },
      ]

      this.nodeChart.push({
        name: `${this.services[index].name}`,
        description: "Service",
        children: manager,
      })

      this.fetchMembers = false
    },
  },
}
</script>

<style>
.icon-medium {
  font-size: 1.2rem;
}

.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .v-data-table tr {
  text-align: center !important;
}

.theme--light.header-table th {

  font-size: 14px !important;
  text-align: center !important;
}

.theme--light.header-table td {
  text-align: center !important;
} */

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.hide-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.row-collaborators {
  max-width: 900px;
}

.row-etablishment {
  max-width: 450px;
}

.hover-overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease-in-out;
}

.avatar:hover .hover-overlay {
  opacity: 1;
}

.avatar-skeleton {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.overlay-text {
  color: white;
  font-size: 20px !important;
  text-align: center;
}

.bordered {
  border: 1.5px solid #e0e0e0;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 1;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -57px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.test {
  /*  remove bg color */
  background-color: transparent !important;
}
</style>
