<!---   2 boutons / notes de frais + export décla sociales ---->
<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="12" md="3">
        <v-dialog max-width="1300" v-model="dialogExportCost">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[0].hovered = true"
              @mouseleave="tasks[0].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{ lastMonth }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[0].icon,
                    'fa-5x',
                    tasks[0].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[0].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      {{ countCostsValidatedLastMonth() }} notes validées
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Export des notes de frais du cabinet {{ agency }} </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="yearFiltered"
                            :items="years"
                            label="Année"
                            outlined
                            dense
                            clearable
                            item-text="text"
                            item-value="year"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="monthFiltered"
                            :items="months"
                            label="Mois"
                            outlined
                            dense
                            clearable
                            item-text="month"
                            item-value="id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="downloadCosts"
                        >Exporter</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-dialog max-width="1300" v-model="dialogExportSocialStatements">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[1].hovered = true"
              @mouseleave="tasks[1].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{
                currentMonth
              }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[1].icon,
                    'fa-5x',
                    tasks[1].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[1].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      a récupérer
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Export des déclarations sociales du cabinet {{ agency }} </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="yearFiltered"
                            :items="years"
                            label="Année"
                            outlined
                            dense
                            clearable
                            item-text="text"
                            item-value="year"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="monthFilteredSocialStatement"
                            :items="months"
                            label="Mois"
                            outlined
                            dense
                            clearable
                            item-text="month"
                            item-value="id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-data-table
                        :items="socialStatements"
                        :headers="headers"
                        hide-default-footer
                        :items-per-page="-1"
                      >
                        <template v-slot:no-data> Aucune donnée disponible </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            icon
                            color="EoleError"
                            target="_blank"
                            @click="downloadFile(item.path, item.name, item.extension)"
                          >
                            <i class="fa-solid fa-file-pdf icon-medium"></i>
                          </v-btn>

                          <v-btn icon target="_blank" @click="openPreview(item)">
                            <i class="fa-solid fa-eye icon-medium"></i>
                          </v-btn>
                        </template>
                        <template v-slot:item.upload_datetime="{ item }">
                          {{ getFormatDate(item.upload_datetime) }}
                        </template>
                      </v-data-table>
                      <v-dialog v-model="dialogPreview" max-width="800px">
                        <v-card>
                          <v-card-text>
                            <iframe
                              :src="`https://${selectedPreviewItem.url}`"
                              frameborder="0"
                              width="100%"
                              height="500px"
                            ></iframe>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="generateAndDownloadZipOfficeStatement(socialStatements)"
                        >Exporter la totalité des fichiers</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>

      <v-col cols="12" md="3">
        <v-dialog max-width="1300" v-model="dialogExportMonthlyStatus">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-on="on"
              v-bind="attrs"
              outlined
              elevation="3"
              rounded="100"
              @mouseover="tasks[2].hovered = true"
              @mouseleave="tasks[2].hovered = false"
              class="align-center rounded-xl"
            >
              <v-card-subtitle class="EoleYellow--text text-center font-weight-bold">{{
                currentMonth
              }}</v-card-subtitle>

              <v-card-text class="text-center pa-5 mt-n5">
                <i
                  :class="[
                    'fa-solid ',
                    tasks[2].icon,
                    'fa-5x',
                    tasks[2].hovered ? 'EoleYellow--text' : 'EoleBlue--text',
                  ]"
                ></i>
                <p class="mt-5">{{ tasks[2].name }}</p>
                <v-row class="mt-n8 mb-n8">
                  <v-col cols="12" md="12">
                    <v-card-subtitle
                      class="text-center text-h5 EoleBlue--text font-weight-bold"
                      style="font-size: 0.9rem !important"
                    >
                      a récupérer
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Export des États mensuels du cabinet {{ agency }} </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="yearFiltered"
                            :items="years"
                            label="Année"
                            outlined
                            dense
                            clearable
                            item-text="text"
                            item-value="year"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="monthFilteredSocialStatement"
                            :items="months"
                            label="Mois"
                            outlined
                            dense
                            clearable
                            item-text="month"
                            item-value="id"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-data-table :items="status" :headers="headers" hide-default-footer :items-per-page="-1">
                        <template v-slot:no-data> Aucune donnée disponible </template>
                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            icon
                            color="EoleError"
                            target="_blank"
                            @click="downloadFile(item.path, item.name, item.extension)"
                          >
                            <i class="fa-solid fa-file-pdf icon-medium"></i>
                          </v-btn>

                          <v-btn icon target="_blank" @click="openPreview(item)">
                            <i class="fa-solid fa-eye icon-medium"></i>
                          </v-btn>
                        </template>
                        <template v-slot:item.upload_datetime="{ item }">
                          {{ getFormatDate(item.upload_datetime) }}
                        </template>
                      </v-data-table>
                      <v-dialog v-model="dialogPreview" max-width="800px">
                        <v-card>
                          <v-card-text>
                            <iframe
                              :src="`https://${selectedPreviewItem.url}`"
                              frameborder="0"
                              width="100%"
                              height="500px"
                            ></iframe>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="generateAndDownloadZipOfficeStatement(status)"
                        >Exporter la totalité des fichiers</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row> </v-row>
  </div>
</template>

<script>
import config from "@/views/config/config"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
import FileSaver from "file-saver"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import moment from "moment"
import "moment-business-days"

import * as XLSX from "xlsx"
import Holidays from "date-holidays"
import { getCookie } from "@/utils/cookies"

const hd = new Holidays()
hd.init("FR")
moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "OfficeSocial",
  props: {
    documents: {
      type: Array,
      required: true,
    },
    monthlyStatus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogExportMonthlyStatus: false,
      dialogExportSocialStatements: false,
      headers: [
        { text: "Nom", value: "name" },
        { text: "Date d'enregistrement", value: "upload_datetime" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      accountsRegistration: [],
      selectedFilePayslipPreview: "",
      previewPayslipDialog: false,
      dialogImportSocialStatement: false,
      dialogImportSocialPayslip: false,
      currentYear: moment().year(),
      idCurrentMonth: moment().month(),
      files: [],
      filesPayslip: [],
      previewDialog: false,
      selectedFilePreview: "", // URL de prévisualisation du fichier sélectionné
      rules: {
        required: value => !!value || "Champ requis.",
      },
      currentMonth: moment().format("MMMM").toUpperCase(),
      lastMonth: moment().subtract(1, "months").format("MMMM").toUpperCase(),
      tasks: [
        {
          id: 1,
          name: "Notes de frais",
          done: false,
          icon: "fa-piggy-bank",
          hovered: false,
        },

        {
          id: 2,
          name: "Déclarations sociales",
          done: false,
          icon: "fa-upload",
          hovered: false,
        },
        {
          id: 2,
          name: "États mensuels",
          done: false,
          icon: "fa-upload",
          hovered: false,
        },
      ],
      agency: localStorage.getItem("agency"),
      config: config,
      costs: [],
      dialogExportCost: false,
      yearFiltered: moment().year(),
      monthFiltered: moment().month() - 1,
      monthFilteredSocialStatement: moment().month(),
      years: [],
      months: [
        {
          id: 0,
          month: "Janvier",
        },
        {
          id: 1,
          month: "Février",
        },
        {
          id: 2,
          month: "Mars",
        },
        {
          id: 3,
          month: "Avril",
        },
        {
          id: 4,
          month: "Mai",
        },
        {
          id: 5,
          month: "Juin",
        },
        {
          id: 6,
          month: "Juillet",
        },
        {
          id: 7,
          month: "Août",
        },
        {
          id: 8,
          month: "Septembre",
        },
        {
          id: 9,
          month: "Octobre",
        },
        {
          id: 10,
          month: "Novembre",
        },
        {
          id: 11,
          month: "Décembre",
        },
      ],
      worked_days: [],
      monthFilter: moment().month() - 1,
      publicHolidays: [],
      count: {
        totalCollaborators: 0,
        totalCollaboratorsConfirmed: 0,
      },
      selectedPreviewItem: {
        id: null,
        url: null,
      },
      dialogPreview: false,
    }
  },
  computed: {
    status() {
      // Filtrer par année si yearFiltered est défini
      let filteredByYear = this.yearFiltered
        ? this.monthlyStatus.filter(doc => doc.year == this.yearFiltered)
        : this.monthlyStatus

      // Filtrer par mois si monthFilter est défini, en prenant en compte les mois indexés à partir de 0
      let filteredByMonth =
        this.monthFilteredSocialStatement !== null
          ? filteredByYear.filter(doc => {
              // On s'attend à ce que monthFilter soit indexé à partir de 0, donc on l'ajuste ici si nécessaire
              return doc.months.some(month => month.month == this.monthFilteredSocialStatement)
            })
          : filteredByYear

      // Transformer le format pour le v-data-table
      let transformed = []
      filteredByMonth.forEach(doc => {
        doc.months.forEach(month => {
          if (month.month == this.monthFilteredSocialStatement) {
            month.files.forEach(file => {
              transformed.push({ ...file, year: doc.year, month: month.month })
            })
          }
        })
      })

      return transformed
    },
    socialStatements() {
      // Filtrer par année si yearFiltered est défini
      let filteredByYear = this.yearFiltered
        ? this.documents.filter(doc => doc.year == this.yearFiltered)
        : this.documents

      // Filtrer par mois si monthFilter est défini, en prenant en compte les mois indexés à partir de 0
      let filteredByMonth =
        this.monthFilteredSocialStatement !== null
          ? filteredByYear.filter(doc => {
              // On s'attend à ce que monthFilter soit indexé à partir de 0, donc on l'ajuste ici si nécessaire
              return doc.months.some(month => month.month == this.monthFilteredSocialStatement)
            })
          : filteredByYear

      // Transformer le format pour le v-data-table
      let transformed = []
      filteredByMonth.forEach(doc => {
        doc.months.forEach(month => {
          if (month.month == this.monthFilteredSocialStatement) {
            month.files.forEach(file => {
              transformed.push({ ...file, year: doc.year, month: month.month })
            })
          }
        })
      })

      return transformed
    },
    filteredCollabsInfo() {
      // Vérifie que `test` est défini et que `monthFilter` a une valeur valide.
      if (!this.worked_days || this.monthFilter == null) {
        return []
      }

      // Trouver les données correspondant au mois sélectionné dans `test`
      const monthData = this.worked_days.find(data => data.month == this.monthFilter)

      if (!monthData) {
        return []
      }

      // Filtrer et traiter les données des collaborateurs pour le mois sélectionné
      const collabsInfo = monthData.collabs_info.map(collaborator => {
        console.log("coll", collaborator)
        // Traiter `worked_days` pour chaque collaborateur
        const workedDaysProcessed = collaborator.worked_days_history.flatMap(workedDay => {
          if (workedDay.days == null) return [] // Gérer le cas où `days` est null ou undefined

          // Analyser la chaîne JSON des jours travaillés/absences
          try {
            const days = JSON.parse(workedDay.days)
            // Filtrer les jours qui correspondent au type et à l'état désirés, si nécessaire
            return days.filter(day => {
              // Exemple de filtrage supplémentaire, si nécessaire
              return true // Remplacer par la condition de filtrage appropriée
            })
          } catch (error) {
            console.error("Erreur lors de l'analyse de JSON pour les jours travaillés", error)
            return []
          }
        })

        return {
          ...collaborator,
          worked_days_processed: workedDaysProcessed,
        }
      })

      // Filtrer par nom de famille et prénom si le filtre utilisateur est spécifié
      const filteredCollabs = this.userFilter
        ? collabsInfo.filter(
            collaborator =>
              collaborator.lastname.toLowerCase().includes(this.userFilter.toLowerCase()) ||
              collaborator.firstname.toLowerCase().includes(this.userFilter.toLowerCase()),
          )
        : collabsInfo

      return filteredCollabs.filter(collaborator => collaborator.worked_days_processed.length > 0)
    },
  },
  watch: {},
  beforeDestroy() {
    this.files.forEach(file => {
      if (file.preview) {
        URL.revokeObjectURL(file.preview)
      }
    })
  },
  methods: {
    async downloadFile(path, documentName) {
      let blobConstruct = null
      let headers = new Headers()

      headers.append("Content-Type", "image/jpeg")
      headers.append("Accept", "image/jpeg")
      headers.append("Origin", "*")

      try {
        const response = await fetch(`${config.apiUri}/php/pages/download.php?path=${path}`, {
          method: "GET",
          headers: headers,
        })

        blobConstruct = await response.blob()

        FileSaver.saveAs(blobConstruct, `${documentName}`)
      } catch (e) {
        console.log(e)
      }
    },
    openPreview(item) {
      this.selectedPreviewItem = item // Stocke l'item sélectionné
      this.dialogPreview = true // Ouvre la boîte de dialogue
    },
    closePreview() {
      this.selectedPreviewItem = null // Réinitialise l'item sélectionné
    },
    isPreviewOpen(item) {
      return this.selectedPreviewItem && this.selectedPreviewItem.id === item.id
    },
    getFormatDate(date) {
      return moment(date).format("dddd Do MMMM YYYY [à] HH:mm")
    },
    exportOfficeSocialStatement() {},
    getFormattedName(lastname, firstname) {
      lastname = lastname.toLowerCase().replace(/ /g, "")
      firstname = firstname.toLowerCase().replace(/ /g, "-")
      return `${lastname}-${firstname}`
    },
    verifyRegistration(file) {
      console.log(file)
      return this.accountsRegistration.find(account => {
        let findItem = `${account.lastname}_${account.firstname}`.toLowerCase()
        console.log(findItem)
        return findItem == file.uname
      })
    },
    getIdAccount(file) {
      return this.accountsRegistration.find(account => {
        let findItem = `${account.lastname}_${account.firstname}`.toLowerCase()
        return findItem == file.uname
      })
    },
    async getRegistration() {
      const idAgency = this.$store.state.user.id_agency
      const res = await fetch(`${config.apiUri}/agencies/${idAgency}/registrations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("jwt")}`,
        },
      })
      const data = await res.json()

      this.accountsRegistration = data
      console.log(data)
    },

    countCostsValidatedLastMonth() {
      let count = 0
      const lastMonthId = moment().month()
      const currentYear = moment().year()

      this.costs.forEach(cost => {
        const lastMonth = moment().get("month") - 1
        if (cost.idMonth == lastMonthId && cost.year == currentYear && cost.state == 3) {
          count++
        }
      })

      return count
    },
    countConfirmWorkingDays() {
      let count = 0
      //get current month id from moment -1
      let currentMonth = moment().month() - 1
      console.log("currentMonth", currentMonth)
      this.count.totalCollaboratorsConfirmed = 0

      this.worked_days.forEach(wk => {
        if (wk.month == currentMonth) {
          this.count.totalCollaborators = wk.collabs_info.length
          console.log(wk)
          wk.collabs_info.forEach(collab => {
            if (collab.worked_days_history.length > 0) {
              this.count.totalCollaboratorsConfirmed++
            }
          })
        }
      })
      return count
    },
    getMonthName(month) {
      return moment().month(month).format("MMMM")
    },
    // Fonction pour générer des styles de bordure
    getBorderStyle() {
      return {
        top: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
      }
    },

    // Fonction pour appliquer le style à une cellule spécifique
    setCellStyle(ws, cellRef, style) {
      ws[cellRef] = ws[cellRef] || {}
      ws[cellRef].s = { ...ws[cellRef].s, ...style }
    },

    // Est retiré !!
    async generateAndDownloadZipOfficeStatement(documents) {
      const zip = new JSZip()

      function createPath(path) {
        // Assurez-vous de définir `config.ged`, `config.apiUri`, `config.path` correctement
        let ur = `${config.apiUri}/php/pages/download.php?path=${path}`
        console.log(ur)
        return ur
      }

      const downloadAndAddToZipOfficeStatement = async document => {
        const url = createPath(document.path)
        const response = await fetch(url)
        const pdfBlob = await response.blob()
        const fileName = document.name // Utilisez `document.name` pour obtenir directement le nom du fichier
        zip.file(fileName, pdfBlob)
      }

      await Promise.all(documents.map(downloadAndAddToZipOfficeStatement))

      const zipBlob = await zip.generateAsync({ type: "blob" })

      // Utilisez votre méthode de téléchargement préférée, par exemple file-saver
      saveAs(zipBlob, "documents.zip")
    },

    async downloadCosts() {
      const agencyId = this.$store.state.user.id_agency

      const date = `${this.yearFiltered}-${this.monthFiltered + 1}`

      try {
        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/costs/${date}/export`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
            // Ajoutez ici d'autres en-têtes si nécessaire, par exemple pour l'authentification
          },
        })

        if (!response.ok) {
          throw new Error("Problème lors du téléchargement du fichier")
        }

        const blob = await response.blob()

        // Crée un URL pour le blob
        const downloadUrl = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = downloadUrl

        // Définissez le nom de fichier pour le téléchargement
        const filename = `costs_${date}.zip`
        a.setAttribute("download", filename)
        document.body.appendChild(a)
        a.click()

        // Nettoie l'objet URL après le téléchargement
        window.URL.revokeObjectURL(downloadUrl)
        a.remove()
      } catch (error) {
        console.error("Erreur lors du téléchargement :", error)
        alert("Erreur lors du téléchargement des documents.")
      }
    },
    async fetchCosts() {
      this.costs = []

      try {
        let headers = new Headers()
        let idAgency = this.$store.state.user.id_agency

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        let response = await fetch(`${config.apiUri}/agencies/${idAgency}/costs`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          // Filtrage initial pour les managers
          let filteredUsers = data.costs

          filteredUsers.forEach(user => {
            user.costs.forEach(yearObj => {
              yearObj.months.forEach(monthObj => {
                let totalMonth = 0
                monthObj.costs.forEach(item => {
                  // Traitement de chaque item de coût
                  item.designation = item.designation_category
                  item.price = parseFloat(item.price)
                  item.documents = JSON.parse(item.documents)
                  item.data = item.data != null ? JSON.parse(item.data) : []
                  item.date_display = moment(item.date).format("DD/MM/YYYY")
                  item.date_cost_display = moment(item.date_cost).format("DD/MM/YYYY")
                  item.month_display = moment(item.date_cost).format("MMMM")
                  item.year = parseInt(moment(item.date_cost).format("YYYY"))

                  totalMonth += item.price
                })

                // Conditions pour ajouter dans costsOrdered ou costsOrderedHistory
                let conditionToAdd = parseInt(monthObj.state) === 1
                // let targetArray = conditionToAdd ? this.costsOrdered : this.costsOrderedHistory

                if (conditionToAdd || parseInt(monthObj.state) === 2 || parseInt(monthObj.state) === 3) {
                  this.costs.push({
                    // Ajout des coûts filtrés avec les informations utilisateur
                    year: yearObj.year,
                    id_cost_manager: parseInt(monthObj.id_cost_manager),
                    idMonth: monthObj.costs[0] ? parseInt(moment(monthObj.costs[0].date_cost).format("MM")) : 0,
                    month: monthObj.costs,
                    user: {
                      id: user.id,
                      firstname: user.firstname,
                      lastname: user.lastname,
                      complete_name: `${user.firstname} ${user.lastname}`,
                    },
                    monthPrice: totalMonth,
                    state: parseInt(monthObj.state),
                    months: monthObj.costs[0] ? moment(monthObj.costs[0].date_cost).format("MMMM") : "",
                  })
                }
              })
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
  },

  async created() {
    this.getRegistration()

    this.fetchCosts()
    alert(JSON.stringify(this.documents))
    //generer année de à année en cours
    let currentYear = new Date().getFullYear()
    for (let i = 2021; i <= currentYear; i++) {
      this.years.push({ year: i, text: i.toString() })
    }
  },
}
</script>

<style>
.import .v-btn--icon.v-size--default {
  height: 64px !important;
  width: 64px !important;
}
</style>
