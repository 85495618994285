<template>
  <v-container fluid>
    <custome-loader v-if="loadingCandidates"></custome-loader>
    <v-row>
      <v-col cols="12" md="3" class="">
        <v-card flat outlined>
          <v-card-text class="mb-n5">
            <v-text-field outlined label="Rechercher un viver" dense v-model="filterCandidate"></v-text-field>
          </v-card-text>
          <v-card-text class="no-padding">
            <v-treeview
              dense
              :active.sync="active"
              :items="items"
              open-on-click
              :open.sync="open"
              activatable
              color="EoleBlue"
              transition
            >
              <template v-slot:prepend="{ item }">
                <v-avatar v-if="!item.children" size="40" color="EoleBlue" class="elevation-7 my-2 mr-2">
                  <span class="white--text">{{ item.firstname[0] + item.lastname[0] }}</span>
                </v-avatar>
                {{ item.lastname }} {{ item.firstname }}
              </template>
            </v-treeview>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end no-padding align-center mt-4 mr-2 EoleBlue--text">
            <!-- <p class="text-caption">{{ candidates.length +   }} utilisateurs trouvés</p> -->
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="9" class="">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="!selected" align="center" class="text-left">
            <v-col>
              <v-alert outlined type="info" color="EoleBlue" prominent icon="mdi-account" text dense
                >Veuillez selectionner un collaborateur à afficher dans cette fenêtre.
              </v-alert>
            </v-col>
          </v-row>
          <v-card v-else :key="selected.id" class="mx-auto" flat style="background-color: transparent !important">
            <v-card outlined class="text-center">
              <v-card-text class="profilBannerAccount" style="height: 100px">
                <v-row>
                  <v-col cols="12" md="6" class="text-left"> </v-col>
                  <v-col cols="12" md="6" class="text-right"> </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="profilSeparator">
                <v-divider></v-divider>
              </v-card-text>
              <v-card-text class="profilBadge">
                <template v-slot:badge>
                  <i v-if="selected.dbl === 0" class="fa-solid fa-check"></i>
                  <i v-else class="fa-solid fa-ban"></i>
                </template>

                <v-avatar ref="avatar" size="96" color="EoleBlue">
                  <span class="white--text text-h3">{{ selected.firstname[0] + selected.lastname[0] }}</span>
                </v-avatar>
              </v-card-text>
              <v-card-text class="profilTabs mb-15">
                <v-row>
                  <v-col cols="12" md="9">
                    <v-tabs
                      color="EoleYellow"
                      class="text-left mt-5"
                      v-model="tab"
                      style="box-shadow: none !important"
                      background-color="transparent"
                      :show-arrows="false"
                    >
                      <v-tab href="#tab-1"> Informations </v-tab>

                      <v-tab href="#tab-2"> Documents</v-tab>
                    </v-tabs>
                  </v-col>

                  <v-col cols="12" md="3" class="mt-6">
                    <v-menu left right transition="slide-x-transition" close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" style="background-color: white">
                          <i class="fa-solid fa-ellipsis-vertical"></i>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="selected.dbl === 0 ? editItem(selected) : editedItemDisabled(selected)"
                          link
                        >
                          <v-list-item-title>
                            <v-icon left>mdi-account-edit</v-icon>Modifier le profil du candidat
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item link @click="updateCandidateState(3)">
                          <v-list-item-title>
                            <i class="fa-solid fa-handshake-angle mr-2 fa-1x" style="font-size: 1.2rem"></i>Recevoir le
                            candidat
                          </v-list-item-title>
                        </v-list-item>

                        <v-divider class="my-2"></v-divider>

                        <v-dialog max-width="1600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" class="EoleGreen--text" link>
                              <v-list-item-title>
                                <i
                                  class="fa-solid fa-file-circle-check EoleGreen--text mr-2 fa-1x"
                                  style="font-size: 1.2rem"
                                ></i
                                >Accepter la candidature
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                            <v-card-text style="background-color: #f1f4f9">
                              <v-row>
                                <v-col cols="12" md="3" class="no-padding ml-n2">
                                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                    <v-card-title>
                                      <v-img
                                        :src="require('@/assets/images/logos/anavel_logo.png')"
                                        max-height="150px"
                                        max-width="120px"
                                        alt="logo"
                                        contain
                                      ></v-img>
                                    </v-card-title>
                                    <v-card-title class="text-center justify-center muller-capitalized"
                                      >Mes Services Assurance</v-card-title
                                    >
                                    <v-card-subtitle class="text-center justify-center"
                                      >Un logiciel ANAVEL</v-card-subtitle
                                    >
                                    <v-card-text class="no-padding">
                                      <v-img
                                        :src="require('@/assets/images/logos/layer.png')"
                                        alt="logo"
                                        width="300px"
                                        class=""
                                      >
                                      </v-img>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <v-col cols="12" md="9" class="no-padding d-flex">
                                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                                    <v-card-title>
                                      <span class="text-h5">Promesse d'embauche</span>
                                    </v-card-title>
                                    <v-row>
                                      <v-col style="font-size: 1.1rem">
                                        Madame, Monsieur, Suite à notre entretien en date du
                                        <div class="text-box mt-5">
                                          <v-text-field
                                            outlined
                                            dense
                                            type="date"
                                            v-model="editedPromise.last_appointment"
                                          ></v-text-field>
                                        </div>
                                        , nous avons le plaisir de vous proposer un contrat de travail à durée au sein
                                        de notre entreprise. Vous êtes libre de nous donner votre accord dans un délai
                                        de 7 jours pour accepter notre offre de contrat de travail décrit ci-après.
                                        L’offre de contrat concerne un poste se situant dans notre agence de située au à
                                        compter, au plus tard, du à 9:00 heures, en qualité dede la convention
                                        collective des agents généraux d’assurance (brochure 3115) dont vous trouverez
                                        la fiche de poste ci-dessous. Pour votre parfaite information, nous serions
                                        susceptibles de vous accueillir avant cette date si vous arrivez à vous rendre
                                        disponible.
                                      </v-col>
                                    </v-row>

                                    <v-card-text>
                                      <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-select
                                            dense
                                            prepend-inner-icon="mdi-cogs"
                                            outlined
                                            :items="typeContracts"
                                            label="Type de contrat"
                                            item-text="designation"
                                            return-object
                                            v-model="editedPromise.type_contract"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-select
                                            dense
                                            prepend-inner-icon="mdi-cogs"
                                            outlined
                                            :items="typeEmployments"
                                            item-text="designation"
                                            return-object
                                            label="Type d'emploi"
                                            v-model="editedPromise.type_employment"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            outlined
                                            label="Date du dernier entretien"
                                            dense
                                            type="date"
                                            v-model="editedPromise.last_appointment"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            outlined
                                            label="Nombre de jours de repos"
                                            dense
                                            v-model="editedPromise.recovery_working_time"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-select
                                            :items="getActiveAgencies"
                                            dense
                                            prepend-inner-icon="mdi-cogs"
                                            outlined
                                            label="Site d'embauche"
                                            item-text="city"
                                            return-object
                                            v-model="editedPromise.workplace"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            outlined
                                            label="Date de démarrage du contrat"
                                            dense
                                            type="date"
                                            v-model="editedPromise.start_date"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-select
                                            dense
                                            prepend-inner-icon="mdi-cogs"
                                            outlined
                                            :items="typeJobs"
                                            item-text="designation"
                                            return-object
                                            label="Poste"
                                            v-model="editedPromise.type_job"
                                          >
                                          </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-select
                                            dense
                                            prepend-inner-icon="mdi-cogs"
                                            outlined
                                            :items="typeQualifications"
                                            item-text="designation"
                                            return-object
                                            label="Classification du poste"
                                            v-model="editedPromise.type_qualification"
                                          ></v-select>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            label="Rémunération brut (en) €"
                                            outlined
                                            dense
                                            type="number"
                                            @input="updateWrittenSalary"
                                            v-model="editedPromise.mensual_salary"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                          <v-text-field
                                            label="En lettre"
                                            outlined
                                            dense
                                            v-model="editedPromise.mensual_salary_letter"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <!-- Période 1,2,3 checkbox | euros, lettres  -->
                                      <v-row v-for="(period, index) in editedPromise.rows" v-bind:key="index">
                                        <v-col cols="12" sm="6" md="1" class="mt-n3" style="max-width: 50px">
                                          <v-checkbox label=""></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3">
                                          <v-text-field
                                            outlined
                                            :label="`Période ${index + 1}`"
                                            dense
                                            v-model="period.date"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                          <v-text-field
                                            label="Rémunération brut (en) €"
                                            outlined
                                            dense
                                            v-model="period.amount"
                                            @input="updateWrittenForm(index)"
                                          ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="4">
                                          <v-text-field
                                            label="En lettre"
                                            outlined
                                            dense
                                            :value="period.letter"
                                            readonly
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col cols="12" md="12">
                                          <v-switch label="Période d'essai" inset></v-switch>
                                          <v-switch label="Renouvelable" inset></v-switch>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                    <v-spacer></v-spacer>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        class="white--text"
                                        color="EoleBlue"
                                        @click="
                                          editedIndex = -1
                                          dialog = false
                                        "
                                      >
                                        Annuler
                                      </v-btn>
                                      <v-btn class="white--text" color="EoleGreen" @click="getPdf">Prévisualiser</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-dialog>

                        <v-list-item class="EoleError--text" link @click="updateCandidateState(4)">
                          <v-list-item-title>
                            <i
                              class="fa-solid fa-file-circle-xmark EoleError--text mr-2 fa-1x"
                              style="font-size: 1.2rem"
                            ></i
                            >Refuser la candidature
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-text class="mt-16">
                <v-row>
                  <v-col cols="12" md="6" class="text-left">
                    <h3 class="text-h5">
                      {{ selected.firstname }} {{ selected.lastname }}
                      <v-tooltip v-model="showEmail" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text ml-2"
                            fab
                            color="EoleBlue"
                            :href="`mailto:${selected.email}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-envelope fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.email }}</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text"
                            fab
                            color="EoleBlue"
                            :href="`tel:${selected.phone_number}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-phone-volume fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>{{ selected.phone_number }}</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="white--text"
                            fab
                            color="EoleBlue"
                            :href="`tel:${selected.phoneNumber}`"
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <i class="fa-solid fa-location-dot fa-icone"></i
                          ></v-btn>
                        </template>
                        <span>65 avenue de la libération</span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                </v-row>

                <v-tabs-items v-model="tab" style="background-color: transparent !important">
                  <v-tab-item :value="'tab-1'">
                    <v-row class="mt-n2 pa-5">
                      <v-col cols="12" md="3" class="d-flex w-full flex flex-column">
                        <v-card outlined class="rounded-xl">
                          <v-card-text class="pa-5">
                            <v-row>
                              <v-col cols="12" md="12" class="text-left">
                                Email :
                                <strong> {{ selected.email }}</strong>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="12" md="12" class="text-left mt-n5">
                                Téléphone :
                                <strong> {{ selected.phone_number }}</strong>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row v-if="selected.files.length > 0">
                      <v-col cols="12" md="12">
                        <iframe
                          :src="selected.files[0].url"
                          frameborder="5"
                          class="w-full"
                          height="700px"
                          allowfullscreen
                        ></iframe>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item :value="'tab-2'">
                    <v-row>
                      <v-col cols="12" md="12" class="d-flex">
                        <v-dialog max-width="1300" v-model="dialogImportFile">
                          <template v-slot:activator="{ on, attrs }">
                            <v-card
                              v-on="on"
                              v-bind="attrs"
                              outlined
                              elevation="3"
                              rounded="100"
                              class="align-center rounded-xl"
                              @click="selectedCandidate = selected"
                            >
                              <v-card-title class="text-h5">Importer un document</v-card-title>
                            </v-card>
                          </template>
                          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                            <v-card-text style="background-color: #f1f4f9">
                              <v-row>
                                <v-col cols="12" md="3" class="no-padding ml-n2">
                                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                    <v-card-title>
                                      <v-img
                                        :src="require('@/assets/images/logos/anavel_logo.png')"
                                        max-height="150px"
                                        max-width="120px"
                                        alt="logo"
                                        contain
                                      ></v-img>
                                    </v-card-title>
                                    <v-card-title class="text-center justify-center muller-capitalized"
                                      >Mes Services Assurance</v-card-title
                                    >
                                    <v-card-subtitle class="text-center justify-center"
                                      >Un logiciel ANAVEL</v-card-subtitle
                                    >
                                    <v-card-text class="no-padding">
                                      <v-img
                                        :src="require('@/assets/images/logos/layer.png')"
                                        alt="logo"
                                        width="300px"
                                        class=""
                                      >
                                      </v-img>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <v-col cols="12" md="9" class="no-padding d-flex">
                                  <v-card
                                    class="rounded-r-xl flex-fill flex d-flex flex-column"
                                    flat
                                    :loading="loadingSend"
                                  >
                                    <v-card-title>
                                      <span class="text-h5">Ajouter des documents</span>
                                    </v-card-title>

                                    <v-card-text>
                                      <v-card
                                        link
                                        outlined
                                        @dragover.prevent
                                        v-model="files"
                                        @click="openFileExplorer"
                                        class="drop-zone flex d-flex flex-column justify-center text-center"
                                      >
                                        <div>
                                          <v-card-text class="EoleBlue--text">
                                            <v-icon size="64" color="EoleBlue">mdi-cloud-upload</v-icon>
                                          </v-card-text>
                                          <v-card-text><small>Extension de fichier accepté : </small> </v-card-text>
                                          <v-card-text class="EoleBlue--text">
                                            Cliquez pour importer un fichier ici</v-card-text
                                          >
                                        </div>
                                      </v-card>
                                      <v-dialog v-model="previewDialog" max-width="800px">
                                        <v-card>
                                          <v-card-title class="text-h5">Prévisualisation PDF</v-card-title>
                                          <v-card-text>
                                            <v-container>
                                              <v-row>
                                                <v-col cols="12">
                                                  <iframe
                                                    :src="selectedFilePreview"
                                                    width="100%"
                                                    height="500px"
                                                  ></iframe>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card-text>
                                          <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="closePreview">Fermer</v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-dialog>
                                    </v-card-text>
                                    <v-card-text>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          md="3"
                                          v-for="(file, index) in files"
                                          class="flex d-flex flex-column"
                                          v-bind:key="index"
                                        >
                                          <v-card
                                            class="rounded-xl text-center"
                                            elevation="3"
                                            @click="previewFile(file)"
                                            min-height="150px"
                                            max-height="150px"
                                          >
                                            <v-card-text class="text-center">
                                              <i class="fa-solid fa-file-pdf fa-2x EoleError--text"></i>
                                            </v-card-text>
                                            <v-card-subtitle>
                                              {{ file.name }}
                                            </v-card-subtitle>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>

                                    <v-spacer></v-spacer>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn elevation="0" @click="uploadFile" :loading="loadingSend">Envoyer</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-data-table
                          no-data-text="Aucun document disponible"
                          full-width
                          :headers="headersFiles"
                          :items="selected.files"
                          item-key="id"
                          class="elevation-1 v-data-table-file"
                          :items-per-page="-1"
                          disable-pagination
                          hide-default-footer
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              icon
                              color="EoleError"
                              target="_blank"
                              @click="downloadFile(selected.id, item.id)"
                              :disabled="item.url == null"
                            >
                              <i class="fa-solid fa-file-pdf icon-medium"></i>
                            </v-btn>

                            <v-btn @click="openPreviewFile(item)" icon target="_blank" :disabled="item.url == null">
                              <i class="fa-solid fa-eye icon-medium"></i>
                            </v-btn>
                            <v-menu right transition="slide-x-transition" close-on-content-click>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" style="" class="justify-center text-center">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item @click="openFileExplorer(item.id, item.name)">
                                  <v-list-item-title>
                                    <div><v-icon left>mdi-download</v-icon>Modifier</div>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import Loading from "vue-loading-overlay"
import CustomeLoader from "@/layouts/components/LoaderCustom.vue"
import moment from "moment"
moment.locale("fr")

import { getCookie } from "@/utils/cookies"
const writtenNumber = require("written-number")

writtenNumber.defaults.lang = "fr"

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  props: {
    establishments: {
      type: Array,
    },
    agency: {
      type: Object,
    },
  },
  data: () => ({
    editedPromise: {
      agency: "",
      agency_street: "",
      agency_zip: "",
      agency_city: "",
      lastname_candidate: "",
      firstname_candidate: "",
      type_contract: {},
      type_employment: {},
      last_appointment: "",
      recovery_working_time: "",
      workplace: {},
      start_date: "",
      type_job: {},
      type_qualification: {},
      mensual_salary: "",
      mensual_salary_letter: "",
      rows: [
        { date: moment().year(), amount: "", amountWritten: "" },
        { date: moment().year() + 1, amount: "", amountWritten: "" },
        { date: moment().year() + 2, amount: "", amountWritten: "" },
      ],
    },
    typeEmployments: [],
    typeContracts: [],
    typeJobs: [],
    typeQualifications: [],

    periods: [
      { date: moment().year(), amount: "", amountWritten: "" },
      { date: moment().year() + 1, amount: "", amountWritten: "" },
      { date: moment().year() + 2, amount: "", amountWritten: "" },
    ],
    selectedCandidate: {},
    loadingSend: false,
    selectedFilePreview: "",
    previewDialog: false,
    headersFiles: [
      {
        text: "Nom du fichier",
        align: "left",
        sortable: true,
        value: "name",
      },

      {
        text: "Actions",
        value: "actions",
      },
    ],
    filterCandidate: "",
    loadingCandidates: false,
    candidates: [],
    candidatesRefused: [],
    candidatesWorkInProgress: [],
    candidatesToContact: [],
    candidatesUnavailableCount: 0,
    candidatesEnabledCount: 0,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    active: [],
    open: [],
    tab: "tab-1",
    files: [],
  }),

  computed: {
    getActiveAgencies() {
      return this.establishments.filter(agency => agency.in_activity == 1)
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau candidat" : "Modification pour "
    },
    items() {
      return [
        {
          id: 1,
          name: `A contacter`,
          children: this.candidatesToContact,
          open: false,
        },
        {
          id: 1,
          name: `En cours de recrutement`,
          children: this.candidatesWorkInProgress,
          open: false,
        },
        {
          id: 2,
          name: `Mon vivier actif`,
          children: this.candidates,
          open: false,
        },
        {
          id: 3,
          name: `Candidatures refusées`,
          children: this.candidatesRefused,
          open: false,
        },

        {
          id: 4,
          name: `Candidatures acceptées`,
          children: this.candidatesAccepted,
          open: false,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      //get firstname of user selected

      return (
        this.candidates.find(user => user.id === id) ||
        this.candidatesToContact.find(user => user.id === id) ||
        this.candidatesWorkInProgress.find(user => user.id === id) ||
        this.candidatesRefused.find(user => user.id === id)
      )
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    searchCity(val) {
      //verify if is number
      if (/^\d{5}$/.test(val)) {
        try {
          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.editedItem.zip = val
            })
            .catch(err => {
              console.log(err)
            })

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },
  },

  //

  async created() {
    this.fetchCandidates()
      .then(() => {})
      .finally(() => {
        this.open = [2]
      })

    this.fetchType()
  },
  components: {
    CustomeLoader,
  },

  methods: {
    async getPdf() {
      let idAgency = this.$store.state.user.agency_id

      this.editedPromise.agency = this.agency.name
      this.editedPromise.agency_street = this.agency.street
      this.editedPromise.agency_zip = this.agency.zip
      this.editedPromise.agency_city = this.agency.city
      this.editedPromise.lastname_candidate = this.selected.lastname
      this.editedPromise.firstname_candidate = this.selected.firstname
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/candidates/generate-pdf`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify(this.editedPromise),
        })

        if (!response.ok) throw new Error("Erreur lors de la génération du PDF")

        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      } catch (e) {
        console.error(e)
        alert("Erreur lors de la génération du PDF")
      }
    },
    async fetchType() {
      let headers = new Headers()

      headers.append("Content-Type", "application/json")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

      await pause(1500)

      try {
        let response = await fetch(`${config.apiUri}/contracts`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()

        if (response.ok) {
          data.type_employments.forEach(employment => {
            this.typeEmployments.push(employment)
          })
          data.type_contracts.forEach(contract => {
            this.typeContracts.push(contract)
          })
          data.type_jobs.forEach(job => {
            this.typeJobs.push(job)
          })
          data.type_qualifications.forEach(qualification => {
            this.typeQualifications.push(qualification)
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async updateCandidateState(idState) {
      try {
        const idAgency = this.$store.state.user.agency_id
        const idCandidate = this.selected.id

        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/candidates/${idCandidate}/state`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            idState: idState,
          }),
        })

        if (!response.ok) throw new Error("Erreur lors de la mise à jour du candidat")

        this.fetchCandidates()
      } catch (e) {
        console.error(e)
        alert("Erreur lors de la mise à jour du candidat")
      }
    },
    updateWrittenSalary() {
      this.$nextTick(() => {
        this.editedPromise.mensual_salary_letter = writtenNumber(this.editedPromise.mensual_salary, { lang: "fr" })
      })
    },
    updateWrittenForm(i) {
      this.$nextTick(() => {
        this.editedPromise.rows[i].letter = writtenNumber(this.editedPromise.rows[i].amount, { lang: "fr" })
      })
    },
    async downloadFile(idCandidate, idFile) {
      const idAgency = this.$store.state.user.agency_id

      try {
        const response = await fetch(
          `${config.apiUri}/agencies/${idAgency}/candidates/${idCandidate}/files/${idFile}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
          },
        )

        let data = await response.blob()

        let url = window.URL.createObjectURL(data)
        let a = document.createElement("a")
        a.href = url
        a.download = "file.pdf"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)

        return data
      } catch (e) {
        console.error(e)
      }
    },
    previewFiles(files) {
      // Traite les fichiers ici, par exemple :
      const processedFiles = files.map(file => {
        if (!file.preview) {
          file.preview = URL.createObjectURL(file)
        }
        return file
      })

      // Ajoute les fichiers traités à votre liste de fichiers existants
      this.files = this.files.concat(processedFiles)
    },
    verifyFileExtension(file) {
      // pdf and xml files
      const allowedExtensions = /(\.pdf|\.docx|\.doc)$/i
      return allowedExtensions.exec(file.name)
    },
    openFileExplorer() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept =
        "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      input.multiple = true

      input.onchange = e => {
        const files = Array.from(e.target.files).filter(file => this.verifyFileExtension(file))

        if (files.length > 0) {
          this.previewFiles(files)
        }
      }

      input.click()
    },
    previewFile(file) {
      this.selectedFilePreview = file.preview || URL.createObjectURL(file)
      this.previewDialog = true
    },

    async uploadFile() {
      this.loadingSend = true // Démarre l'indicateur de chargement
      try {
        for (const [index, file] of this.files.entries()) {
          const formData = new FormData()
          formData.append("idAgency", this.$store.state.user.id_agency)
          formData.append("id", this.selectedCandidate.id)
          formData.append("lastname", this.selectedCandidate.lastname)
          formData.append("firstname", this.selectedCandidate.firstname)
          formData.append("agency", this.$store.state.user.agency_name)
          formData.append("documentName", `proute`)
          formData.append("endpoint", "candidate")
          formData.append("file", file)

          const res = await fetch(`${config.apiUri}/upload`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getCookie("jwt")}`,
            },
            body: formData,
          })

          if (!res.ok) throw new Error("Erreur lors de l'upload du PDF")
        }
        this.dialogImportFile = false
        this.$toast.success("Fichiers envoyés avec succès", {
          duration: 5000,
          position: "bottom-right",
        })
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      } finally {
        this.files = [] // Réinitialiser les fichiers après l'envoi
        this.loadingSend = false // Arrête l'indicateur de chargement

        this.fetchCandidates()
      }
    },
    filterItems() {
      let search = this.filterCandidate.toLowerCase()
      return [
        {
          id: 1,
          name: `A contacter`,
          children: this.candidatesToContact.filter(
            item => item.firstname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search),
          ),
          open: false,
        },
        {
          id: 2,
          name: `En cours de recrutement`,
          children: this.candidatesWorkInProgress.filter(
            item => item.firstname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search),
          ),
          open: false,
        },
        {
          id: 3,
          name: `Mon vivier actif`,
          children: this.candidates.filter(
            item => item.firstname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search),
          ),
          open: false,
        },
        {
          id: 4,
          name: `Candidatures refusés`,
          children: this.candidatesRefused.filter(
            item => item.firstname.toLowerCase().includes(search) || item.lastname.toLowerCase().includes(search),
          ),
          open: false,
        },
      ]
    },
    async fetchCandidates() {
      this.candidates = []
      this.candidatesRefused = []
      this.candidatesWorkInProgress = []
      this.candidatesToContact = []

      this.loadingCandidates = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")
        headers.append("Authorization", `Bearer ${getCookie("jwt")}`)

        const idAgency = this.$store.state.user.agency_id

        await fetch(`${config.apiUri}/agencies/${idAgency}/candidates`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
          .then(res => res.json())
          .then(data => {
            // this.candidates = data

            data.forEach(candidate => {
              switch (candidate.id_agency_candidate_state) {
                case 1:
                  this.candidatesToContact.push(candidate)
                  break
                case 2:
                  this.candidatesWorkInProgress.push(candidate)
                  break

                case 3:
                  this.candidates.push(candidate)
                  break
                case 4:
                  this.candidatesRefused.push(candidate)
                  break
                default:
                  this.candidates.push(candidate)
                  break
              }
            })
          })
          .finally(() => {
            this.loadingCandidates = false
          })
      } catch (e) {
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })

        this.loadingCandidates = false
      }
    },
  },
}
</script>
<style lang="scss">
.fa-icone {
  font-size: 1.2rem;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  // position icon in middle of image
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.drop-zone {
  /* styles de base */
}

.drop-zone:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

.drop-zone-error {
  /* styles de base */
}

.drop-zone-error:hover {
  /* styles lorsque la souris est sur la zone de dépôt */
  background-color: #f0f0f0;
  border: 2px dashed #c1272d;
}

.drop-zone-error.file-selected:hover {
  /* styles lorsque l'utilisateur a déjà sélectionné un fichier et qu'il s'apprête à le déposer */
  background-color: #f0f0f0;
  border: 2px dashed #333;
}

// if media screen is more than 1250px width then apply the following styles
@media screen and (min-width: 2000px) {
  .profilCandidateBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

@media screen and (min-width: 1500px) {
  .profilCandidateBadge {
    position: absolute;
    top: 45px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

@media screen and (max-width: 1500px) {
  .profilCandidateBadge {
    position: absolute;
    top: 150px;
    z-index: 0;
    width: 0%;
    position: absolute;
    right: 120px;
  }
}

.profilBanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}

// hide button in list
.no-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item-explain {
  -webkit-line-clamp: 4 !important;
}

.text-box {
  // border: 1px solid;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
}
</style>
