<template>
  <div>
    <v-row class="mb-5 text-center my-5">
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              v-bind="attrs"
              v-on="on"
              outlined
              height="150"
            >
              <v-card-title class="text-center justify-center">Personnes en congés aujourd'hui</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <h2>{{ collaboratorsInLeaveToday }}</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <span>Ce sont les collaborateurs en congés ce {{ today }}</span>
        </v-tooltip>
      </v-col>

      <v-col class="d-flex" cols="12" md="3">
        <v-dialog max-width="1200" v-model="dialogCollaboratorsRecoveryLeave">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              :disabled="isManager"
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              link
              v-bind="attrs"
              v-on="on"
              outlined
              height="150"
              @click="dialogVisible = true"
            >
              <v-card-title class="text-center justify-center">RTT pour l'année {{ currentYear }}</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <h2>{{ agencyInformations.recovery_current_year }}</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Configuration des RTT</span>
                    </v-card-title>
                    <v-card-text> Collaborateur(s) concerné(s) : {{ collaboratorsRecoveryLeave.length }} </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6" class="text-center">
                          <v-text-field
                            v-model="newRecoveryLeaveBalance"
                            label="Nombre de jours forfait pour l'année"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="postNewRecoveryLeave(newRecoveryLeaveBalance)">Terminer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-dialog max-width="1200" v-model="dialogCollaboratorsCompensatoryLeave">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              :disabled="isManager"
              class="flex d-flex flex-column rounded-xl"
              elevation="3"
              link
              v-bind="attrs"
              v-on="on"
              outlined
              height="150"
              @click="dialogVisible = true"
            >
              <v-card-title class="text-center justify-center">JFC pour l'année {{ currentYear }}</v-card-title>
              <v-row align="center">
                <v-card-text>
                  <h2>{{ agencyInformations.compensatory_current_year }}</h2>
                </v-card-text>
              </v-row>
            </v-card>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Configuration des jours forfait</span>
                    </v-card-title>
                    <v-card-text>
                      Collaborateur(s) concerné(s) : {{ collaboratorsCompensatoryLeave.length }}
                    </v-card-text>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6" class="text-center">
                          <v-text-field
                            v-model="newCompensatoryLeaveBalance"
                            label="Nombre de jours forfait pour l'année"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="postNewCompensatoryLeave(newCompensatoryLeaveBalance)"
                        >Terminer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-card outlined flat elevation="3" class="flex d-flex flex-column rounded-xl" height="150">
          <v-card-title class="text-center justify-center">Demandes de congés à valider</v-card-title>
          <v-row align="center">
            <v-card-text>
              <!-- <v-avatar size="56px" color="EoleError white--text">
                    <i class="fa-solid fa-user-large-slash"></i>
                  </v-avatar> -->
              <h2>{{ demand.length }}</h2>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card-title
      class="no-padding mb-2 font-weight-bold EoleBlue--text"
      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
      >DEMANDES DE CONGÉS A VALIDER</v-card-title
    >
    <v-row>
      <v-col cols="12" md="12" class="text-right">
        <v-dialog max-width="1300" v-model="dialogCollaboratorsValidation">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="dialogVisible = true" color="EoleBlue">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
            <v-card-text style="background-color: #f1f4f9">
              <v-row>
                <v-col cols="12" md="3" class="no-padding ml-n2">
                  <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                    <v-card-title>
                      <v-img
                        :src="require('@/assets/images/logos/anavel_logo.png')"
                        max-height="150px"
                        max-width="120px"
                        alt="logo"
                        contain
                      ></v-img>
                    </v-card-title>
                    <v-card-title class="text-center justify-center muller-capitalized"
                      >Mes Services Assurance</v-card-title
                    >
                    <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                    <v-card-text class="no-padding">
                      <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class="">
                      </v-img>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="9" class="no-padding d-flex">
                  <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                    <v-card-title>
                      <span class="text-h5">Historique des demandes de congés</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row align="center" class="">
                        <v-col cols="12" md="3">
                          <v-text-field v-model="selectedHoliday" label="Rechercher dans la liste"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1" align-self="center"> </v-col>
                        <v-col cols="12" md="3" align-self="end" class="ml-5"> </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-data-table
                        :items-per-page="-1"
                        :items="filteredHolidayItems"
                        :headers="headersHolidays"
                        hide-default-footer
                        :no-data-text="'Aucune demande trouvée'"
                      >
                        <template v-slot:[`item.name`]="{ item }">
                          <span>{{ item.lastname }} {{ item.firstname }}</span>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <span>Le {{ item.date_format }}</span>
                        </template>
                        <template v-slot:[`item.period`]="{ item }">
                          <span v-if="!item.is_half_day"
                            >Du {{ item.start_date_format }} au {{ item.end_date_format }}</span
                          >
                          <span v-else-if="item.is_half_day && item.half_day_type === 1"
                            >Le {{ item.start_date_format }} Matin</span
                          >
                          <span v-else-if="item.is_half_day && item.half_day_type === 2"
                            >Le {{ item.start_date_format }} Après-midi</span
                          >
                        </template>
                        <template v-slot:[`item.sold`]="{ item }">
                          <span>{{ item.remain_paid_leave }}</span>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-chip small v-if="item.state === 1" color="EoleBlue">A approuver</v-chip>
                          <v-chip small v-else-if="item.state === 2" color="EoleError"
                            ><v-icon left small>mdi-close-thick</v-icon>Refusé</v-chip
                          >
                          <v-chip small v-else-if="item.state === 5" color="EoleGray" outlined>Annulé</v-chip>
                          <v-chip small v-else color="EoleGreen" outlined
                            ><v-icon left small>mdi-check</v-icon> Validé</v-chip
                          >
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="postNewCompensatoryLeave(newCompensatoryLeaveBalance)"
                        >Terminer</v-btn
                      >
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :items="demand"
        :headers="headersHolidays"
        hide-default-footer
        :no-data-text="'Aucune demande en cours'"
        :items-per-page="-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.lastname }} {{ item.firstname }}</span>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span>Le {{ item.date_format }}</span>
        </template>
        <template v-slot:[`item.period`]="{ item }">
          <span v-if="!item.is_half_day">Du {{ item.start_date_format }} au {{ item.end_date_format }}</span>
          <span v-else-if="item.is_half_day && item.half_day_type === 1">Le {{ item.start_date_format }} Matin</span>
          <span v-else-if="item.is_half_day && item.half_day_type === 2"
            >Le {{ item.start_date_format }} Après-midi</span
          >
        </template>
        <template v-slot:[`item.sold`]="{ item }">
          <span>{{ item.remain_paid_leave }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="openDialog(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDay" right transition="slide-x-transition" close-on-content-click width="1200px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <!-- {{ editedItem }} -->
          <!-- <v-row>
            <v-col cols="12" md="6" style="background-color: red"> </v-col>
            <v-col cols="12" md="6" style="background-color: blue"> </v-col>
          </v-row> -->
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <!-- <v-card-title class="muller-capitalized EoleBlue--text">
                    {{ selectedItem.lastname }} {{ selectedItem.firstname }} à besoin de prendre un peu l'air 🏖️
                  </v-card-title>
                  <v-card-subtitle class="mt-5">
                    Pour la période du {{ selectedItem.start_date_format }} au {{ selectedItem.end_date_format }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde COP N-1
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.remain_paid_leave_last_year }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-06-{{ lastYear }} au 31-05-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Le solde de jours restant au 1er du mois courant (). </span>
                        </v-tooltip>
                      </v-col>
                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card
                              v-if="selectedItem.id_employment != 2"
                              class="flex flex-column rounded-lg"
                              v-bind="attrs"
                              v-on="on"
                              flat
                              outlined
                            >
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde RTT
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.recovery_working_time_leave_last_year }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                            <v-card v-else class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde JFC
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.compensatory_package_leave }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
                        </v-tooltip>
                      </v-col>

                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde COP N
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.remain_paid_leave }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-06-{{ currentYear }} au 31-05-{{ nextYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="text-center text-h6 my-5">
                    {{ selectedItem.lastname }} {{ selectedItem.firstname }} souhaite prendre
                    <strong class="EoleBlue--text"
                      >{{ selectedItem.number_of_days }} jours de {{ selectedItem.type }}
                    </strong>
                    du <strong class="EoleBlue--text">{{ selectedItem.start_date_format }}</strong> au
                    <strong class="EoleBlue--text"> {{ selectedItem.end_date_format }}</strong>
                  </v-card-text>
                  <v-card-text>
                    <v-textarea
                      :value="selectedItem.collaborator_comment"
                      readonly
                      label="Commentaire collaborateur"
                      outlined
                      dense
                    ></v-textarea>
                    <v-textarea v-model="comments" label="Commentaire manager" outlined dense></v-textarea>
                    <v-row>
                      
                    </v-row> -->
                  <v-btn v-if="editedDay.state === 3" @click="cancelHoliday(editedDay.id, editedDay.collaboratorId)"
                    >Annuler la demande de congés</v-btn
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAction" right transition="slide-x-transition" close-on-content-click width="1200px">
        <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
          <!-- {{ editedItem }} -->
          <!-- <v-row>
            <v-col cols="12" md="6" style="background-color: red"> </v-col>
            <v-col cols="12" md="6" style="background-color: blue"> </v-col>
          </v-row> -->
          <v-card-text style="background-color: #f1f4f9">
            <v-row>
              <v-col cols="12" md="3" class="no-padding ml-n2">
                <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                  <v-card-title>
                    <v-img
                      :src="require('@/assets/images/logos/anavel_logo.png')"
                      max-height="150px"
                      max-width="120px"
                      alt="logo"
                      contain
                    ></v-img>
                  </v-card-title>
                  <v-card-title class="text-center justify-center muller-capitalized"
                    >Mes Services Assurance</v-card-title
                  >
                  <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
                  <v-card-text class="no-padding">
                    <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="9" class="no-padding d-flex">
                <v-card class="rounded-r-xl flex-fill flex d-flex flex-column" flat>
                  <v-card-title class="muller-capitalized EoleBlue--text">
                    {{ selectedItem.lastname }} {{ selectedItem.firstname }} à besoin de prendre un peu l'air 🏖️
                  </v-card-title>
                  <v-card-subtitle class="mt-5">
                    Pour la période du {{ selectedItem.start_date_format }} au {{ selectedItem.end_date_format }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde COP N-1
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.remain_paid_leave_last_year }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-06-{{ lastYear }} au 31-05-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Le solde de jours restant au 1er du mois courant (). </span>
                        </v-tooltip>
                      </v-col>
                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card
                              v-if="selectedItem.id_employment != 2"
                              class="flex flex-column rounded-lg"
                              v-bind="attrs"
                              v-on="on"
                              flat
                              outlined
                            >
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde RTT
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.recovery_working_time_leave }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                            <v-card v-else class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde JFC
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.compensatory_package_leave }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-01-{{ currentYear }} au 31-12-{{ currentYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
                        </v-tooltip>
                      </v-col>

                      <v-col class="d-flex" cols="12" md="4" sm="6">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-card class="flex flex-column rounded-lg" v-bind="attrs" v-on="on" flat outlined>
                              <v-card-subtitle class="text-center">
                                <v-avatar color="EoleBlueLighten" class="mr-2">
                                  <i
                                    class="fa-regular fa-calendar-plus fa-1x EoleBlue--text"
                                    style="font-size: 1.5rem"
                                  ></i>
                                </v-avatar>
                                Solde COP N
                              </v-card-subtitle>
                              <div class="d-flex flex-column flex-grow-1">
                                <v-card-text class="text-center flex-grow-1">
                                  <h2 class="font-weight-bold" style="font-size: 2rem">
                                    {{ selectedItem.remain_paid_leave }}
                                  </h2>
                                </v-card-text>
                              </div>
                              <v-card-subtitle class="text-center"
                                >01-06-{{ currentYear }} au 31-05-{{ nextYear }}</v-card-subtitle
                              >
                            </v-card>
                          </template>
                          <span>Les appels en attente sont affichés dans la liste ci-dessous. </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text class="text-center text-h6 my-5">
                    {{ selectedItem.lastname }} {{ selectedItem.firstname }} souhaite prendre
                    <strong class="EoleBlue--text"
                      >{{ selectedItem.number_of_days }} jours de {{ selectedItem.type }}
                    </strong>
                    du <strong class="EoleBlue--text">{{ selectedItem.start_date_format }}</strong> au
                    <strong class="EoleBlue--text"> {{ selectedItem.end_date_format }}</strong>
                  </v-card-text>
                  <v-card-text>
                    <v-textarea
                      :value="selectedItem.collaborator_comment"
                      readonly
                      label="Commentaire collaborateur"
                      outlined
                      dense
                    ></v-textarea>
                    <v-textarea v-model="comments" label="Commentaire manager" outlined dense></v-textarea>
                    <v-row>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          color="EoleError"
                          class="white--text"
                          @click="putHoliday(selectedItem.id, 2, selectedItem.idAccount)"
                          >Refuser</v-btn
                        >
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="EoleGreen"
                          class="white--text"
                          @click="putHoliday(selectedItem.id, 3, selectedItem.idAccount)"
                          >Accepter</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-card-title
      class="no-padding mb-2 mt-15 font-weight-bold"
      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
      >RÉCAPITULATIF DES CONGÉS</v-card-title
    >
    <div v-if="isDataLoaded" class="mb-15">
      <v-row class="mb-n9">
        <v-col cols="12" md="8" class="text-right">
          <v-btn fab icon @click="previousMonth">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span
            >Les congés pour <strong class="EoleYellow--text">{{ displayMonth }} {{ year }} </strong>chez
            {{ agency }}</span
          >

          <v-btn fab icon @click="nextMonth">
            <v-icon>mdi-chevron-right </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" md="4" class="text-right">
          <v-dialog top width="350px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" fab icon color="EoleBlue"><v-icon>mdi-eye</v-icon></v-btn>
            </template>
            <v-card>
              <v-card-title> Légende </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" class="mb-n6">
                    <v-badge dot color="EoleYellow" class="" inline></v-badge
                    ><span> Congés en attente de validation</span>
                  </v-col>
                  <v-col cols="12" md="12" class="mb-n6">
                    <v-badge dot color="EoleBlue" inline></v-badge> <span>Congés approuvés</span>
                  </v-col>
                  <v-col cols="12" md="12" class="mb-n1">
                    <v-badge dot color="#f44336" inline></v-badge> <span>Formations</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <ul>
                  <li v-for="item in itemsHoliday" v-bind:key="item.id">{{ item.type }} : {{ item.label }}</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-simple-table>
            <thead>
              <tr>
                <th>Nom</th>
                <th v-for="(day, index) in daysOfMonth" :key="index">{{ day.format("DD MMM") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(collaborator, index) in sortedCollaborators" :key="index">
                <td>{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
                <td
                  @click="getLeaveClass(collaborator, day) ? openDialogDay(collaborator, day) : null"
                  v-for="(day, index) in daysOfMonth"
                  :key="index"
                  :class="['custom-td-height', getLeaveClass(collaborator, day)]"
                >
                  <v-row style="height: 0px">
                    <v-col
                      v-if="getLeaveClass(collaborator, day)"
                      :class="['white--text justify-center text-center pointer', getLeaveClass ? '' : '']"
                      cols="12"
                      md="5"
                    >
                      {{ getHolidayType(collaborator, day) }}
                    </v-col>
                    <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                    <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                  </v-row>
                  <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">
                    {{ days }} jours {{ getHolidayType(collaborator, day) }}
                  </div> -->
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Total</td>
                <td v-for="(day, index) in daysOfMonth" :key="index" :class="['custom-td-height']">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-row style="height: 0px" v-on="on" v-bind="attrs">
                        <v-col class="text-center" cols="12" md="12">{{ getCountUserInLeaveToday(day) }} </v-col>
                        <!-- <v-col cols="12" md="5" v-else> {{ days }} </v-col> -->

                        <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">{{ days }} jours</div> -->
                      </v-row>
                    </template>
                    <span>
                      <ul>
                        <li v-for="(collaborator, index) in getUsernameInLeaveToday(day)" v-bind:key="index">
                          {{ collaborator }}
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                  <!-- <div v-if="getLeaveClass(collaborator, day)" class="white--text">
                    {{ days }} jours {{ getHolidayType(collaborator, day) }}
                  </div> -->
                </td>
              </tr>
            </tbody>
            <!-- ... -->
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="text-right"
          >Au mois de {{ displayMonth }} il y aura {{ totalNumberWork }} jour(s) travaillé(s)</v-col
        >
      </v-row>
    </div>
    <!-- 
    <v-card-title
      class="no-padding mb-2 font-weight-bold EoleBlue--text"
      style="font-size: 0.95rem !important; color: rgba(94, 86, 105, 0.76) !important"
      >VALIDATION DES JOURS TRAVAILLÉS</v-card-title
    >
    <v-row v-if="collaboratorsValidation.length > 0">
      <v-col cols="12" md="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>Nom</th>
              <th v-for="(day, index) in daysOfLastMonth" :key="index">{{ day.format("DD MMM") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(collaborator, index) in collaboratorsValidation" :key="index">
              <td class="'no-padding'">{{ collaborator.lastname }} {{ collaborator.firstname }}</td>
              <td
                v-for="(day, index) in daysOfLastMonth"
                :key="index"
                :class="['no-padding', getLeaveClassLastMonth(collaborator, day)]"
              >
                <v-row>
                  <v-col
                    v-if="getLeaveClassLastMonth(collaborator, day) != 'working'"
                    :class="[
                      'white--text justify-center text-center',
                      getLeaveClassLastMonth(collaborator, day) ? 'mb-5' : 'mt-5',
                    ]"
                    cols="12"
                    md="12"
                    style="text-align: center; padding: 10px; border-radius: 5px"
                  >
                    <div
                      v-if="getHalfDayType(day, collaborator.id) === 0"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                    <div
                      v-if="getHalfDayType(day, collaborator.id) === 1"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 10px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                    <div
                      v-else-if="getHalfDayType(day, collaborator.id) === 2"
                      :class="['white--text']"
                      :style="getHalf(day, collaborator.id) ? 'position: relative; top: 50px' : null"
                    >
                      {{ getHolidayInformation(day, collaborator.id) }}
                    </div>
                  </v-col>
                  <v-col
                    v-else
                    :class="[
                      'EoleBlue--text justify-center text-center',
                      getLeaveClassLastMonth(collaborator, day) ? 'mb-0' : 'mt-0',
                    ]"
                    cols="12"
                    md="12"
                    style="text-align: center; padding: 10px; border-radius: 5px"
                  >
                    <div class="EoleBlue--text">JT</div>
                  </v-col>
                  <v-col
                    v-if="getHalf(day, collaborator.id)"
                    cols="12"
                    md="12"
                    :class="[getHalfDayClass(day, collaborator.id), 'EoleBlue--text']"
                    >JT</v-col
                  >

                </v-row>
             
              </td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="postDaysWorkValidation(collaborator.id)">
                      <v-list-item-title>Valider</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="12">Aucun jours de travail à valider</v-col>
    </v-row> -->
  </div>
</template>

<script>
import config from "@/views/config/config"

import moment from "moment"
import "moment-business-days"
import Holidays from "date-holidays"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
import { getCookie } from "@/utils/cookies"
init("user_gUunL4KW2ImPaGngWHYhP")
const hd = new Holidays()
hd.init("FR")

moment.updateLocale("fr", {
  holidays: [
    "2023-01-01",
    "2023-04-17",
    "2023-05-01",
    "2023-05-08",
    "2023-05-25",
    "2023-06-05",
    "2023-07-14",
    "2023-08-15",
    "2023-11-01",
    "2023-11-11",
    "2023-12-25",
  ],
  holidayFormat: "YYYY-MM-DD",
  workingWeekdays: [1, 2, 3, 4, 5],
})

export default {
  name: "Calendar",
  props: {
    collaborators: {
      type: Array,
      required: true,
    },
    demand: {
      type: Array,
      required: true,
    },
    demandHistory: {
      type: Array,
      required: true,
    },
    collaboratorsValidation: {
      type: Array,
      required: true,
    },
    collaboratorsCompensatoryLeave: {
      type: Array,
      required: true,
    },
    collaboratorsRecoveryLeave: {
      type: Array,
      required: true,
    },
    agencyInformations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isManager: false,
      dialogDay: false,
      today: moment().format("dddd Do MMMM YYYY"),
      selectedHoliday: "",
      collaboratorsInLeaveToday: 0,
      dialogCollaboratorsRecoveryLeave: false,
      dialogCollaboratorsCompensatoryLeave: false,
      dialogCollaboratorsValidation: false,
      editedDay: {},
      leavesLastMonth: [],
      currentYear: moment().year(),
      lastYear: moment().year() - 1,
      nextYear: moment().year() + 1,
      itemsHoliday: [
        { id: 1, type: "COP", label: "Congés payés" },
        { id: 2, type: "RTT", label: "Récupération du temps de travail" },
        { id: 3, type: "JFC", label: "Jours forfait compensatoire" },
        { id: 4, type: "COEF", label: "Événement familial" },
        { id: 5, type: "MAL", label: "Maladie" },
        { id: 6, type: "CSS", label: "Congés sans solde" },
        { id: 7, type: "MP", label: "Maladie professionnelle" },
        { id: 8, type: "AT", label: "Accident du travail" },
        { id: 9, type: "MAT", label: "Maternité" },
        { id: 10, type: "PAT", label: "Paternité" },
        { id: 11, type: "ABNJ", label: "Absence non justifiée" },
        { id: 12, type: "REA", label: "Révision examen apprenti" },
        { id: 13, type: "TT", label: "Télétravail" },
        { id: 14, type: "ABJR", label: "Absence justifiée rémunurée" },
      ],
      comments: "",
      dialogAction: false,
      headersHolidays: [
        {
          text: "Collaborateur",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Période souhaitée",
          value: "period",
        },
        {
          text: "Type de congés",
          value: "type",
        },
        {
          text: "Nombre de jours souhaité",
          value: "number_of_days",
        },
        {
          text: "Date de la demande",
          value: "date",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      agency: "",
      year: moment().year(), // Ajout de l'année actuelle
      totalNumberWork: 0,
      month: moment().month() + 2, // Mois actuel
      leaves: [], // Données des congés
      daysOfMonth: [], // Jours du mois
      isDataLoaded: false,
      selectedItem: {
        collaborator_comment: "",
        id: 0,
      },
      type: "",
      daysOfLastMonth: [],
      publicHolidays: [],
      lastMonthPublicHolidays: [],
      newCompensatoryLeaveBalance: 0,
      newRecoveryLeaveBalance: 0,
      editedDay: {},
    }
  },
  computed: {
    sortedCollaborators() {
      return this.collaborators.sort((a, b) => {
        if (a.id_service === null) return 1
        if (b.id_service === null) return -1
        return a.id_service - b.id_service
      })
    },
    filteredHolidayItems() {
      // Filtrer les produits en fonction du texte saisi dans v-text-field
      const searchText = this.selectedHoliday.toLowerCase()
      return this.demandHistory.filter(holiday => holiday.complete_name.toLowerCase().includes(searchText))
    },
    displayMonth() {
      return moment()
        .month(this.month - 1)
        .format("MMMM")
    },
  },
  watch: {
    month() {
      this.updateCalendar()
    },
    collaborators: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
    collaboratorsValidation: {
      deep: true,
      handler() {
        this.updateCalendar()
      },
    },
  },
  methods: {
    openDialogDay(collaborator, day) {
      console.log("clicked")
      this.editedDay = this.getInformationDay(collaborator, day)
      this.dialogDay = true
    },
    getInformationDay(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave
    },
    orderCollaboratorsByIdService() {
      this.collaborators.sort((a, b) => a.id_service - b.id_service)
    },
    postNewRecoveryLeave(balance) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "setNewRecoveryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours de RTT sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsRecoveryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    verifyCollaboratorIsInLeaveToday() {
      const today = moment().format("YYYY-MM-DD")
      let length = 0

      this.leaves.forEach(leave => {
        if (moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") && leave.state === 3) {
          length++
        }
      })

      return length
    },
    postNewCompensatoryLeave(balance) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "setNewCompensatoryLeaveBalance")
        formData.append("idAgency", idAgency)
        formData.append("balance", balance)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success(`Vous configuré le nombre de jours forfait sur : ${balance}`, {
              position: "bottom-right",
            })

            this.$emit("update")

            this.$forceUpdate()

            this.dialogCollaboratorsCompensatoryLeave = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getLastMonthPublicHolidaysFrance(year) {
      this.lastMonthPublicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.lastMonthPublicHolidays.push(holiday.date)
      })
    },
    getPublicHolidaysFrance(year) {
      this.publicHolidays = []

      const frenchHolidays = hd.getHolidays(year)

      frenchHolidays.forEach(holiday => {
        holiday.date = moment(holiday.date).format("YYYY-MM-DD")

        this.publicHolidays.push(holiday.date)
      })
    },
    getUsernamesById(id) {
      const collaborator = this.collaborators.find(collaborator => collaborator.id === id)

      return collaborator.lastname + " " + collaborator.firstname
    },
    getUsernameInLeaveToday(day) {
      const today = day.format("YYYY-MM-DD")
      let collaborators = []

      this.leaves.forEach(leave => {
        if (
          moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") &&
          (leave.state === 3) ^ (leave.state === 4)
        ) {
          collaborators.push(this.getUsernamesById(leave.collaboratorId))
        }
      })

      return collaborators
    },
    getCountUserInLeaveToday(day) {
      const today = day.format("YYYY-MM-DD")
      let length = 0

      this.leaves.forEach(leave => {
        if (
          moment(today).isBetween(leave.startDate, leave.endDate, "day", "[]") &&
          (leave.state === 3) ^ (leave.state === 4)
        ) {
          length++
        }
      })

      return length
    },
    postDaysWorkValidation(idAccount) {
      this.getWorkingId(idAccount)

      const idAgency = this.$store.state.user.agency_id
      try {
        const formData = new FormData()

        formData.append("place", "postDaysWorkValidation")
        formData.append("idAccount", idAccount)
        formData.append("idWorkingDay", this.editedDay.idWorkingDay)

        fetch(`${config.apiUri}/agencies/${idAgency}/holidays`, {
          method: "POST",
          body: formData,
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogCollaboratorsValidation = false
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    getWorkingId(id) {
      const leave = this.leavesLastMonth.find(leave => leave.collaboratorId === id)

      this.editedDay = leave
    },
    getHalfDayType(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return false

      return leave.halfDayType
    },
    getHolidayInformation(day, id) {
      // const leave = this.leavesLastMonth.find(leave =>
      //   moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      // )
      // if (!leave) {
      //   return "working"
      // }

      // return leave.type

      const leave = this.leavesLastMonth.find(
        leave => leave.collaboratorId === id && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) {
        return "working"
      }

      return leave.type
    },
    getHolidayTypeLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    getHolidayType(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      return leave.type
    },
    openDialog(item) {
      // Méthode pour ouvrir le v-dialog pour un élément donné
      this.selectedItem = item
      this.dialogAction = true
    },
    async cancelHoliday(id, idAccount) {
      const idAgency = this.$store.state.user.agency_id

      const putData = {
        action: "cancel",
        idAccount: idAccount,
      }
      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/holidays/${id}`, {
          method: "PUT",
          body: JSON.stringify(putData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false

            this.comments = ""

            this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
        this.$toast.error(`Erreur ${err}`, {
          position: "bottom-right",
        })
      }
    },
    async putHoliday(id, answer, idAccount) {
      const idAgency = this.$store.state.user.agency_id

      const putData = {
        action: answer === 2 ? "reject" : "confirm",
        comment: this.comments,
        idAccount: idAccount,
      }
      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/holidays/${id}`, {
          method: "PUT",
          body: JSON.stringify(putData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        }).then(response => {
          if (response.ok) {
            this.$toast.success("Vous avez modifié la demande de congés", {
              position: "bottom-right",
            })

            this.$emit("update")

            this.dialogAction = false

            this.comments = ""

            this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
          } else {
            this.$toast.error("Erreur", {
              position: "bottom-right",
            })
          }
        })
      } catch (err) {
        console.log(err)
        this.$toast.error(`Erreur ${err}`, {
          position: "bottom-right",
        })
      }
    },
    previousMonth() {
      if (this.month === 1) {
        // Si le mois actuel est janvier, passez à décembre de l'année précédente
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }
      this.updateCalendar()
    },
    nextMonth() {
      if (this.month === 12) {
        // Si le mois actuel est décembre, passez à janvier de l'année suivante
        this.month = 1
        this.year += 1
      } else {
        this.month += 1
      }
      this.updateCalendar()
    },

    updateCalendar() {
      this.generateDaysOfMonth()
      this.processLeaves()
      this.isDataLoaded = true
      this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
    },
    generateDaysOfMonth() {
      this.getPublicHolidaysFrance(this.year)
      this.totalNumberWork = 0
      this.daysOfMonth = []
      let startOfMonth = moment([this.year, this.month - 1]).startOf("month")
      let endOfMonth = moment([this.year, this.month - 1]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.publicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfMonth.push(startOfMonth.clone())
          this.totalNumberWork++
        }
        startOfMonth.add(1, "days")
      }
    },
    generateDaysOfLastMonth() {
      this.getLastMonthPublicHolidaysFrance(this.year)
      this.daysOfLastMonth = []
      if (this.month === 1) {
        this.month = 12
        this.year -= 1
      } else {
        this.month -= 1
      }

      let lastMonth = this.month - 1

      let startOfMonth = moment([this.year, lastMonth]).startOf("month")
      let endOfMonth = moment([this.year, lastMonth]).endOf("month")

      while (startOfMonth.isSameOrBefore(endOfMonth)) {
        if (startOfMonth.isBusinessDay() && !this.lastMonthPublicHolidays.includes(startOfMonth.format("YYYY-MM-DD"))) {
          this.daysOfLastMonth.push(startOfMonth.clone())
        }
        startOfMonth.add(1, "days")
      }
    },
    processLeaves() {
      this.leaves = this.collaborators.flatMap(collaborator =>
        collaborator.holidays
          .filter(holiday => {
            let startDate = moment(holiday.start_date)
            let endDate = moment(holiday.end_date)

            return startDate.month() === this.month - 1 || endDate.month() === this.month - 1
          })
          .map(holiday => ({
            id: holiday.id,
            collaboratorId: collaborator.id,
            startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
            endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
            days: holiday.number_of_days,
            state: holiday.state,
            isHalfDay: holiday.is_half_day,
            halfDayType: holiday.half_day_type,
            type: holiday.type,
          })),
      )
    },
    processLeavesLastMonth() {
      this.leavesLastMonth = this.collaboratorsValidation.flatMap(collaborator =>
        collaborator.worked_days.flatMap(workedDay => {
          // Convertir la chaîne JSON en tableau d'objets
          let daysArray = workedDay.days

          // Filtrer et mapper les jours travaillés
          return daysArray
            .filter(holiday => {
              let startDate = moment(holiday.start_date)
              let endDate = moment(holiday.end_date)

              return startDate.month() === this.month - 2 || endDate.month() === this.month - 2
            })
            .map(holiday => ({
              collaboratorId: collaborator.id,
              startDate: moment(holiday.start_date).format("YYYY-MM-DD"),
              endDate: moment(holiday.end_date).format("YYYY-MM-DD"),
              days: holiday.number_of_days,
              state: holiday.state,
              isHalfDay: holiday.is_half_day,
              halfDayType: holiday.half_day_type,
              type: holiday.type,
              idWorkingDay: holiday.idWorkingDay,
            }))
        }),
      )
    },
    getHalfDayClass(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return ""

      if (leave.halfDayType === 2) {
        return "morning"
      } else if (leave.halfDayType === 1) {
        return "afternoon"
      }

      return halfDayClass
    },
    getHalf(day, collaboratorId) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaboratorId && moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (!leave) return false

      return leave.isHalfDay
    },
    getLeaveClassLastMonth(collaborator, day) {
      const leave = this.leavesLastMonth.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay === 1) {
          if (leave.halfDayType === 1) {
            if (leave.state === 3) {
              return "leave-half-morning-validated"
            } else {
              return "leave-half-morning-pending"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType === 2) {
            if (leave.state === 3) {
              return "leave-half-afternoon-validated"
            } else {
              return "leave-half-afternoon-pending"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 3) {
          return "leave-end"
        }
        if (leave.state === 3) {
          return "leave-middle"
        }
        if (leave.state === 1) {
          return "leave-middle-nok"
        }
      }
      if (!leave) return "working"
    },

    getLeaveClass(collaborator, day) {
      const leave = this.leaves.find(
        leave =>
          leave.collaboratorId === collaborator.id &&
          moment(day).isBetween(leave.startDate, leave.endDate, "day", "[]"),
      )

      if (leave) {
        this.days = leave.days

        // Si c'est un demi-jour le matin

        if (leave.isHalfDay === 1) {
          if (leave.halfDayType === 1) {
            if (leave.state === 3) {
              return "leave-half-morning-validated"
            } else if (leave.state === 4) {
              return "leave-half-morning-training"
            } else {
              return "leave-half-morning-pending"
            }
          }

          // Si c'est un demi-jour l'après-midi
          if (leave.halfDayType === 2) {
            if (leave.state === 3) {
              return "leave-half-afternoon-validated"
            } else if (leave.state === 4) {
              return "leave-half-afternoon-training"
            } else {
              return "leave-half-afternoon-pending"
            }
          }
        }

        // Sinon, retournez les classes normales en fonction de 'state'
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 1) {
          return "leave-start-nok"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 1) {
          return "leave-end-nok"
        }
        if (moment(day).isSame(leave.startDate, "day") && leave.state === 3) {
          return "leave-start"
        }
        if (moment(day).isSame(leave.endDate, "day") && leave.state === 3) {
          return "leave-end"
        }

        if (moment(day).isSame(leave.startDate, "day") && leave.state === 4) {
          return "leave-start-training"
        }

        if (moment(day).isSame(leave.endDate, "day") && leave.state === 4) {
          return "leave-end-training"
        }

        if (leave.state === 4) {
          return "leave-middle-training"
        }

        if (leave.state === 3) {
          return "leave-middle"
        }
        if (leave.state === 1) {
          return "leave-middle-nok"
        }
      }
      return ""
    },
  },

  mounted() {
    this.updateCalendar()

    this.agency = localStorage.getItem("agency")

    this.generateDaysOfLastMonth()
    this.processLeavesLastMonth()
  },
  created() {
    this.isManager = localStorage.getItem("grade") === "manager"
    this.collaboratorsInLeaveToday = this.verifyCollaboratorIsInLeaveToday()
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.leave-start-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-right: none;
}

.leave-middle-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-end-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
}

.leave-half-training {
  background-color: #8a8d93;
  border-radius: 5px;
  border-left: none;
  border-right: none;
}

.leave-start-training .row .col-12 {
  padding-top: 0px;
}

.leave-middle-training .row .col-12 {
  padding-top: 0px;
}

.leave-end-training .row .col-12 {
  padding-top: 0px;
}

.morning {
  position: relative;
  bottom: 50px;
}
.afternoon {
  position: relative;
  top: 0px;
  padding-top: 10px;
}
.custom-td-height {
  height: 72px !important;
  width: 50px !important;
}
/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - Validé */
.leave-half-morning-validated {
  background: linear-gradient(to bottom, #001f47 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-validated .row {
  padding-bottom: 60px;
}

/* Style pour la moitié supérieure d'une cellule de congé demi-journée le matin - En attente */
.leave-half-morning-pending {
  background: linear-gradient(to bottom, #fcc03c 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-pending .row {
  padding-bottom: 0px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - Validé */
.leave-half-afternoon-validated {
  background: linear-gradient(to top, #001f47 50%, transparent 50%);
}

.leave-half-afternoon-validated .row .col-12 {
  padding-top: 15px;
}

.leave-half-morning-training {
  background: linear-gradient(to bottom, #8a8d93 50%, transparent 50%);
  content: "AM";
}

.leave-half-morning-training .row {
  padding-bottom: 80px;
}

.leave-half-afternoon-training {
  background: linear-gradient(to top, #8a8d93 50%, transparent 50%);
}

.leave-half-afternoon-training .row .col-12 {
  padding-top: 15px;
}

/* Style pour la moitié inférieure d'une cellule de congé demi-journée l'après-midi - En attente */
.leave-half-afternoon-pending {
  background: linear-gradient(to top, #fcc03c 50%, transparent 50%);
  content: "PM";
}

.leave-half-afternoon-pending .row {
  padding-top: 0px;
}

.leave-start-nok .row .col-12 {
  padding-top: 0px;
}

.leave-middle-nok .row .col-12 {
  padding-top: 0px;
}

.leave-end-nok .row .col-12 {
  padding-top: 0px;
}

.leave-start .row .col-12 {
  padding-top: 0px;
}

.leave-middle .row .col-12 {
  padding-top: 0px;
}

.leave-end .row .col-12 {
  padding-top: 0px;
}

.leave-half-morning-pending .row .col-12 {
  padding-top: 0px;
  margin-top: -16px;
}

.leave-half-morning-validated .row .col-12 {
  padding-top: 0px;
}
/* 
.calendar {
  font-family: "Roboto", sans-serif;
  max-width: 100%;
  margin: auto;
  overflow-x: auto;
} */

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}

th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  text-align: center;
}

thead th {
  background-color: #f5f5f5;
  color: #757575;
}

tbody tr:nth-child(odd) {
  background-color: #fafafa;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.on-leave {
  color: #f44336; /* Couleur rouge pour les jours de congé */
}

th:first-child,
td:first-child {
  text-align: left;
}

.leave-start-nok,
.leave-middle-nok,
.leave-end-nok {
  background-color: #fcc03c; /* Couleur pour les congés */
}

.leave-start-nok {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end-nok {
  border-left: none; /* Supprime la bordure gauche */
}

.leave-middle-nok {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* ... Autres styles ... */

/* Style de base pour les cellules de congé */
.leave-start,
.leave-middle,
.leave-end {
  background-color: #001f47; /* Couleur pour les congés */
}

/* Bordures arrondies pour le début et la fin */
.leave-start {
  border-right: none; /* Supprime la bordure droite */
}

.leave-end {
  border-left: none; /* Supprime la bordure gauche */
}

/* Ajustements pour les cellules du milieu */
.leave-middle {
  border-left: none; /* Supprime les bordures gauche et droite */
  border-right: none;
}

/* Pour gérer les cas où le congé commence et se termine le même jour */

/* Style pour la dernière cellule de congé si elle est suivie d'une cellule non-congé */
.leave-end + td {
  border-left: 1px solid #e0e0e0; /* Remet la bordure gauche */
}

/* Style pour la première cellule normale après une série de congés */
td:not(.leave-middle):not(.leave-start):not(.leave-end) {
  border-left: 1px solid #e0e0e0; /* Assure que la cellule a une bordure gauche */
}
</style>
